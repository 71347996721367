import { Listing } from "components/Listing"
import { AttributeCombination } from "pages/AttributeCombination"
import AttributeValuesPrices from "pages/AttributeValuesPrices"
import CalculationRules from "pages/CalculationRules"
import { CostCollector } from "pages/CostCollector"
import { Detail } from "pages/Detail"
import { Economic } from "pages/Economic"
import PricesRecalculate from "pages/PricesRecalculate"
import SetNomenclaturePrices from "pages/SetNomenclaturePrices"
import Staller from "pages/Staller"
import { CreateRouteData } from "./tools"

export const economicRoutes: CreateRouteData[] = [
    {
        path: "region",
        title: "Регионы",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "currency",
        title: "Валюты",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "price-type",
        title: "Типы цен",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "series",
        title: "Серии",
        render: () => <Listing noCreate />,
        update: () => <Economic.Series />,
    },
    {
        path: "producer",
        title: "Поставщики",
        render: () => <Listing noCreate />,
        update: () => <Economic.Producer />,
    },
    {
        path: "price-nomenclature-filter",
        title: "Фильтры номенклатуры",
        render: () => <Listing />,
        createAndUpdate: () => <Economic.NomenclatureFilters />,
    },
    {
        path: "price-margin-preset",
        title: "Типы наценок",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "price-calculation-rule",
        title: "Правила расчёта",
        render: () => <Listing />,
        createAndUpdate: () => <CalculationRules />,
    },
    {
        path: "cost-collector",
        title: "Сборщик цены от поставщика",
        render: () => <CostCollector />,
    },
    {
        path: "prices-recalculate",
        title: "Пересчёт цен",
        render: () => <PricesRecalculate />,
    },
    {
        path: "attribute-values-prices",
        title: "Сочетания значений",
        render: () => <AttributeValuesPrices />,
        permission: "economic/attribute-values-prices",
    },
    {
        render: () => <Listing />,
        path: "attribute-combination",
        title: "Справочник сочетаний",
        createAndUpdate: () => <AttributeCombination />,
    },
    {
        path: "model-value-staller",
        render: () => <Staller />,
        title: "Настройки Сталлер",
        permission: "economic/model-value-staller",
    },
    {
        path: "set-nomenclature-prices",
        title: "Ручная установка цен",
        render: () => <SetNomenclaturePrices />,
        permission: "economic/set-nomenclature-prices",
    },
]
