import { useQuery } from "@tanstack/react-query"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useEffect, useState } from "react"
import ReactCompareImage from "react-compare-image"
import toast from "react-hot-toast"
import { useReadyPictureConfiguratorStore } from "./ReadyPictureConfigurator.store"
import {
    IUploadedFile,
    ReadyPictureConfiguratorPictureUploader,
} from "./ReadyPictureConfiguratorPictureUploader"

const BLANK_PICTURE =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8+h8AAu8B9totwrcAAAAASUVORK5CYII="

/*
    "v1/pictures-generator/prebuilt/get",
    "v1/pictures-generator/prebuilt/create-or-update"
    "v1/pictures-generator/prebuilt/delete"
*/

export default function ReadyPictureConfiguratorPicturePart({
    getPictureUrl,
    updateUrl,
    deleteUrl,
    title,
    id,
}: {
    updateUrl: string
    deleteUrl: string
    getPictureUrl: string
    title: string
    id: number
}) {
    const {
        activeGroup,
        activeModel,
        activeProducer,
        activeSeries,
        filledAttributes,
    } = useReadyPictureConfiguratorStore()

    const [uploadedPicture, setUploadedPicture] = useState<IUploadedFile>()
    const [existingPictureLink, setExistingPictureLink] = useState<string>("")

    useQuery({
        queryKey: [
            "get-picture",
            getPictureUrl,
            activeGroup,
            activeModel,
            activeSeries,
            activeProducer,
            filledAttributes,
        ],
        enabled:
            !!activeProducer &&
            !!activeGroup &&
            !!activeSeries &&
            !!activeModel,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api
                .post<any, { picture: { public_link: string } }>(
                    getPictureUrl,
                    {},
                    {
                        producer: activeProducer!.value,
                        group: activeGroup!.value,
                        model: activeModel!.value,
                        series: activeSeries!.value,
                        payload: filledAttributes,
                    }
                )
                .then(({ data: { picture } }) => picture.public_link),
        onSuccess: (v) => setExistingPictureLink(v),
        onError: () => {
            setExistingPictureLink("")
            setUploadedPicture(undefined)
        },
    })

    const handleSubmit = () =>
        toast.promise(
            api.post<any, any>(
                updateUrl,
                {},
                {
                    producer: activeProducer!.value,
                    itemsGroup: activeGroup!.value,
                    model: activeModel!.value,
                    series: activeSeries!.value,
                    picture: uploadedPicture!.id,
                    payload: filledAttributes,
                }
            ),
            {
                loading: "Загрузка...",
                success: () => {
                    return <b>Соответствие успешно сохранено!</b>
                },
                error: (error = "Не удалось загрузить, неизвестная ошибка") => {
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )

    const handleDelete = () =>
        toast.promise(
            api.post<any, any>(
                deleteUrl,
                {},
                {
                    producer: activeProducer!.value,
                    itemsGroup: activeGroup!.value,
                    model: activeModel!.value,
                    series: activeSeries!.value,
                    payload: filledAttributes,
                }
            ),
            {
                loading: "Загрузка...",
                success: () => {
                    setExistingPictureLink("")
                    return <b>Успешно удалено!</b>
                },
                error: (error = "Не удалось удалить, неизвестная ошибка") => {
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )

    useEffect(() => {
        setExistingPictureLink("")
        setUploadedPicture(undefined)
    }, [filledAttributes])

    return (
        <Flex.Col gap={10} fullWidth>
            <h5>{title}</h5>
            <div className="fw">
                <button
                    className="btn btn-primary"
                    disabled={
                        !uploadedPicture ||
                        !Object.keys(filledAttributes).length
                    }
                    onClick={() => handleSubmit()}
                >
                    {existingPictureLink ? "Обновить" : "Сохранить"}{" "}
                    соответствие
                </button>

                {existingPictureLink && (
                    <button
                        type="button"
                        className="btn btn-danger d-inline-block ms-2"
                        onClick={() => handleDelete()}
                    >
                        Удалить
                    </button>
                )}
            </div>

            <hr />

            <div className="fw">
                <ReadyPictureConfiguratorPictureUploader
                    onUploaded={(e) => setUploadedPicture(e)}
                    uploadedPicture={uploadedPicture}
                    id={id}
                />
            </div>

            {(uploadedPicture?.public_link || existingPictureLink) && (
                <div className="fw">
                    <ReactCompareImage
                        leftImage={
                            uploadedPicture?.public_link || BLANK_PICTURE
                        }
                        rightImage={existingPictureLink || BLANK_PICTURE}
                        rightImageLabel="Активно"
                        leftImageLabel="Загружено"
                        aspectRatio="taller"
                    />
                </div>
            )}
        </Flex.Col>
    )
}
