import { useQuery } from "@tanstack/react-query"
import { SelectEntity } from "components/Selects/types"
import api from "helpers/api"
import { useEffect, useMemo, useState } from "react"
import { AttributeListItem } from "types"

export const useMassAttrubuteReplace = () => {
    const [activeGroup, setActiveGroup] = useState<SelectEntity | null>(null)
    const [activeProducer, setActiveProducer] = useState<SelectEntity | null>(
        null
    )
    const [activeSeries, setActiveSeries] = useState<SelectEntity | null>(null)
    const [activeModel, setActiveModel] = useState<SelectEntity | null>(null)
    const [activeAttribute, setActiveAttribute] = useState<AttributeListItem>()

    const { apiPath, params } = useMemo(() => {
        const activeItems = [
            activeGroup,
            activeProducer,
            activeSeries,
            activeModel,
        ].filter(Boolean)

        if (activeItems.length === 2) {
            return {
                apiPath: "attribute/list-by-group-and-producer",
                params: {
                    producer: activeProducer?.value,
                    group: activeGroup?.value,
                },
            }
        }

        if (activeItems.length === 3) {
            return {
                apiPath: `v1/series/${activeSeries!.value}/items-group/${
                    activeGroup!.value
                }/attribute-links`,
            }
        }

        if (activeItems.length === 4) {
            return {
                apiPath: `v1/model/${activeModel!.value}/series/${
                    activeSeries!.value
                }/items-group/${activeGroup!.value}/attribute-links`,
            }
        }
        return { apiPath: "", params: {} }
    }, [activeGroup, activeProducer, activeSeries, activeModel])

    const {
        data: attributeList,
        isLoading: attributeListLoading,
        refetch: refetchAttributes,
    } = useQuery<AttributeListItem[]>({
        queryKey: [
            activeGroup?.value,
            activeProducer?.value,
            activeSeries?.value,
            activeModel?.value,
        ],
        queryFn: () => api.getTyped(apiPath, params),
        enabled: !!apiPath,
    })

    const activeValues = useMemo(() => {
        return Object.entries(activeAttribute?.attribute.valuesList ?? {})
    }, [activeAttribute])

    useEffect(() => {
        setActiveSeries(null)
        setActiveModel(null)
    }, [activeProducer])

    useEffect(() => {
        setActiveProducer(null)
    }, [activeGroup])

    useEffect(() => {
        setActiveModel(null)
    }, [activeSeries])

    useEffect(() => {
        const attribute = attributeList?.find(
            (el) => el.attribute.id === activeAttribute?.attribute.id
        )
        if (attribute) {
            setActiveAttribute(attribute)
        }
    }, [attributeList, activeAttribute])

    return {
        activeGroup,
        setActiveGroup,
        activeProducer,
        setActiveProducer,
        activeSeries,
        setActiveSeries,
        activeModel,
        setActiveModel,
        attributeList,
        attributeListLoading,
        showAttributeContainer: !!activeGroup && !!activeProducer,
        activeAttribute,
        setActiveAttribute,
        activeValues,
        refetchAttributes,
    }
}
