import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useSearchParam } from "react-use"
import api from "../helpers/api"
import Loader from "../partials/Loader"
import { LoginProps } from "../types/interfaces"

const Login: React.FC<LoginProps> = ({ onLogin, onError }) => {
    const reason = useSearchParam("reason")
    const redirectTo = useSearchParam("redirect_to")
    const history = useHistory()

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [hasError, setHasError] = useState<boolean>(false)
    const [loaderShowed, setLoaderShowed] = useState<boolean>(false)

    const changeUsernameHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => setUsername(event.target.value)
    const changePasswordHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => setPassword(event.target.value)
    const signInHandler = () => {
        setLoaderShowed(true)
        loginHandler()
    }
    const loginHandler = () => {
        api.post(
            "login_check",
            {},
            {
                username: username,
                password: password,
            }
        )
            .then(() => {
                setHasError(false)
                onLogin && onLogin()
                setLoaderShowed(false)

                if (redirectTo) {
                    return history.push(redirectTo)
                }
            })
            .catch(() => {
                setHasError(true)
                setLoaderShowed(false)
                onError && onError()
            })
    }
    const keyPressHandler = (event: React.KeyboardEvent) => {
        setHasError(false)
        if (event.key === "Enter") {
            event.preventDefault()
            loginHandler()
        }
    }

    return (
        <div className="form-signin text-center">
            <form>
                <img
                    className="mb-4"
                    src="./img/svg/bootstrap-logo.svg"
                    alt=""
                    width="72"
                    height="57"
                />
                <h1 className="h3 mb-3 fw-normal">Авторизация</h1>

                {reason && (
                    <div className="alert alert-danger">
                        Сессия авторизация истекла. Пожалуйста, войдите в
                        аккаунт заново.
                    </div>
                )}

                <div className="form-floating">
                    <input
                        onChange={changeUsernameHandler}
                        onKeyPress={keyPressHandler}
                        type="email"
                        className="form-control"
                        placeholder="name@example.com"
                    />
                    <label>Почта</label>
                </div>
                <div className="form-floating">
                    <input
                        onChange={changePasswordHandler}
                        onKeyPress={keyPressHandler}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="current-password"
                    />
                    <label>Пароль</label>
                </div>
                {hasError ? (
                    <div className="alert alert-danger" role="alert">
                        Неправильная почта, или пароль!
                    </div>
                ) : (
                    <br />
                )}
                {loaderShowed ? (
                    <Loader />
                ) : (
                    <button
                        onClick={signInHandler}
                        className="w-100 btn btn-lg btn-primary"
                        type="button"
                    >
                        Войти
                    </button>
                )}
            </form>
        </div>
    )
}

export default Login
