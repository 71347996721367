import { Listing } from "components/Listing"
import { AccessControlPage } from "pages/AccessControlPage"
import { CreateRouteData } from "./tools"

export const userRoutes: CreateRouteData[] = [
    {
        path: "role",
        title: "Роли",
        render: () => <Listing />,
        createAndUpdate: () => <AccessControlPage />,
    },
    {
        path: "user",
        title: "Пользователи",
        render: () => <Listing />,
        createAndUpdate: () => <AccessControlPage />,
    },
]
