import { Flex } from "components/UI/Flex"
import { FC } from "react"
import { AttributeListItem } from "types"
import AccountingSelectSeries from "./AccountingSelectSeries"
import ActionsButtons from "./ActionsButtons"
import NamingSelectSeries from "./NamingSelectSeries"
import styles from "./namingSelectWithActions.module.scss"

interface IValue {
    value: string
    label: string
    sort: number
}

type Attribute = "name" | "accounting"
type Items = "model" | "series"

const NamingSelectWithActions: FC<{
    attributes?: AttributeListItem[]
    values: {
        accountingNameAttributes: IValue[]
        nameAttributes: IValue[]
    }
    onChange: (prop: { typeAttribute: Attribute; value: IValue[] }) => void
    options: IValue[]
    hiddenCopyButton?: boolean
    type: Items
    copy?: (prop: { toAttributes: Attribute }) => void
    dubName?: string
}> = ({
    attributes,
    values,
    onChange,
    options,
    hiddenCopyButton,
    type,
    copy = () => {},
    dubName,
}) => {
    const formName = (array: IValue[]) => () => {
        const currentArray = (
            array.map((item) =>
                attributes?.find((subitem) => subitem.id === item.value)
            ) as {
                attribute: {
                    valuesList: Record<string, string>
                }
            }[]
        ).map(
            ({ attribute }) =>
                Object.values(attribute.valuesList)[
                    Math.floor(
                        Math.random() *
                            Object.values(attribute.valuesList).length
                    )
                ]
        )

        return [dubName, ...currentArray].join(" ").trim()
    }

    return (
        <Flex.Col
            gap={24}
            align="start"
            padding={"20px 0 29px 0"}
            fullWidth
            className={styles.container}
        >
            <AccountingSelectSeries
                options={options}
                onChange={(val) =>
                    onChange({ value: val, typeAttribute: "accounting" })
                }
                type={type}
                value={values.accountingNameAttributes}
            >
                <ActionsButtons
                    formName={formName(values.accountingNameAttributes)}
                    type="accounting"
                    disabledFormNaming={!values.accountingNameAttributes.length}
                    hiddenCopyButton={hiddenCopyButton}
                    copy={() => copy({ toAttributes: "accounting" })}
                />
            </AccountingSelectSeries>
            <NamingSelectSeries
                options={options}
                type={type}
                onChange={(val) =>
                    onChange({ value: val, typeAttribute: "name" })
                }
                value={values.nameAttributes}
            >
                <ActionsButtons
                    formName={formName(values.nameAttributes)}
                    type="userAccount"
                    disabledFormNaming={!values.nameAttributes.length}
                    hiddenCopyButton={hiddenCopyButton}
                    copy={() => copy({ toAttributes: "name" })}
                />
            </NamingSelectSeries>
        </Flex.Col>
    )
}

export default NamingSelectWithActions
