import { Uuid } from "types"
import { NomenclatureItem } from "types/api"

export const tranformers: Record<string, (v: any) => void> = {
    itemsGroups: (data: Array<{ label: string; value: Uuid }>) => {
        return data.map((el) => el.value)
    },
    analogues: (data?: NomenclatureItem[]) => (data ?? []).map((el) => el.id),
}

export const transformSendData = (data: Record<string, any>) => {
    const newData = { ...data }
    Object.keys(data).forEach((key) => {
        if (tranformers[key]) {
            newData[key] = tranformers[key](data[key])
        }
    })
    return newData
}
