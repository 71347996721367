import { useQuery } from "@tanstack/react-query"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Uuid } from "types"
import api from "../../../helpers/api"
import { useConfiguratorStore } from "./GeneralConfiguratorWrapper"
import { ConfiguratorParams } from "./index"

export const ConfiguratorPartAttributes: React.FC<{
    params: ConfiguratorParams
    onIsFormCompleted: (state: boolean) => void
    isFormCompleted: boolean
}> = ({ params, onIsFormCompleted }) => {
    const [showDisabledOptions, setShowDisabledOptions] = useState(false)
    const [nextValues, setNextValues] = useState<Record<string, string[]>>({})

    const { filledAttributes, setFilledAttribute, setIsLoading } =
        useConfiguratorStore()

    const onFillAttributeBulk = (
        payload: { attributeId: Uuid; valueId?: Uuid }[]
    ): Record<string, string> => {
        let curr = filledAttributes

        payload.forEach(({ valueId, attributeId }) => {
            if (valueId) {
                curr = {
                    ...curr,
                    [attributeId]: valueId,
                }
            } else {
                delete curr[attributeId]
            }
        })

        setFilledAttribute(curr)
        return curr
    }

    const { data: valuesAlternativeNames } = useQuery<
        {
            attributeId: Uuid
            valuesAlternativeNames: Record<Uuid, string>
        }[]
    >({
        queryKey: [
            "configurator-alternative-names",
            params.group,
            params.producer,
        ],
        initialData: [],
        queryFn: () =>
            api.getTyped(
                "attribute/group_producer_attributes_all_list_alt_names",
                {
                    group: params.group,
                    producer: params.producer,
                }
            ),
    })

    const { data: unpublishedValuesList } = useQuery<
        { attributeId: Uuid; values: Uuid[] }[]
    >({
        queryKey: [params.group, params.producer],
        initialData: [],
        queryFn: () =>
            api.getTyped("attribute/group_producer_attributes_unpublished", {
                group: params.group,
                producer: params.producer,
            }),
    })

    const { data: attributesByGroupProducerWithSorting } = useQuery<
        {
            attribute: {
                valuesList: Record<Uuid, string>
                name: string
                id: Uuid
            }
            sortChoosing?: number
        }[]
    >({
        initialData: [],
        queryKey: [
            "configurator",
            "attributesByGroupProducerWithSorting",
            params.group,
            params.producer,
        ],
        queryFn: () =>
            api.getTyped<
                {
                    attribute: {
                        valuesList: Record<Uuid, string>
                        name: string
                        id: Uuid
                    }
                    sortChoosing?: number
                }[]
            >("attribute/group_producer_attributes_linking_details", {
                group: params.group,
                producer: params.producer,
            }),
    })

    const { data: attributesValues } = useQuery({
        queryKey: ["configurator", "attributesByGroupProducerSeries", params],
        initialData: [],
        queryFn: () =>
            api.getTyped<
                {
                    attributeId: Uuid
                    attribute: {
                        name: string
                        id: Uuid
                        valuesList: Record<Uuid, string>
                    }
                }[]
            >("attribute/list-by-group-and-producer-and-series", {
                producer: params.producer,
                group: params.group,
                series: params.series,
            }),
    })

    const { data: attributesAvailableValues } = useQuery({
        queryKey: ["configurator", "availableValues", params],
        initialData: [],
        queryFn: () =>
            api.getTyped<
                {
                    attributeId: Uuid
                    configurableAttributeValues: Uuid[]
                }[]
            >("attribute/list-by-series-and-model", {
                series: params.series,
                model: params.model?.value,
            }),
    })

    const { data: attributesAvailableValuesSeries } = useQuery({
        queryKey: [params],
        initialData: [],
        queryFn: () =>
            api.getTyped<
                {
                    attribute: {
                        name: string
                        id: Uuid
                        valuesList: Record<Uuid, string>
                    }
                    sortChoosing?: Uuid
                }[]
            >(
                `v1/series/${params.series}/items-group/${params.group}/attribute-links`
            ),
    })

    const attributes = useMemo(() => {
        if (
            !attributesByGroupProducerWithSorting ||
            !attributesValues ||
            !attributesAvailableValues
        )
            return []

        const availableSeriesSortChoosing =
            attributesAvailableValuesSeries.some(
                (item) =>
                    item.sortChoosing !== null &&
                    item.sortChoosing !== undefined
            )

        // console.info(attributesByGroupProducerWithSorting.map(v => v.sortChoosing))
        return attributesByGroupProducerWithSorting
            .map((item, i) => {
                const currentSortChoosing = availableSeriesSortChoosing
                    ? attributesAvailableValuesSeries.find(
                          (subitem) =>
                              subitem.attribute.id === item.attribute.id
                      )?.sortChoosing
                    : item.sortChoosing

                return {
                    ...item,
                    sortChoosing: Number(
                        // todo checkme
                        currentSortChoosing || 1000 + i // +
                        //Math.max(i, 1) // 1000 to indicate it was frontend-side assigned
                    ),
                }
            })

            .sort((a, b) => a.sortChoosing - b.sortChoosing)
            .filter((v) =>
                attributesValues?.some(
                    (valuesRow) => valuesRow.attributeId === v.attribute.id
                )
            )
            .filter((row) => {
                const availableValuesForCurrAttribute =
                    attributesAvailableValues?.find(
                        ({ attributeId }) => attributeId === row.attribute.id
                    ) ?? {}

                return Object.keys(availableValuesForCurrAttribute).length
            })
    }, [
        attributesValues,
        attributesByGroupProducerWithSorting,
        attributesAvailableValues,
        attributesAvailableValuesSeries,
    ])

    const getLeadingAttribute = (
        filledAttributes: Record<string, string>
    ): { id: Uuid; sortIndex: number } => {
        const result = attributes.reduce<[number, Uuid]>(
            (prev, curr, i) => {
                if (
                    filledAttributes[curr.attribute.id] &&
                    curr.sortChoosing >= prev[0]
                ) {
                    return attributes[i + 1]
                        ? [
                              attributes[i + 1].sortChoosing,
                              attributes[i + 1].attribute.id,
                          ]
                        : [curr.sortChoosing, curr.attribute.id]
                }

                return prev
            },
            [0, ""]
        )

        return {
            id: result[1],
            sortIndex: result[0],
        }
    }

    const [leadingAttributeSortIndex, leadingAttributeSortAttributeId] =
        useMemo<[number, Uuid]>(() => {
            const res = getLeadingAttribute(filledAttributes)
            return [res.sortIndex, res.id]
        }, [attributes, filledAttributes])

    const getValuesListByAttribute = (targetAttributeId: Uuid) => {
        return Object.entries(
            attributesValues?.find(
                ({ attributeId }) => attributeId === targetAttributeId
            )?.attribute.valuesList ?? {}
        )
            .map(([attributeValueId, attributeValueLabel]) => {
                const isAvailable = attributesAvailableValues
                    ?.find(
                        ({ attributeId }) => attributeId === targetAttributeId
                    )
                    ?.configurableAttributeValues?.some(
                        (configurableAttributeId) =>
                            configurableAttributeId === attributeValueId
                    )

                const isPublished = isAvailable
                    ? !(
                          unpublishedValuesList
                              .find((v) => v.attributeId === targetAttributeId)
                              ?.values.includes(attributeValueId) ?? false
                      )
                    : false

                return {
                    id: attributeValueId,
                    name: attributeValueLabel,
                    shouldBeShown: isAvailable && isPublished,
                }
            })
            .sort((a, b) =>
                a.name
                    .toLowerCase()
                    .trim()
                    .localeCompare(b.name.toLowerCase().trim())
            )
    }

    const prepareFetchedValuesForAttribute = useCallback(
        (targetAttributeId: Uuid) => {
            const pureValuesList = getValuesListByAttribute(targetAttributeId)

            return (
                nextValues[targetAttributeId]?.map((value) => {
                    const foundRecord = pureValuesList?.find(
                        ({ id }) => id === value
                    )
                    if (foundRecord) {
                        return foundRecord
                    }

                    return {
                        id: value,
                        name: `Unknown value: ${value}`,
                        shouldBeShown: true,
                    }
                }) ?? []
            )
        },
        [
            attributesAvailableValues,
            attributesValues,
            valuesAlternativeNames,
            unpublishedValuesList,
            nextValues,
        ]
    )

    const handleChangeAttribute = async (
        attributeId: Uuid,
        valueId: Uuid,
        sortChoosing: number
    ) => {
        let values_ = nextValues
        attributes.forEach((attributeCandidate) => {
            if (attributeCandidate.sortChoosing > sortChoosing) {
                delete values_[attributeCandidate.attribute.id]
            }
        })

        setNextValues(values_)

        const actualFilled = onFillAttributeBulk([
            {
                attributeId,
                valueId,
            },
            ...attributes
                .filter(
                    (attributeCandidate) =>
                        attributeCandidate.sortChoosing > sortChoosing
                )
                .map((v) => ({
                    attributeId: v.attribute.id,
                    valueId: undefined,
                })),
        ])

        const leadingAttribute = getLeadingAttribute(actualFilled)
        const { data } = await api.post<any, { data: string[] }>(
            "v1/compatibility/calculate-by-route/",
            {},
            {
                model: params.model?.value,
                route: Object.values(actualFilled),
                candidates: getValuesListByAttribute(leadingAttribute.id)
                    .filter((shouldBeShown) => !!shouldBeShown)
                    .map(({ id }) => id),
            }
        )

        setNextValues({
            ...nextValues,
            [leadingAttribute.id]: data.data,
        })
    }

    useEffect(() => {
        onIsFormCompleted(
            attributes.length === Object.keys(filledAttributes).length
        )
    }, [filledAttributes, attributes])

    useEffect(() => {
        setNextValues({})
    }, [params])

    useEffect(() => {
        if (attributes.length) {
            setIsLoading(false)
        }
    }, [attributes])

    // hack to prevent react useEffect infinity loop
    const lastAttributeSetRef = useRef(false)
    useEffect(() => {
        if (leadingAttributeSortAttributeId === "") {
            // skip if first attribute doesn't choosen yet
            return
        }

        const lastAttributeSortIndex = [...attributes].reverse()[0]
            ?.sortChoosing
        if (
            lastAttributeSortIndex === leadingAttributeSortIndex &&
            lastAttributeSetRef.current === true
        ) {
            // if last element already set, break the loop and reset lastAttributeSetRef.current
            lastAttributeSetRef.current = false
            return
        }

        // get values for current attribute
        const leadingAttributeValues = prepareFetchedValuesForAttribute(
            leadingAttributeSortAttributeId
        ).filter(({ shouldBeShown }) => shouldBeShown)

        if (leadingAttributeValues.length !== 1) {
            // stop loop if current attribute have >1 values, putting control in the hands of the user
            return
        }

        // if only one attribute available, choose it
        handleChangeAttribute(
            leadingAttributeSortAttributeId,
            leadingAttributeValues[0]?.id,
            leadingAttributeSortIndex
        )

        // if it was last attribute, reset lastAttributeSetRef.current
        if (
            lastAttributeSortIndex === leadingAttributeSortIndex &&
            lastAttributeSetRef.current === false
        ) {
            lastAttributeSetRef.current = true
        }
    }, [leadingAttributeSortIndex, nextValues])

    return (
        <>
            <div className="control-in mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="m-0">Свойства</h5>

                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={showDisabledOptions}
                            onChange={() =>
                                setShowDisabledOptions(!showDisabledOptions)
                            }
                        />
                        <label className="form-check-label">
                            Отображать недоступные значения
                        </label>
                    </div>
                </div>

                {attributes.map((row, i) => {
                    const isAvailable =
                        i === 0 ||
                        leadingAttributeSortIndex >=
                            (attributes[i]?.sortChoosing ??
                                Number.MAX_SAFE_INTEGER)

                    let values: {
                        id: string
                        name: string
                        shouldBeShown: boolean | undefined
                    }[] = []

                    if (isAvailable) {
                        values = i
                            ? prepareFetchedValuesForAttribute(row.attribute.id)
                            : getValuesListByAttribute(row.attribute.id)
                    }

                    return (
                        <div
                            className="row mb-3"
                            style={{ opacity: isAvailable ? 1 : 0.5 }}
                            key={`attribute-row-${row.attribute.id}`}
                        >
                            <label className="col-xl-3 col-form-label">
                                [{row.sortChoosing}] {row.attribute.name}
                            </label>
                            <div className="col-xl-9 d-flex align-items-center">
                                <div
                                    className="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                    style={{
                                        margin: "10px",
                                        opacity:
                                            !nextValues[row.attribute.id] &&
                                            isAvailable &&
                                            i !== 0
                                                ? 1
                                                : 0,
                                    }}
                                ></div>

                                <select
                                    className="form-select ml-2"
                                    value={
                                        filledAttributes[row.attribute.id] ??
                                        "default"
                                    }
                                    disabled={!isAvailable}
                                    onChange={(e) => {
                                        handleChangeAttribute(
                                            row.attribute.id,
                                            e.target.value,
                                            row.sortChoosing
                                        )
                                    }}
                                >
                                    <option value="default" disabled>
                                        Необходимо выбрать значение
                                    </option>

                                    {values.map((option) => {
                                        if (
                                            !showDisabledOptions &&
                                            !option.shouldBeShown
                                        )
                                            return null

                                        return (
                                            <option
                                                key={`configurator-option-${option.id}`}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
