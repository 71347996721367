import api from "../../helpers/api"

/** CATEGORY */
export const SET_CATEGORY = (payload: any) => ({
    type: "SET_CATEGORY",
    payload: payload,
})

/** THUNK */
export const getCategory: any = (url: string) => {
    return async (dispatch: any) => {
        await api
            .get(url)
            .then((response) => {
                dispatch(SET_CATEGORY(response.data))
            })
            .catch(() => {})
    }
}
