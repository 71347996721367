import React from "react"
import { AlterNameCell } from "./listComponents/AlternateNameCell"
import { AttributeListCell } from "./listComponents/AttributesListCell"
import AttributeValueCell from "./listComponents/AttributeValueCell"
import { CalculationTypeCell } from "./listComponents/CaclulationType"
import { Checkbox } from "./listComponents/Checkbox"
import { CountryCell } from "./listComponents/Country"
import { DateCell } from "./listComponents/DateCell"
import { DateTime } from "./listComponents/Datetime"
import { DecimalsCell } from "./listComponents/DecimalsCell"
import { FileDownload } from "./listComponents/FileDownload"
import { Input } from "./listComponents/Input"
import { MarginTypeCell } from "./listComponents/MarginTypeCell"
import MeasureCell from "./listComponents/MeasureCell"
import { NameObjectCell } from "./listComponents/NameObjectCell"
import ObjectColumnCell from "./listComponents/ObjectColumnCell"
import { PriceCalculationRulePriceMarginPresetLinkCell } from "./listComponents/PriceCalculationRulePriceMarginPresetLinkCell"
import { PriceMarginPresetLinkCell } from "./listComponents/PriceMarginPresetLinkCell"
import { PriceNomenclatureFiltersCell } from "./listComponents/PriceNomenclatureFilters"
import { PriceTypeCell } from "./listComponents/PriceTypeCell"
import { RecordValueCell } from "./listComponents/RecordValueCell"
import { RegionTypeCell } from "./listComponents/RegionTypeCell"
import { RoundingStrategyCell } from "./listComponents/RoundingStrategyCell"
import { Select } from "./listComponents/Select"
import { Text } from "./listComponents/Text"
import { UploadFile } from "./listComponents/UploadFile"

interface ListItemComponentSelecterProps {
    type: string
    name: string
    value: any
}

const countryFieldTypes = new Set(["importCountry", "producingCountry"])

const nameObjectCellKeys = new Set(["parentGroup"])

const renderAssociate: { [key: string]: React.FC<any> } = {
    text: Text,
    datetime: DateTime,
    number: Text,
    select: Select,
    entity: Text,
    input: Input,
    textarea: Text,
    checkbox: Checkbox,
    upload_file: UploadFile,
    uuid: Text,
    integer: Text,
    "file-download": FileDownload,
    series: ObjectColumnCell,
    itemsGroup: ObjectColumnCell,
    producer: ObjectColumnCell,
    currency: ObjectColumnCell,
    tnved: ObjectColumnCell,
    okrb: ObjectColumnCell,
    country: ObjectColumnCell,
    attributeFirstValue: AttributeValueCell,
    attributeSecondValue: AttributeValueCell,
    measureUnit: MeasureCell,
    alternativeName: AlterNameCell,
    model: ObjectColumnCell,
    priceNomenclatureFilters: PriceNomenclatureFiltersCell,
    priceCalculationRulePriceMarginPresetLink:
        PriceCalculationRulePriceMarginPresetLinkCell,
    priceType: PriceTypeCell,
    calculationType: CalculationTypeCell,
    attributes: AttributeListCell,
}

export const ListItemComponentSelecter: React.FC<
    ListItemComponentSelecterProps
> = ({ ...data }) => {
    if (data.name === "measureUnit") {
        return React.createElement(MeasureCell, { value: data.value })
    }

    if (countryFieldTypes.has(data.type)) {
        return <CountryCell value={data.value} />
    }

    if (data.name === "roundingStrategy") {
        return <RoundingStrategyCell value={data.value} />
    }
    if (data.name === "alternativeName") {
        return React.createElement(AlterNameCell, { value: data.value })
    }
    if (data.name === "decimals") {
        return <DecimalsCell value={data.value} />
    }
    if (data.name === "calculationType") {
        return <CalculationTypeCell value={data.value} />
    }
    if (data.name === "priceType") {
        return <PriceTypeCell value={data.value} />
    }
    if (data.name === "startAt" || data.name === "endAt") {
        return <DateCell value={data.value} />
    }
    if (data.name === "priceCalculationRulePriceMarginPresetLink") {
        return <PriceMarginPresetLinkCell value={data.value} />
    }

    if (data.name === "marginType") {
        return <MarginTypeCell value={data.value} />
    }

    if (nameObjectCellKeys.has(data.type)) {
        return <NameObjectCell value={data.value} />
    }

    if (
        ["region", "calculationTypeRegion", "priceTypeRegion"].includes(
            data.type
        )
    ) {
        return <RegionTypeCell value={data.value} />
    }

    if (
        [
            "attributeValue",
            "firstAttributeValueCriteria",
            "secondAttributeValueCriteria",
        ].includes(data.name)
    ) {
        return <RecordValueCell value={data.value} />
    }

    const _data = { ...data } as any
    if (!_data.value) {
        _data.value = {
            id: null,
            name: null,
        }
    }
    return renderAssociate[data.type] ? (
        React.createElement(renderAssociate[data.type], _data)
    ) : (
        <>Unknown column type: {data.type}</>
    )
}
