import { usePageData } from "hooks/usePageData"
import React from "react"
import { ModelEntity } from "types/api"
import ModelAdditionalDatail from "./Model"
import ModelAttributes from "./ModelAttributes"

export const builders = {
    model: (data: any) => ModelAdditionalDatail({ data }),
} as Record<string, (data: any) => JSX.Element>

export const DetailAdditionalData = ({ data }: { data: ModelEntity }) => {
    const { slug } = usePageData()

    const Component = builders[slug]

    return Component ? Component(data) : null
}

export const additionInputsBuilder = {
    model: (data: any) => React.createElement(ModelAttributes, { data }),
} as Record<string, (data: any) => JSX.Element>

export const DetailAdditionalFields = ({ data }: { data: ModelEntity }) => {
    const { slug } = usePageData()

    const Component = additionInputsBuilder[slug]

    return Component ? Component(data) : null
}
