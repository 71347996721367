import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const MultipleInputText: React.FC<InputProps> = (props) => {
    const { propertyContainer, textError, group } = props

    const state = useSelector((state: { [key: string]: any }) => state)

    const dispatch = useDispatch()

    const defaultEmptyFieldsList = Object.fromEntries(
        Array.from({ length: 3 }).map(() => [uuidv4(), ""])
    )

    const currentItems =
        state.fields.currentValues[group][propertyContainer.getName()] ||
        defaultEmptyFieldsList

    const isInvalid = textError && "is-invalid"

    const addValuesHandler = () => {
        dispatch(
            SET_CURRENT_VALUES(
                {
                    [propertyContainer.getName()]: {
                        ...currentItems,
                        [uuidv4()]: "",
                    },
                },
                group
            )
        )
    }

    const inputHandler = (
        index: any,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        currentItems[index] = event.target.value
        dispatch(
            SET_CURRENT_VALUES(
                { [propertyContainer.getName()]: currentItems },
                group
            )
        )
    }

    const renderingValues = Array.isArray(currentItems)
        ? currentItems?.map((item: string, index: number) => {
              if (item === null) return null
              return (
                  <div className="row" key={index}>
                      <div className="col direct-breadcrumbs">
                          <input
                              className={`form-control ${isInvalid}`}
                              type={
                                  propertyContainer.getType() ===
                                  "multipleNumber"
                                      ? "number"
                                      : "text"
                              }
                              id={`${index}`}
                              value={item}
                              name={`${propertyContainer.getName()}[${index}]`}
                              placeholder={propertyContainer.get("placeholder")}
                              disabled={propertyContainer.get("readonly")}
                              onChange={(event) => {
                                  inputHandler(index, event)
                              }}
                          />
                          {textError && <TextError text={textError} />}
                      </div>
                  </div>
              )
          })
        : Object.keys(currentItems)?.map((index) => {
              const item = currentItems[index]
              if (item === null) return null
              return (
                  <div className="row" key={index}>
                      <div className="col direct-breadcrumbs">
                          <input
                              className={`form-control ${isInvalid}`}
                              type={
                                  propertyContainer.getType() ===
                                  "multipleNumber"
                                      ? "number"
                                      : "text"
                              }
                              id={`${index}`}
                              value={item}
                              name={`${propertyContainer.getName()}[${index}]`}
                              placeholder={propertyContainer.get("placeholder")}
                              disabled={propertyContainer.get("readonly")}
                              onChange={(event) => {
                                  if (
                                      Object.values(currentItems).includes(
                                          event.target.value
                                      )
                                  ) {
                                      event.target.classList.add("is-invalid")
                                  } else {
                                      event.target.classList.remove(
                                          "is-invalid"
                                      )
                                  }
                                  inputHandler(index, event)
                              }}
                          />
                          {textError && <TextError text={textError} />}
                      </div>
                  </div>
              )
          })

    return (
        <div className="row mb-3">
            <label
                htmlFor={propertyContainer.getId()}
                className="col-xl-3 col-form-label"
            >
                {propertyContainer.get("required") ? <Required /> : null}
                {propertyContainer.get("label")}
            </label>
            <div
                className="col-xl-9"
                style={{ maxHeight: 300, overflow: "auto" }}
            >
                {renderingValues}
                <button
                    onClick={addValuesHandler}
                    type="button"
                    className="btn btn-outline-primary"
                >
                    Еще
                </button>
            </div>
        </div>
    )
}

export default MultipleInputText
