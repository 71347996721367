import { RuleGroupType } from "react-querybuilder"
import { Uuid } from "types"
import RequesitesDeliveryTime from "./RequesitesDeliveryTime"
import RequesitesStock from "./RequesiteStock"

export type RequesiteType = "deliveryTime" | "stock" | "discontinued"

export const getRequesiteComponent = (v: RequesiteType | null) => {
    switch (v) {
        case "deliveryTime": {
            return RequesitesDeliveryTime
        }
        case "stock": {
            return RequesitesStock
        }
        case "discontinued": {
            return RequesitesStock
        }
        default: {
            return null
        }
    }
}

export type SetRequisitePropsRequest = {
    propsType: RequesiteType
    props: string | string[]
    payload: {
        query: RuleGroupType<any>
        id?: Uuid
    }
}

export const getRequesiteName = (v: RequesiteType) => {
    switch (v) {
        case "deliveryTime": {
            return "Срок поставки"
        }
        case "stock": {
            return "Складская позиция"
        }
        default: {
            return ""
        }
    }
}
