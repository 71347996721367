export const getUrlFilters = (locationSearch: string) => {
    const filtersString = locationSearch?.match(/filter=[^&]+/)?.[0] ?? ""
    const filterValuesObject: Record<
        string,
        {
            value: string
            compareType: string
        }
    > = filtersString
        .replace(/filter=/, "")
        .split(";")
        .filter(Boolean)
        .reduce(
            (acc, filterItemString) => {
                const [key, compareType, value] = filterItemString.split("--")
                acc[key] = {
                    value: decodeURIComponent(value),
                    compareType,
                }
                return acc
            },
            {} as Record<
                string,
                {
                    value: string
                    compareType: string
                }
            >
        )

    const filter: Record<string, string> = {}
    const compareTypes: Record<string, string> = {}

    Object.entries(filterValuesObject).forEach(
        ([key, { value, compareType }]) => {
            filter[key] = value
            compareTypes[key] = compareType
        }
    )
    return {
        filter,
        compareTypes,
        filtersString,
        isEmpty:
            Object.keys(filter).length === 0 ||
            Object.keys(compareTypes).length === 0,
    }
}
