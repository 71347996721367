import { Button } from "antd"
import { ButtonProps } from "./type"

export default function CopyButton({
    disabled,
    onClick,
    loading,
    type = "accounting",
    className = "",
}: ButtonProps & { type?: "accounting" | "userAccount"; className?: string }) {
    return (
        <Button
            type="text"
            disabled={disabled}
            loading={loading}
            style={{ minWidth: 370 }}
            icon={<img src={"/img/svg/copy.svg"} alt="" />}
            onClick={onClick}
            className={className}
        >
            {type === "accounting"
                ? "Использовать пользовательское наименование"
                : "Использовать бухгалтерское наименование"}
        </Button>
    )
}
