import { AsyncSelect } from "components/UI"
import { AsyncPaginateProps } from "react-select-async-paginate"

import { Uuid } from "types"
import styles from "./wrapperForAsyncSelect.module.scss"

type SelectProps<ResponseType> = {
    dataUrl: string
    params?: object
    page?: number
    noMessage?: string
    loadMessage?: string
    noDefaultOption?: boolean
    isClearable?: boolean
    onChange: (v: any, noReset?: true) => void
    onFirstOptionLoad?: () => void
    skipValues?: Uuid[]
    externalOptions?: any[]
    filter?: (v: any) => any
    transformResponse?: (v: ResponseType) => any
    regionDependent?: boolean
    label: string
    link?: {
        text: string
        href: string
    }
} & Omit<AsyncPaginateProps<any, any, any, any>, "loadOptions" | "onChange">

export default function WrapperForAsyncSelect<ResponseType>({
    label,
    link,
    ...props
}: SelectProps<ResponseType>) {
    return (
        <div className={styles.wrapperSelect}>
            <label>{label}</label>
            <AsyncSelect {...props} />
            {!!link && (
                <a target="_blank" rel="noreferrer" href={link.href}>
                    {link.text}
                </a>
            )}
        </div>
    )
}
