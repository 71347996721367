import AccordionContainer from "components/UI/AccordionContainer"
import { Flex } from "components/UI/Flex"
import { FC } from "react"

interface IItemsAccordion {
    items: any[]
    isFetched: boolean
    onClick?: (val: string) => void
}

const ItemsAccordion: FC<IItemsAccordion> = ({
    isFetched,
    items,
    onClick = () => {},
}) => {
    const renderContent = () => {
        if (!isFetched) {
            return "Загрузка..."
        }
        if (!items.length) {
            return "В номенклатурной группе серии не найдены"
        }
        return <AccordionContainer items={items} onClick={onClick} />
    }

    return (
        <Flex.Col className="control-in" align="start" fullWidth>
            {renderContent()}
        </Flex.Col>
    )
}

export default ItemsAccordion
