import { Spin, Typography } from "antd"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import { useState } from "react"
import { Uuid } from "types"
import classes from "./StandartKitDetail.module.sass"

export default function StandardKitDetailPicture({ id }: { id: Uuid }) {
    const [isFetching, setIsFetching] = useState(true)
    const [isError, setIsError] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <Flex.Col gap={20} fullWidth>
            <div className={classes.picture}>
                {isFetching && !isError && (
                    <Flex.Col
                        fullWidth
                        fullHeight
                        align="center"
                        justify="center"
                        absolute
                    >
                        <Spin />
                    </Flex.Col>
                )}
                {isError && (
                    <Flex.Col
                        fullWidth
                        fullHeight
                        align="center"
                        justify="center"
                        absolute
                    >
                        <Typography.Text type="danger">
                            Ошибка загрузки изображения
                        </Typography.Text>
                    </Flex.Col>
                )}
                <img
                    src={`${import.meta.env.VITE_APP_API_HOST}v1/standard-kit/picture/${id}`}
                    alt={id}
                    className={clsx(classes.image, {
                        [classes.hidden]: !imageLoaded,
                    })}
                    onLoad={() => {
                        setImageLoaded(true)
                        setIsFetching(false)
                        setIsError(false)
                    }}
                    onError={() => {
                        setIsError(true)
                        setIsFetching(false)
                        setImageLoaded(false)
                    }}
                />
            </div>
        </Flex.Col>
    )
}
