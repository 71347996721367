import { AsyncSelect } from "components/UI"
import { PropsWithChildren, useCallback, useState } from "react"
import { useSelector } from "react-redux"

export type FilterInputEntity = "model" | "series" | "items-group" | "producer"

type EnitityData = { label: string; value: number }

interface IInputEntities {
    entities: FilterInputEntity[]
    setValue: (key: FilterInputEntity, value: any) => void
    resetValues: (keys: FilterInputEntity[]) => void
}

const Wrapper = ({
    children,
    name,
    label,
}: PropsWithChildren<{ label: string; name: string }>) => {
    return (
        <div className="row mb-2">
            <label htmlFor={name} className="col-xl-2 col-form-label">
                {label}
            </label>
            {children}
        </div>
    )
}

export default function InputEntities({
    entities,
    setValue,
    resetValues,
}: IInputEntities) {
    const [activeProducer, setActiveProducer] = useState<EnitityData>()
    const [activeGroup, setActiveGroup] = useState<EnitityData>()
    const [activeSeries, setActiveSeries] = useState<EnitityData>()

    const [loadedOptions, setLoadedOption] = useState<
        Record<FilterInputEntity, boolean>
    >({
        "items-group": false,
        model: false,
        producer: false,
        series: false,
    })

    const onFirstOptionLoad = useCallback(
        (key: FilterInputEntity) => () => {
            setLoadedOption((prev) => ({
                ...prev,
                [key]: true,
            }))
        },
        [setLoadedOption]
    )

    const values = useSelector(
        (state: any) => state.fields.currentValues.filter
    )

    const _resetValues = (keys: FilterInputEntity[]) => {
        const _keys = keys.filter((el) => loadedOptions[el])
        resetValues(_keys)
    }

    return (
        <>
            {entities.includes("items-group") && (
                <Wrapper label="Группа" name="items-group">
                    <AsyncSelect
                        onChange={(e: EnitityData) => {
                            setActiveProducer(undefined)
                            setActiveSeries(undefined)
                            setActiveGroup(e as EnitityData)
                            e?.value
                                ? setValue("items-group", e.value)
                                : resetValues(["items-group"])
                            loadedOptions["items-group"] &&
                                _resetValues(["model", "producer", "series"])
                        }}
                        defaultValue={values?.["itemsGroup"]}
                        dataUrl="items-group"
                        page={1}
                        onFirstOptionLoad={onFirstOptionLoad("items-group")}
                        isClearable
                    />
                </Wrapper>
            )}
            {entities.includes("producer") && loadedOptions["items-group"] && (
                <Wrapper label="Поставщик" name="producer">
                    <AsyncSelect
                        isDisabled={
                            !activeGroup?.value && !loadedOptions["items-group"]
                        }
                        onChange={(e: EnitityData) => {
                            setActiveSeries(undefined)
                            setActiveProducer(e)
                            e?.value
                                ? setValue("producer", e.value)
                                : resetValues(["producer"])
                            loadedOptions.producer &&
                                _resetValues(["model", "series"])
                        }}
                        defaultValue={values?.["producer"]}
                        dataUrl="producer"
                        params={{
                            itemsGroups: activeGroup?.value,
                        }}
                        onFirstOptionLoad={onFirstOptionLoad("producer")}
                        isClearable
                    />
                </Wrapper>
            )}
            {entities.includes("series") &&
                loadedOptions["items-group"] &&
                loadedOptions.producer && (
                    <Wrapper label="Серия" name="series">
                        <AsyncSelect
                            isDisabled={
                                !activeGroup?.value || !activeProducer?.value
                            }
                            onChange={(e: EnitityData) => {
                                setActiveSeries(e)
                                e?.value
                                    ? setValue("series", e.value)
                                    : resetValues(["series"])
                                loadedOptions.series && _resetValues(["model"])
                            }}
                            defaultValue={values?.["series"]}
                            dataUrl="series"
                            params={{
                                producer: activeProducer?.value,
                            }}
                            onFirstOptionLoad={onFirstOptionLoad("series")}
                            isClearable
                        />
                    </Wrapper>
                )}
            {entities.includes("model") &&
                loadedOptions["items-group"] &&
                loadedOptions["producer"] &&
                loadedOptions["series"] && (
                    <Wrapper label="Модель" name="model">
                        <AsyncSelect
                            isDisabled={
                                !activeGroup?.value ||
                                !activeProducer?.value ||
                                !activeSeries?.value
                            }
                            onChange={(e: EnitityData) => {
                                e?.value
                                    ? setValue("model", e.value)
                                    : resetValues(["model"])
                            }}
                            defaultValue={values?.["model"]}
                            dataUrl="model"
                            key={activeSeries?.value}
                            params={{
                                series: activeSeries?.value,
                                itemsGroups: activeGroup?.value,
                            }}
                            onFirstOptionLoad={onFirstOptionLoad("model")}
                            isClearable
                        />
                    </Wrapper>
                )}
        </>
    )
}
