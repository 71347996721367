import api from "../../helpers/api"
import { handleClickBatchActions } from "./partials/helpers/handleClickBatchActions"

/**
 * @param {string} type - тип события
 * @param {number} id - id элемента
 * @param {string} action - действие (по сути endpoint)
 * @param {number} fileId = id файла
 * @param {{[key:string]:any}} history - history из react-router
 * @param {any} dispatch - dispatch из react-redux
 */

export const eventSelecter = (
    type: string,
    id: number,
    action: string,
    fileId: number,
    history: { [key: string]: any },
    slug: any,
    dispatch: any,
    reloadFn: any,
    showNotification: (v: any) => void
) => {
    if (type === "toArchive") {
        const idsToDelete = [id]
        if (window.confirm("Вы хотите отправить запись " + id + " в архив?")) {
            api.post(action, {}, { idsToDelete })
                .then(() => {
                    showNotification({
                        type: "success",
                        message: "Отправлено в архив",
                    })
                    window.location.reload()
                })
                .catch((error) => {
                    showNotification({
                        type: "danger",
                        message: error.message,
                    })
                })
        }
    }

    if (type === "startImport") {
        if (!fileId) {
            showNotification({ type: "danger", message: "Выберите файл!" })
            return false
        }

        api.post<any, any>(action, null, { profileId: id, fileId })
            .then((response) => {
                if (response.data.status === "ok") {
                    showNotification({
                        type: "success",
                        message: `Импорт запущен. <a href="/import-log">Посмотреть отчеты</a>`,
                    })
                    return false
                } else {
                    showNotification({ type: "success", message: `Ошибка!` })
                    return false
                }
            })
            .catch(() => {})
    }

    if (type === "delete") {
        handleClickBatchActions(
            [id],
            slug,
            dispatch,
            history,
            reloadFn,
            showNotification
        )
    }

    if (type === "edit") {
        history.push(action)
    }
}
