import { AsyncSelect } from "components/UI"
import React, { useState } from "react"
import { Uuid } from "types"
import { ModelsValuesBindingChoosers } from "./ModelsValuesBindingChoosers"
export const ModelsAttributesValuesBinding: React.FC = () => {
    document.title = "Установка свойств и значений"

    const [activeProducer, setActiveProducer] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
        | null
    >(null)
    const [activeGroup, setActiveGroup] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
        | null
    >(null)

    return (
        <>
            <h1 className="h3 mb-3">Установка свойств и значений</h1>
            <div className="control">
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    <h5>Основное</h5>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Номенклатурная группа
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                onChange={(e) => {
                                                    setActiveProducer(undefined)
                                                    setActiveGroup(
                                                        e as typeof activeGroup
                                                    )
                                                }}
                                                className="mb-2"
                                                dataUrl="items-group"
                                                page={1}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Поставщик
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                onChange={(e) =>
                                                    setActiveProducer(
                                                        e as typeof activeProducer
                                                    )
                                                }
                                                className="mb-2"
                                                dataUrl="producer"
                                                params={{
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3 control-in__buttons">
                                        <button
                                            className="btn btn-primary"
                                            disabled
                                        >
                                            Создать серию
                                        </button>
                                        <button
                                            className="btn btn-primary ms-3"
                                            disabled
                                        >
                                            Создать модель
                                        </button>
                                        <button
                                            className="btn btn-primary ms-3"
                                            disabled
                                        >
                                            Создать свойство
                                        </button>
                                    </div>

                                    {(!activeGroup || !activeProducer) && (
                                        <>
                                            Необходимо выбрать номенклатурную
                                            группу и поставщика
                                        </>
                                    )}

                                    {activeGroup?.value &&
                                        activeProducer?.value && (
                                            <ModelsValuesBindingChoosers
                                                groupId={activeGroup.value}
                                                producerId={
                                                    activeProducer.value
                                                }
                                            />
                                        )}
                                </div>
                            </div>

                            <div className="control-view__info"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
