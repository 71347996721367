import { Col } from "antd"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import UNumberItem from "components/UNumberItem"
import { UTable } from "components/UTable"
import { useMemo } from "react"
import { useCostCollectorStore } from "../CostCollector.store"
import CostCollectorAttributeHistoryModal from "./CostCollectorAttributes.HistoryModal"
import classes from "./CostCollectorAttributes.module.sass"
import { useCostCollectorAttributeStore } from "./CostCollectorAttributes.store"
import { AttributeValuePriceItem } from "./CostCollectorAttributes.types"

export default function CostCollectorValuesTable() {
    const { selectedRows } = useCostCollectorStore()

    const {
        onPressAction,
        changePriceValue,
        setFocusedCell,
        focusedCell,
        historyPopup,
        attributeValuesTableData,
        selectedValues,
        activeAttributeId,
        toggleSelectedValue,
        toggleAllValues,
        deletePriceValue,
    } = useCostCollectorAttributeStore()

    const multipyMode = selectedRows.length > 1

    const currentAttributeValuesTableRows = useMemo(() => {
        return activeAttributeId
            ? (attributeValuesTableData[activeAttributeId]?.reduce(
                  (acc, next) => {
                      return acc.some((el) => el.id === next.id)
                          ? acc
                          : [...acc, next]
                  },
                  [] as AttributeValuePriceItem[]
              ) ?? [])
            : []
    }, [activeAttributeId, attributeValuesTableData])

    return (
        <Col
            md={{ span: 16 }}
            xs={{ span: 24 }}
            className={clsx({
                "--visible": !!currentAttributeValuesTableRows.length,
                "--hidden": !currentAttributeValuesTableRows.length,
                fw: true,
            })}
        >
            <Flex.Col fullWidth gap={20}>
                <UTable
                    data={currentAttributeValuesTableRows}
                    onRowClick={(row) =>
                        setFocusedCell({
                            id: row.id,
                            type: "newPrice",
                        })
                    }
                    columns={[
                        {
                            columnName: "ID",
                            render: (row) => row.id,
                            width: 5,
                        },
                        {
                            columnName: () => (
                                <Flex.Col justify="center">
                                    <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        onChange={() =>
                                            toggleAllValues(activeAttributeId!)
                                        }
                                        checked={
                                            !!currentAttributeValuesTableRows?.length &&
                                            currentAttributeValuesTableRows.every(
                                                (el) => {
                                                    const selected =
                                                        selectedValues[
                                                            activeAttributeId!
                                                        ]
                                                    return selected?.some(
                                                        (selectedItem) =>
                                                            selectedItem.id ===
                                                            el.id
                                                    )
                                                }
                                            )
                                        }
                                    />
                                </Flex.Col>
                            ),
                            render: (row) => (
                                <Flex.Col justify="center">
                                    <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        checked={selectedValues[
                                            activeAttributeId!
                                        ]?.some((el) => el.id === row.id)}
                                        onChange={() => {
                                            toggleSelectedValue(
                                                activeAttributeId!,
                                                row
                                            )
                                        }}
                                    />
                                </Flex.Col>
                            ),
                            width: 5,
                        },
                        {
                            columnName: "Значение",
                            render: (row) => row.name,
                            width: 20,
                        },
                        {
                            columnName: "Старая наценка",
                            isDisabled: multipyMode,
                            render: (row) => (
                                <span style={{ fontWeight: "bold" }}>
                                    {multipyMode ? "" : row.oldPrice || ""}
                                </span>
                            ),
                            width: 15,
                        },
                        {
                            columnName: "Новая наценка",
                            render: (row) => {
                                return (
                                    <UNumberItem
                                        noFormControl
                                        allowEmpty
                                        noBackground
                                        className={classes.input}
                                        disableInnerKeydownLogic
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === "Enter" ||
                                                e.key === "Tab"
                                            ) {
                                                e.preventDefault()
                                                return onPressAction.tab()
                                            }
                                            if (e.key === "ArrowLeft") {
                                                return onPressAction.left()
                                            }
                                            if (e.key === "ArrowRight") {
                                                return onPressAction.right()
                                            }
                                            if (e.key === "ArrowUp") {
                                                return onPressAction.up()
                                            }
                                            if (e.key === "ArrowDown") {
                                                return onPressAction.down()
                                            }
                                        }}
                                        value={row.newPrice}
                                        handleChange={(v) => {
                                            if (typeof v === "number") {
                                                changePriceValue(
                                                    row.id,
                                                    "newPrice",
                                                    v
                                                )
                                            } else {
                                                deletePriceValue(
                                                    row.id,
                                                    "newPrice"
                                                )
                                            }
                                        }}
                                        onBlur={(e) => {
                                            const value = e.target.value
                                            value
                                                ? changePriceValue(
                                                      row.id,
                                                      "newPrice",
                                                      +value
                                                  )
                                                : deletePriceValue(
                                                      row.id,
                                                      "newPrice"
                                                  )
                                        }}
                                        onClick={() =>
                                            setFocusedCell({
                                                id: row.id,
                                                type: "newPrice",
                                            })
                                        }
                                        focused={
                                            focusedCell?.id === row.id &&
                                            focusedCell.type === "newPrice"
                                        }
                                    />
                                )
                            },
                            width: 15,
                        },
                        {
                            columnName: "% наценки",
                            isDisabled: multipyMode,
                            render: (row) => (
                                <span style={{ fontWeight: "bold" }}>
                                    {multipyMode ? "" : row.marginValue || ""}
                                </span>
                            ),
                            width: 15,
                        },
                        {
                            columnName: "Новый % наценки",
                            render: (row) => (
                                <UNumberItem
                                    noFormControl
                                    allowEmpty
                                    noBackground
                                    className={classes.input}
                                    disableInnerKeydownLogic
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === "Enter" ||
                                            e.key === "Tab"
                                        ) {
                                            e.preventDefault()
                                            return onPressAction.tab()
                                        }
                                        if (e.key === "ArrowLeft") {
                                            return onPressAction.left()
                                        }
                                        if (e.key === "ArrowRight") {
                                            return onPressAction.right()
                                        }
                                        if (e.key === "ArrowUp") {
                                            return onPressAction.up()
                                        }
                                        if (e.key === "ArrowDown") {
                                            return onPressAction.down()
                                        }
                                    }}
                                    value={row.newMarginValue}
                                    handleChange={(v) => {
                                        if (typeof v === "number") {
                                            changePriceValue(
                                                row.id,
                                                "newMarginValue",
                                                v
                                            )
                                        } else {
                                            deletePriceValue(
                                                row.id,
                                                "newMarginValue"
                                            )
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const value = e.target.value
                                        value
                                            ? changePriceValue(
                                                  row.id,
                                                  "newMarginValue",
                                                  +value
                                              )
                                            : deletePriceValue(
                                                  row.id,
                                                  "newMarginValue"
                                              )
                                    }}
                                    onClick={() =>
                                        setFocusedCell({
                                            id: row.id,
                                            type: "newMarginValue",
                                        })
                                    }
                                    focused={
                                        focusedCell?.id === row.id &&
                                        focusedCell.type === "newMarginValue"
                                    }
                                />
                            ),
                            width: 15,
                        },
                        {
                            columnName: "История",
                            render: (row) => (
                                <button
                                    className="btn btn-outline fw"
                                    onClick={() => historyPopup.show(row)}
                                >
                                    +
                                </button>
                            ),
                            cellStyles: {
                                padding: 0,
                            },
                            width: 5,
                        },
                        {
                            columnName: "Сначала наценка",
                            render: (row) => (
                                <Flex.Col justify="center">
                                    <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        checked={
                                            row.newMarginFirst ??
                                            row.marginFirst
                                        }
                                        onChange={(e) => {
                                            changePriceValue(
                                                row.id,
                                                e.target.checked
                                                    ? "setMarginFirst"
                                                    : "removeMarginFisrt",
                                                e.target.checked
                                            )
                                        }}
                                    />
                                </Flex.Col>
                            ),
                            width: 5,
                        },
                    ]}
                />
                <CostCollectorAttributeHistoryModal />
            </Flex.Col>
        </Col>
    )
}
