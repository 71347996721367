import { Precision } from "./CostCollector.store"

export const applyPrecision = (
    value: number,
    initPrecision = 0 as Precision
) => {
    const ratio = Math.pow(10, initPrecision ?? 0)
    if (initPrecision >= 0) {
        return Math.round(value / ratio) * ratio
    } else {
        const _value = Math.round(value * (1 / ratio)) / (1 / ratio)
        return _value
    }
}
