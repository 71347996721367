import { Tooltip } from "antd"
import { AsyncSelect } from "components/UI"
import { useConfiguratorStore } from "./GeneralConfiguratorWrapper"

export default function GeneralConfiguratorCollection() {
    const { activeCollection, setActiveCollection, resetFilledAttributes } =
        useConfiguratorStore()

    return (
        <div className="row mb-3">
            <label className="col-xl-3 col-form-label">
                <Tooltip
                    placement="right"
                    overlayInnerStyle={{
                        width: 310,
                        textAlign: "center",
                    }}
                    title={activeCollection?.value ?? ""}
                >
                    Коллекция
                </Tooltip>
            </label>
            <div className="col-xl-9">
                <AsyncSelect
                    page={1}
                    pageSize={2}
                    onChange={(e) => {
                        setActiveCollection(e)
                        resetFilledAttributes()
                    }}
                    value={activeCollection}
                    dataUrl="compatible-collection"
                />
            </div>
        </div>
    )
}
