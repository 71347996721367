import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConfigProvider } from "antd"
import ru from "antd/locale/ru_RU"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import api from "helpers/api"
import GeneralConfiguratorWrapper from "pages/Configuration/GeneralConfigurator/GeneralConfiguratorWrapper"
import { useEffect, useLayoutEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Toaster } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useSearchParam } from "react-use"
import SecureRouter from "routes/SecureRouter"
import type { RootState } from "store/types"
import { clearUser, fetchUser } from "store/user/actions"
import Login from "./components/Login"
import { NotificationWrapper } from "./hooks/useNotifications"
import FatalError from "./partials/FatalError"
import Header from "./partials/Header"
import Loader from "./partials/Loader"
import Menu from "./partials/Menu"
import "./styles/global.sass"

dayjs.locale("ru")

const escFunction = (event: KeyboardEvent) => {
    if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.key === "escape" ||
        event.key === "esc"
    ) {
        const evt = new Event("Escape")
        window.dispatchEvent(evt)
    }
}

const hashCode = (s: string) => {
    for (var i = 0, h = 0; i < s.length; i++)
        h = Math.trunc(Math.imul(31, h) + (s.codePointAt(i) ?? 0))
    return h
}

const App = () => {
    const dispatch = useDispatch()
    const { pending, user, error } = useSelector(
        (state: RootState) => state.user
    )

    const reason = useSearchParam("reason")

    const history = useHistory()

    const region = useSelector((state: RootState) => state.region.region)

    useLayoutEffect(() => {
        api.setRegion(region?.value || null)
    }, [api, region])

    useEffect(() => {
        if (!!error) return
        if ((!user || !user?.credentials) && !pending) {
            dispatch(fetchUser())
        }
        document.addEventListener("keydown", escFunction, false)
        return () => {
            document.removeEventListener("keydown", escFunction, false)
        }
    }, [user, dispatch, pending, fetchUser, error])

    useEffect(() => {
        if (reason === "SESSION_EXPIRED") {
            dispatch(clearUser())
        }
    }, [reason])

    if (pending)
        return (
            <div style={{ width: "100%", paddingTop: 400 }}>
                <Loader />
            </div>
        )

    if (error)
        return (
            <Login
                onLogin={() => {
                    dispatch(fetchUser())
                }}
            />
        )

    const pathname = history.location.pathname

    if (/configurator-.*iframe$/.test(pathname)) {
        return (
            <GeneralConfiguratorWrapper isIframe={true} useCollections={true} />
        )
    }

    return (
        <ConfigProvider locale={ru}>
            <Toaster />
            <Menu credentials={user?.credentials ?? []} />

            <main className="main">
                <Header />
                <div className="content">
                    <NotificationWrapper>
                        <SecureRouter />
                    </NotificationWrapper>
                    <FatalError />
                </div>
            </main>
        </ConfigProvider>
    )
}

const AppWrapper = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })
    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary
                fallbackRender={({ error }) => {
                    return (
                        <div
                            style={{ paddingLeft: "5rem", paddingTop: "5rem" }}
                        >
                            <h2>Ой! Произошла ошибка интерфейса</h2>
                            <h4 style={{ opacity: 0.7 }}>
                                Об этой ошибке необходимо сообщить
                                разработчикам,
                                <br />а пока они ее не исправили - можно{" "}
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        window.location.reload()
                                    }}
                                    style={{ textDecoration: "none" }}
                                >
                                    перезагрузить
                                </button>{" "}
                                страницу.
                            </h4>
                            <pre style={{ color: "red", marginTop: 25 }}>
                                Path: {window.location.pathname}
                                <br />
                                Hash: {hashCode(error.message)}
                                <br />
                                <br />
                                {error.message}
                            </pre>
                        </div>
                    )
                }}
            >
                <App />
            </ErrorBoundary>
        </QueryClientProvider>
    )
}

export default AppWrapper
