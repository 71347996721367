import { Select } from "antd"
import { useEffect } from "react"
import { FieldSelectorProps } from "react-querybuilder"

// Для НГ Петля и Ручка сократить выбор в фильтре до параметра “Номеклатуры”
const onlyNomenclatureGroups = new Set(["Петля", "Ручка"])

export default function KitsItemsFieldSelector({
    value,
    handleOnChange,
    options,
    title,
    context,
}: FieldSelectorProps) {
    const builderOptions = options as Array<{ name: string; label: string }>

    const currentGroupName = context.item?.group?.label ?? ""

    const showOnlyNomenclature = onlyNomenclatureGroups.has(currentGroupName)

    const _options = builderOptions.map((el) => ({
        label: el.label,
        value: el.name,
    }))

    const filtredOptions = showOnlyNomenclature
        ? _options.filter((option) =>
              option.label.toLowerCase().match(/номенклатур/)
          )
        : _options

    useEffect(() => {
        if (filtredOptions.length === 1) {
            const optionValue = filtredOptions[0].value
            if (value !== optionValue) {
                handleOnChange(optionValue)
            }
        }
    }, [filtredOptions])

    return (
        <Select
            showSearch
            style={{ minWidth: 150 }}
            options={
                showOnlyNomenclature
                    ? _options.filter((option) =>
                          option.label.toLowerCase().match(/номенклатур/)
                      )
                    : _options
            }
            value={value}
            onChange={(e) => {
                handleOnChange(e)
            }}
            filterOption={(input, option) => {
                return (
                    option?.label
                        ?.toLocaleString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) || false
                )
            }}
        />
    )
}
