import { CaretRightOutlined } from "@ant-design/icons"
import { Collapse } from "antd"
import { CollapseProps } from "antd/lib"
import { FC, ReactNode, useState } from "react"
import { Flex } from "../Flex"
import styles from "./accordionContainer.module.scss"

interface IAccordion {
    items: {
        id: string
        label?: string
        extraOpen?: ReactNode
        children?: ReactNode
        extraClose?: ReactNode
    }[]
    onClick?: (val: string) => void
}

const AccordionContainer: FC<IAccordion> = ({ items, onClick = () => {} }) => {
    const [activeKey, setActiveKey] = useState<string | string[]>("")

    const getItems: () => CollapseProps["items"] = () =>
        items.map((item) => ({
            key: item.id,
            label: (
                <Flex.Row
                    gap={113}
                    justify="start"
                    className={styles.itemContent}
                >
                    <span>{item.label}</span>
                    {activeKey?.[0] !== item.id && item.extraClose && (
                        <Flex.Row
                            justify="start"
                            gap={20}
                            className={styles.extraClose}
                        >
                            {item.extraClose}
                        </Flex.Row>
                    )}
                    {activeKey?.[0] === item.id && item.extraOpen && (
                        <Flex.Row justify="start" gap={20}>
                            {item.extraOpen}
                        </Flex.Row>
                    )}
                </Flex.Row>
            ),
            children: item.children,
            className: styles.item,
        }))

    return (
        <Collapse
            accordion
            activeKey={activeKey}
            onChange={(values) => {
                onClick(values[0])
                setActiveKey(values)
            }}
            bordered={false}
            expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className={styles.accordions}
            items={getItems()}
        />
    )
}

export default AccordionContainer
