import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import withMessage from "hocs/withMessage"
import withQueryBuilder from "hocs/withQueryBuilder"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { RootState } from "store/types"
import { IdNameItem } from "types"

function NomenclatureDetailAnalogues() {
    const analogues = (useSelector(
        (state: RootState) => state.fields.currentValues.main?.analogues
    ) ?? []) as IdNameItem[]

    const analoguesFilters = (useSelector(
        (state: RootState) => state.fields.currentValues.main?.analoguesFilters
    ) ?? []) as IdNameItem[]

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">Аналоги</label>
                <div className="col-xl-9">
                    <Flex.Col
                        gap={10}
                        fullWidth
                        align="start"
                        className="overflow-hidden"
                    >
                        <Flex.Row fullWidth justify="start" gap={10}>
                            <UTable
                                data={analogues}
                                maxRowsVisible={5}
                                columns={[
                                    {
                                        columnName: "",
                                        render: (row) => (
                                            <a
                                                key={row.id}
                                                href={
                                                    "/configuration/nomenclature/update-" +
                                                    row.id
                                                }
                                            >
                                                {row.name}
                                            </a>
                                        ),
                                    },
                                ]}
                            />
                        </Flex.Row>
                    </Flex.Col>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    Фильтры аналогов
                </label>
                <div className="col-xl-9">
                    <Flex.Col
                        gap={10}
                        fullWidth
                        align="start"
                        className="overflow-hidden"
                    >
                        <Flex.Row fullWidth justify="start" gap={10}>
                            <UTable
                                data={analoguesFilters}
                                maxRowsVisible={5}
                                columns={[
                                    {
                                        columnName: "",
                                        render: (row) => (
                                            <Link
                                                key={row.id}
                                                to={
                                                    "/catalog/analog-nomenclature-filter/update-" +
                                                    row.id
                                                }
                                            >
                                                {row.name}
                                            </Link>
                                        ),
                                    },
                                ]}
                            />
                        </Flex.Row>
                    </Flex.Col>
                </div>
            </div>
        </>
    )
}

export default withQueryBuilder(withMessage(NomenclatureDetailAnalogues))
