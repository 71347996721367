import { getDateString } from "utils/date"

export const stringFormatter = {
    count: (one: string, couple: string, five: string) => (n: number) => {
        let string = one
        if ((n % 10 === 1 && Math.floor(n / 10) >= 2) || n === 1)
            string = couple
        else if (n <= 4) string = "позиции"
        else string = five
        return `${n} ${string}`
    },

    date: (value: string) => {
        return getDateString(value, "DD-MM-YYYY")
    },
    time: (value: string) => {
        return getDateString(value, "DD-MM-YYYY в HH:mm")
    },
}
