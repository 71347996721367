import { Button } from "antd"
import { useEffect, useState } from "react"
import { ButtonProps } from "./type"

export default function FormExampleNameButton({
    disabled,
    loading,
    defaultLabel,
    formName,
    className = "",
}: Omit<ButtonProps, "onClick"> & {
    defaultLabel?: string
    formName: () => string
    className?: string
}) {
    const [label, setLabel] = useState(defaultLabel)

    useEffect(() => {
        if (disabled) {
            setLabel("Сформировать пример названия")
        }
    }, [disabled])

    const changeLabel = () => {
        setLabel(formName())
    }

    return (
        <Button
            type="text"
            disabled={disabled}
            loading={loading}
            onClick={changeLabel}
            className={className}
        >
            <img src={"/img/svg/cycle.svg"} alt="" />
            {label ?? "Сформировать пример названия"}
        </Button>
    )
}
