/** DATA FOR INLINE SAVE */
const initialState = {}

export const inlineData = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "SET_INLINE_DATA": {
            return {
                ...state,
                ...action.payload,
            }
        }

        default: {
            return state
        }
    }
}
