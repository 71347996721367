import { useQuery } from "@tanstack/react-query"
import { Col, Row, Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import clsx from "clsx"
import { filterOption } from "components/inputs/helpers"
import { Text } from "components/UI"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useEffect } from "react"
import { SelectProps } from "."
import { getOnSelectData } from "./onSelect"

export type Rounding = -2 | -1 | 0 | 1 | 2

export const roundingOptions: DefaultOptionType[] = [
    {
        label: 0.01,
        value: 2,
    },
    {
        label: 0.1,
        value: 1,
    },
    {
        label: 0,
        value: 0,
    },
    {
        label: 10,
        value: -1,
    },
    {
        label: 100,
        value: -2,
    },
]

type RoundingSelectProps = SelectProps<{
    setActiveRounding: (v: Rounding | null) => void
    activeRounding: Rounding | null
    label?: string
}>

export function RoundingSelect({
    setActiveRounding,
    required,
    layout = "horizontal",
    activeRounding,
    labelSpan,
    inputSpan,
    formLabelFontSize,
    fullWidth,
    containerClassName,
    label = "Валюта",
    getValueProps,
    ...rest
}: RoundingSelectProps) {
    const { showNotification } = useNotifications()

    const _labelSpan = layout === "horizontal" ? labelSpan ?? 5 : 24
    const _inputSpan = layout === "horizontal" ? inputSpan ?? 19 : 24

    const { data, isFetching } = useQuery<Rounding | string>({
        queryKey: [getValueProps?.getParams, getValueProps?.url],
        enabled: !!getValueProps?.url,
        queryFn: () =>
            api.getTyped<Rounding | string>(
                getValueProps!.url,
                getValueProps?.getParams
            ),
    })

    useEffect(() => {
        if (typeof data === "number") {
            setActiveRounding(data)
        }
    }, [data, setActiveRounding])

    const onChange = (v: DefaultOptionType) => {
        const data = v ? getOnSelectData(v) : null
        setActiveRounding(
            data?.value === undefined ? null : (+data.value as Rounding)
        )

        api.put(
            getValueProps!.url,
            {},
            getValueProps?.getPutData(getOnSelectData(v))
        )
            .then(() => {
                showNotification({ type: "success", message: "Сохранено" })
            })
            .catch((error: any) => {
                showNotification({ type: "danger", message: error })
            })
    }

    return (
        <Row
            gutter={[0, 10]}
            className={clsx([
                {
                    fw: fullWidth,
                },
                containerClassName,
            ])}
        >
            <Col xs={24} md={_labelSpan}>
                <Text.FormLabel
                    formLabelFontSize={formLabelFontSize}
                    required={required}
                >
                    {label}
                </Text.FormLabel>
            </Col>
            <Col xs={24} md={_inputSpan}>
                <Select
                    loading={isFetching}
                    showSearch
                    filterOption={filterOption}
                    className="fw"
                    placeholder="Выберите валюту"
                    options={roundingOptions}
                    value={activeRounding}
                    onChange={(_, option) =>
                        onChange(option as DefaultOptionType)
                    }
                    allowClear
                    {...rest}
                />
            </Col>
        </Row>
    )
}
