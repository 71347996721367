import { Flex } from "components/UI/Flex"
import { useState } from "react"
import { Uuid } from "types"
import MasksTable from "./MasksTable"
import PropertiesTable from "./PropertiesTable"

type Type = "model" | "series"

interface ITableItems {
    type?: Type
    idModel?: Uuid
}

const TableItems = ({ type = "series", idModel }: ITableItems) => {
    const [activeAttributeId, setActiveAttributeId] = useState<Uuid>()

    return (
        <Flex.Row
            gap={15}
            align="start"
            fullWidth
            className="generation-settings-tables-items"
        >
            <PropertiesTable
                activeAttributeId={activeAttributeId}
                setActiveAttributeId={setActiveAttributeId}
                type={type}
                activeModelId={idModel}
            />
            <MasksTable
                activeAttributeId={activeAttributeId}
                type={type}
                activeModelId={idModel}
            />
        </Flex.Row>
    )
}

export default TableItems
