import { isNull } from "helpers/checkType"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { isEmptyObject } from "../../../helpers/isEmptyObject"
import { RESET_ERROR } from "../../../store/errors/actions"
import { SET_CURRENT_VALUES } from "../../../store/fields/actions"
import TextError from "../../UI/TextError"

interface RangeInputsProps {
    name: string
    onClickHandler?: any
}

const RangeInputs: React.FC<RangeInputsProps> = ({ name, onClickHandler }) => {
    const dispatch = useDispatch()

    const value: any = useSelector(
        (state: { [key: string]: any }) =>
            state.fields?.currentValues["filter"][name] || ""
    )
    const errors: any =
        useSelector((state: { [key: string]: any }) => state.errors.filters) ||
        ""
    const [from, setFrom] = React.useState("")
    const [to, setTo] = React.useState("")
    const currentValuesFilter = useSelector(
        (state: { [key: string]: any }) => state.fields?.currentValues.filter
    )

    React.useEffect(() => {
        if (Array.isArray(value)) {
            value.map((str: string) => {
                setFrom(isNull(str.split("_")[0]) ? "" : str.split("_")[0])
                setTo(isNull(str.split("_")[1]) ? "" : str.split("_")[1])
                return null
            })
        } else {
            setFrom(isNull(value.split("_")[0]) ? "" : value.split("_")[0])
            setTo(isNull(value.split("_")[1]) ? "" : value.split("_")[1])
        }
    }, [value])

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            event.target.blur()
            onClickHandler()
        }
    }

    const handleChange = (value: any, type: string) => {
        if (value >= 0) {
            if (type === "from") {
                setFrom(value)
            }
            if (type === "to") {
                setTo(value)
            }

            if (type === "from") {
                if (value.length === 0 && to.length === 0) {
                    const copy = currentValuesFilter
                    delete copy[name]
                    dispatch(SET_CURRENT_VALUES(copy, "filter"))
                }
                if (value.length === 0 && to.length > 0) {
                    const result = `null_${to}`
                    dispatch(SET_CURRENT_VALUES({ [name]: result }, "filter"))
                }
                if (value.length > 0 && (to === undefined || to.length < 1)) {
                    const result = `${value}_null`
                    dispatch(SET_CURRENT_VALUES({ [name]: result }, "filter"))
                }
                if (value.length > 0 && to.length > 0) {
                    const result = `${value}_null`
                    dispatch(SET_CURRENT_VALUES({ [name]: result }, "filter"))
                }
            }

            if (type === "to") {
                if (value.length === 0 && from.length === 0) {
                    const copy = currentValuesFilter
                    delete copy[name]
                    dispatch(SET_CURRENT_VALUES(copy, "filter"))
                }
                if (value.length === 0 && from.length > 0) {
                    const result = `${from}_null`
                    dispatch(SET_CURRENT_VALUES({ [name]: result }, "filter"))
                }
                if (
                    value.length > 0 &&
                    (from === undefined || from.length < 1)
                ) {
                    const result = `null_${value}`
                    dispatch(SET_CURRENT_VALUES({ [name]: result }, "filter"))
                }
                if (value.length > 0 && from.length > 0) {
                    const result = `${from}_${value}`
                    dispatch(SET_CURRENT_VALUES({ [name]: result }, "filter"))
                }
            }
        }
    }

    return (
        <div className="col-xl-8">
            <div className="d-flex align-items-center justify-content-between">
                <div className="w-100">
                    <input
                        className={`form-control ${
                            Object.keys(errors).includes(name)
                                ? "is-invalid"
                                : null
                        }`}
                        type="number"
                        value={from}
                        onChange={(event) =>
                            handleChange(event.target.value, "from")
                        }
                        onKeyUp={handleKeyPress}
                        onFocus={() =>
                            isEmptyObject(errors)
                                ? null
                                : dispatch(RESET_ERROR())
                        }
                    />
                </div>
                <div className="mx-3">
                    <span>-</span>
                </div>
                <div className="w-100">
                    <input
                        className={`form-control ${
                            Object.keys(errors).includes(name)
                                ? "is-invalid"
                                : null
                        }`}
                        type="number"
                        value={to}
                        onChange={(event) =>
                            handleChange(event.target.value, "to")
                        }
                        onKeyUp={handleKeyPress}
                        onFocus={() =>
                            isEmptyObject(errors)
                                ? null
                                : dispatch(RESET_ERROR())
                        }
                    />
                </div>
            </div>
            {Object.keys(errors).includes(name) ? (
                <TextError text={errors[name]} />
            ) : null}
        </div>
    )
}

export default RangeInputs
