import { TreeProps } from "antd"
import { AttributeLink } from "types"
import { AttributeCopyKeys } from "./settings"

export const getToEntities = (mode: AttributeCopyKeys) => {
    const entites = ["producer"] as any[]
    if (mode === AttributeCopyKeys.modelProducer) return entites
    entites.push("series")
    if (mode === AttributeCopyKeys.modelSeries) {
        return entites
    }
    entites.push("model")
    return entites
}

export const getToTarget = (mode: AttributeCopyKeys) => {
    const data = {
        name: "модели",
        which: "в которые",
    }
    if (mode === AttributeCopyKeys.modelProducer) {
        data.name = "поставщика"
        data.which = "в которого"
    }
    if (mode === AttributeCopyKeys.modelSeries) {
        data.name = "серию"
    }
    return data
}

export const getTargetKey = (mode: AttributeCopyKeys) => {
    switch (mode) {
        case AttributeCopyKeys.model: {
            return "model"
        }
        case AttributeCopyKeys.modelProducer: {
            return "producer"
        }
        default: {
            return "series"
        }
    }
}

export const getTreeAttributesData = (
    items: AttributeLink[]
): { treeData: TreeProps["treeData"]; allKeys: Array<string | number> } => {
    const allKeys = [] as Array<string | number>
    const treeData = items
        .filter((item) => item.attributeConfigurable)
        .map((el) => {
            allKeys.push(el.attributeId)
            return {
                title: el.attribute.name,
                key: el.attributeId,
                children: el.configurableAttributeValuesLink.map((link) => {
                    const key = Object.keys(link)[0]
                    allKeys.push(key)
                    return {
                        title: Object.values(link)[0],
                        key,
                    }
                }),
            }
        })

    return {
        treeData,
        allKeys,
    }
}
