import { selfCostValue } from "components/Selects/newSelects/PriceTypeFormSelect"
import { getUStringValue } from "components/UNumberItem"
import dayjs from "dayjs"
import { CreateRuleData, GetRuleData } from "./CalculationRules.types"

export const tranformInputCalculationRuleData = (
    data: GetRuleData
): CreateRuleData => {
    dayjs.tz.setDefault("Europe/Minsk")
    const transformedData: CreateRuleData = {
        inputCurrencyAmount: getUStringValue(data.inputCurrencyAmount),
        inputCurrencyRate: getUStringValue(data.inputCurrencyRate),
        outputCurrencyRate: getUStringValue(data.outputCurrencyRate),
        roundingStrategy: data.roundingStrategy,
        outputCurrencyAmount: getUStringValue(data.outputCurrencyAmount),
        outputCurrency: data.outputCurrency?.id,
        inputCurrency: data.inputCurrency?.id,
        endAt: dayjs(data.endAt),
        startAt: dayjs(data.startAt),
        nomenclatureFilters: data.priceNomenclatureFilters.map((el) => el.id),
        priceCalculationRulePriceMarginPresetLink:
            data.priceCalculationRulePriceMarginPresetLink.map((el) => ({
                id: el.priceMarginPreset.id,
                calculationType: el.calculationType,
                order: el.order,
            })),
        calculationType: data.calculationType?.id ?? selfCostValue,
        value: data.value,
        itemsGroup: data.itemsGroup?.id,
        producer: data.producer?.id,
        priceType: data.priceType?.id,
        numericCode: data.numericCode,
        name: data.name,
        decimals: data.decimals,
        currencyExport: data.currencyExport?.code,
        currencyInput: data.currencyInput?.code,
        calculationTypeRegion: data.calculationTypeRegion?.id ?? null,
        priceTypeRegion: data.priceTypeRegion?.id ?? null,
    }
    return transformedData
}
