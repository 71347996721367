import { PicturesGeneratorDemo } from "pages/Pictures"
import { PicturesMassUploader } from "pages/Pictures/MassUploader"
import { ReadyPictureConfigurator } from "pages/Pictures/ReadyPictureConfigurator"
import PicturesSettings from "pages/Pictures/Settings"
import { CreateRouteData } from "./tools"

export const graphicsRoutes: CreateRouteData[] = [
    {
        path: "layer-by-layer",
        title: "По слоям",
        render: () => <PicturesGeneratorDemo />,
        permission: "pictures-generation",
    },
    {
        path: "ready-pictures-configurator",
        title: "Готовые модели",
        render: () => <ReadyPictureConfigurator />,
        permission: "pictures/ready-pictures-configurator",
    },
    {
        path: "picture-mass-uploader",
        title: "Массовая загрузка изображения",
        render: () => <PicturesMassUploader />,
        permission: "pictures/picture-mass-uploader",
    },
    {
        path: "settings",
        title: "Настройки",
        render: () => <PicturesSettings />,
        permission: "pictures/settings",
    },
]
