import CopyButton from "components/UI/buttons/CopyButton"
import FormExampleNameButton from "components/UI/buttons/FormExampleNameButton"
import { Flex } from "components/UI/Flex"
import styles from "./actionButtons.module.scss"

type Attribute = "accounting" | "userAccount"

interface IActionsButtons {
    type?: Attribute
    copy: () => void
    formName: () => string
    disabledCopy?: boolean
    disabledFormNaming?: boolean
    hiddenCopyButton?: boolean
}

export default function ActionsButtons({
    type = "accounting",
    copy,
    formName,
    disabledCopy = false,
    disabledFormNaming = false,
    hiddenCopyButton = false,
}: IActionsButtons) {
    return (
        <Flex.Row
            gap={16}
            margin={"18px 0 0 0"}
            align="start"
            justify="start"
            fullWidth
        >
            <FormExampleNameButton
                disabled={disabledFormNaming}
                formName={formName}
                className={styles.formButton}
            />
            {!hiddenCopyButton && (
                <CopyButton
                    type={type}
                    onClick={copy}
                    disabled={disabledCopy}
                    className={styles.copyButton}
                />
            )}
        </Flex.Row>
    )
}
