import { Tag, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import { GetReferenceNomenclatureResponse } from "types/api"

export default function NomenclatureDetailCollections({
    collections,
}: Pick<GetReferenceNomenclatureResponse, "collections">) {
    return (
        <div className="row mb-3">
            <label className="col-xl-3 col-form-label">Коллекции</label>
            <div className="col-xl-9">
                <Flex.Row className="fh" gap={2} justify="start">
                    {collections.length ? (
                        collections.map((el) => (
                            <Tag
                                className="p-2"
                                key={el.id}
                                title={el.name}
                                content={el.name}
                            >
                                {el.name}
                            </Tag>
                        ))
                    ) : (
                        <Typography.Text style={{ margin: 0 }} type="secondary">
                            Нет
                        </Typography.Text>
                    )}
                </Flex.Row>
            </div>
        </div>
    )
}
