/** ATTRIBUTES */
const initialState = {}

export const attributes = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "GET_ATTRIBUTES": {
            return [...action.payload]
        }

        case "DEL_ATTRIBUTE": {
            const id = action.payload
            const result = state.filter((item: any) => item.id !== id)
            return [...result]
        }

        default: {
            return state
        }
    }
}
