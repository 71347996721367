import dayjs from "dayjs"

const defaults = {
    dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
}

export const getDateString = (dateString?: string, format?: string) => {
    if (typeof dateString !== "string") return ""
    const isUtc = dateString.endsWith("+00:00")
    const base = dayjs(dateString)
    const withTimeZone = isUtc ? base.utc() : base.tz("Europe/Minsk")
    return withTimeZone.format(format ?? defaults.dateTimeFormat)
}
