import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

const defaultSearch = "?count_on_page=20&method=asc&page=1&sort=name"

export const useListingSearch = () => {
    const location = useLocation()
    const history = useHistory()
    const search = location.search

    useEffect(() => {
        if (!location.search) {
            history.replace(location.pathname + defaultSearch)
        }
    }, [location.search, location.pathname])

    return {
        isValidSearch: !!search,
    }
}
