import { useDispatch, useSelector } from "react-redux"
import { SET_ERRORS } from "../store/errors/actions"
import { RESET_ERROR } from "./../store/errors/actions"
import { useNotifications } from "./useNotifications"

export const useValidateHelper = () => {
    const { showNotification } = useNotifications()

    const dispatch = useDispatch()
    const compareType = useSelector(
        (state: { [key: string]: any }) => state.compareType
    )
    const currentValuesFilter = useSelector(
        (state: { [key: string]: any }) => state.fields?.currentValues.filter
    )
    const currentValuesAttributeFilter = useSelector(
        (state: { [key: string]: any }) =>
            state.fields?.currentValues.attributeFilter
    )

    return (filterFunc: any) => {
        let errors: string[] = []
        let keys = Object.keys(compareType).filter(
            (key) => compareType[key] === "range"
        )

        if (keys.length > 0) {
            keys.forEach((el: string) => {
                if (
                    currentValuesFilter &&
                    Object.keys(currentValuesFilter).includes(el)
                ) {
                    const from = currentValuesFilter[el].split("_")[0]
                    const to = currentValuesFilter[el].split("_")[1]
                    if (Number(from) > Number(to)) {
                        errors.push(el)
                    }
                }
                if (
                    currentValuesAttributeFilter &&
                    Object.keys(currentValuesAttributeFilter).includes(el)
                ) {
                    const from = currentValuesAttributeFilter[el].split("_")[0]
                    const to = currentValuesAttributeFilter[el].split("_")[1]
                    if (Number(from) > Number(to)) {
                        errors.push(el)
                    }
                }
            })

            if (errors.length > 0) {
                let errorsObj = errors.reduce((acc: any, inputObj: any) => {
                    acc[inputObj] = "Диапазон введён неправильно"
                    return acc
                }, {})
                dispatch(SET_ERRORS({ filters: errorsObj }))
                showNotification({
                    type: "danger",
                    message:
                        "Ошибка поиска по фильтру. Проверьте основные поля",
                })
            } else {
                dispatch(RESET_ERROR())
                filterFunc()
            }
        } else {
            dispatch(RESET_ERROR())
            filterFunc()
        }
    }
}
