import InputMeasureSelectMassUpdate from "components/inputs/InputMeasureSelectMassUpdate"
import { Flex } from "components/UI/Flex"
import { preloadListingToPaginatedSelect } from "helpers/preloadListingToAsyncSelect"
import { usePageData } from "hooks/usePageData"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { AsyncPaginate } from "react-select-async-paginate"
import { Uuid } from "types"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const InputMeasureSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    )

    const params = useParams() as { id: Uuid | undefined }

    const { slug } = usePageData()
    const dispatch = useDispatch()

    const optionHandler = (value: { label: string; value: Uuid }) => {
        dispatch(
            SET_CURRENT_VALUES({ [propertyContainer.getName()]: value }, group)
        )
    }

    const disabled = slug === "nomenclature"

    const defaultValue = propertyContainer.get("default_value")

    const _value = value
        ? {
              value: value.id ?? value.value,
              label: value.name ?? value.label,
          }
        : defaultValue
        ? {
              value: defaultValue.id,
              label: defaultValue.name,
          }
        : undefined

    const defaultOption = defaultValue
        ? {
              value: defaultValue?.id,
              label: defaultValue?.name,
          }
        : undefined

    useEffect(() => {
        if (!value && !!defaultOption) {
            optionHandler(defaultOption)
        }
    }, [value, defaultOption, optionHandler])

    const dataUrl = "measure-unit"

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <Flex.Row fullWidth fullHeight gap={10}>
                        {!disabled && (
                            <AsyncPaginate
                                placeholder={"Единица измерения"}
                                loadOptions={preloadListingToPaginatedSelect(
                                    dataUrl,
                                    undefined,
                                    { noSort: true }
                                )}
                                noOptionsMessage={() => <>{"Не найдено"}</>}
                                loadingMessage={() => <>{"Загрузка..."}</>}
                                defaultOptions={[defaultOption]}
                                defaultValue={value || defaultValue}
                                debounceTimeout={250}
                                onChange={optionHandler}
                                isMulti={false}
                                className={"fw"}
                                value={_value}
                                isClearable={undefined}
                            />
                        )}
                        {disabled && (
                            <input
                                className="form-control"
                                value={_value?.label}
                                disabled
                            />
                        )}
                        {textError && <TextError text={textError} />}

                        {!disabled && (
                            <InputMeasureSelectMassUpdate
                                measureUnitId={_value?.value}
                                modelId={params?.id}
                            />
                        )}
                    </Flex.Row>
                </div>
            </div>
        </>
    )
}

export default InputMeasureSelect
