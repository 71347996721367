import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import Select, { ActionMeta, MultiValue } from "react-select"

type Value = {
    value: string
    label: string
    sort: number
}

interface IApiMuslitSelect {
    loading?: boolean
    disabled?: boolean
    defaultValue: Value[]
    options: Value[]
    urls: {
        remove: (prop: ActionMeta<Value>) => string
        add: (prop: ActionMeta<Value>) => string
        addMethod?: "post" | "put"
        remodeMethod?: "post" | "delete"
        getRemoveParams?: (
            list: MultiValue<Value>,
            prop: ActionMeta<Value>
        ) => object
        getAddParams?: (
            list: MultiValue<Value>,
            prop: ActionMeta<Value>
        ) => object
    }
}

export const ApiMultiSelect = ({
    loading,
    disabled,
    defaultValue,
    options,
    urls,
}: IApiMuslitSelect) => {
    const { showNotification } = useNotifications()

    const apiMethod = urls.addMethod ?? "post"
    const removeMethod = urls.remodeMethod ?? "delete"

    return (
        <Select
            isMulti
            isLoading={loading}
            isDisabled={disabled}
            isClearable={false}
            placeholder=""
            key={JSON.stringify(defaultValue)}
            defaultValue={defaultValue}
            onChange={(list, prop) => {
                if (prop.action === "remove-value") {
                    if (urls.getRemoveParams) {
                        const data = urls.getRemoveParams(list, prop)
                        return api[removeMethod](urls.remove(prop), {}, data)
                    }
                    prop.removedValue?.value &&
                        api[removeMethod](urls.remove(prop), {}).catch(
                            (error) =>
                                showNotification({
                                    type: "danger",
                                    message: error.message,
                                })
                        )
                    return
                }
                const params = urls.getAddParams
                    ? urls.getAddParams(list, prop)
                    : {}

                const addUrl = urls.add(prop)
                prop.option?.value &&
                    api[apiMethod](
                        addUrl,
                        urls.addMethod === "post" ? {} : params,
                        urls.addMethod === "post" ? params : {}
                    ).catch((error) =>
                        showNotification({
                            type: "danger",
                            message: error.message,
                        })
                    )
            }}
            options={options}
        />
    )
}
