import { Button } from "antd"
import { ReactElement, useCallback, useMemo, useState } from "react"

export type WithShowMore<T> = {
    data: T[]
}

type Settings = {
    showMoreButton?: string
    chunkSize?: number
}

export default function withShowMore<T extends { data?: any[] }>(
    Component: React.ComponentType<T & WithShowMore<any>>,
    showMoreProps?: Settings
) {
    return (props: T): ReactElement => {
        const { showMoreButton = "Показать ещё", chunkSize = 20 } =
            showMoreProps ?? {}

        const [slicedData, setSlicedData] = useState<any[]>(
            props.data ? props.data.slice(0, chunkSize) : []
        )

        const showMore = useCallback(() => {
            setSlicedData((prev) => {
                const data = props.data ?? []
                const dataLength = prev.length + chunkSize
                return data.slice(0, dataLength)
            })
        }, [setSlicedData, chunkSize, props.data])

        const showButton = useMemo(() => {
            const dataLength = props.data?.length ?? 0
            const slicedDataLength = slicedData.length
            return slicedDataLength < dataLength
        }, [props.data, slicedData, chunkSize])

        return (
            <>
                <Component {...props} data={slicedData} />
                {showButton && (
                    <Button onClick={showMore}>{showMoreButton}</Button>
                )}
            </>
        )
    }
}
