import { Button, Card, DatePicker, Form, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import { DefaultOptionType } from "antd/es/select"
import { FormProps } from "antd/lib"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import UNumberItem, { getUNumberValue } from "components/UNumberItem"
import { Dayjs } from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { PriceFormField } from "../CostCollector"
import classes from "../CostCollector.module.sass"
import CostCollectorAttributesSaveModal from "./CostCollectorAttributes.SaveModal"
import {
    MassChangeData,
    useCostCollectorAttributeStore,
} from "./CostCollectorAttributes.store"
import { AttributeValuePriceItem } from "./CostCollectorAttributes.types"

export type ChangeValuesAction =
    | keyof Pick<AttributeValuePriceItem, "newPrice" | "newMarginValue">
    | "changePrice"
    | "changePriceOnPercent"
    | "changeMargin"
    | "setMarginFirst"
    | "removeMarginFisrt"

const getLabel = (action?: ChangeValuesAction) => {
    switch (action) {
        case "newPrice": {
            return "Установить наценку"
        }
        case "changePrice": {
            return "Изменить наценку на сумму"
        }
        case "changePriceOnPercent": {
            return "Изменить наценку на %"
        }
        case "newMarginValue": {
            return "Установить процент наценки"
        }
        case "changeMargin": {
            return "Изменить процент наценки"
        }
        case "setMarginFirst": {
            return "Установить сначала наценку"
        }
        case "removeMarginFisrt": {
            return "Убрать флаг сначала наценку"
        }
        default: {
            return "Значение"
        }
    }
}
const getOption = (action: ChangeValuesAction): DefaultOptionType => {
    return {
        value: action,
        label: getLabel(action),
    }
}

const roundingAction = new Set<ChangeValuesAction>([
    "newPrice",
    "newMarginValue",
    "changeMargin",
    "changePrice",
    "changePriceOnPercent",
])

const booleanAction = new Set<ChangeValuesAction>([
    "setMarginFirst",
    "removeMarginFisrt",
])

export const CostCollectAttributesAction = ({
    refetch,
}: {
    refetch: (clearCachFor: object[]) => void
}) => {
    const { selectedValues, attributeValuesTableData, massChangePrice } =
        useCostCollectorAttributeStore()

    const [priceForm] = useForm<MassChangeData>()

    const [selectedOptionType, setSelectedOptionType] =
        useState<ChangeValuesAction>()
    const [showSendModal, setShowSendModal] = useState(false)
    const [currentDate, setCurrentDate] = useState<Dayjs | null>(null)

    const onFinish: FormProps["onFinish"] = (values: MassChangeData) => {
        massChangePrice({
            ...values,
            operationType: selectedOptionType!,
            value: getUNumberValue(values.value),
        })
    }

    const disableSubmit = useMemo(() => {
        const selectedCells = Object.values(selectedValues).reduce(
            (acc, next) => acc + (next ?? []).length,
            0
        )
        return !selectedCells
    }, [selectedValues])

    const disableModal = useMemo(() => {
        return Object.values(attributeValuesTableData).every((el) =>
            el.every(
                (el) =>
                    el.newMarginFirst === el.marginFirst &&
                    el.newPrice === el.oldPrice &&
                    el.newMarginValue === el.marginValue
            )
        )
    }, [attributeValuesTableData])

    useEffect(() => {
        priceForm.resetFields()
    }, [selectedOptionType])

    return (
        <Flex.Col fullWidth className="fw">
            <Card className="fw">
                <Form
                    form={priceForm}
                    onFinish={onFinish}
                    labelCol={{
                        xs: {
                            span: 24,
                        },
                        md: {
                            span: 5,
                        },
                    }}
                    labelAlign="left"
                    wrapperCol={{
                        xs: {
                            span: 24,
                        },
                        md: {
                            span: 19,
                        },
                    }}
                    className="fw"
                >
                    <Form.Item label="Дата установки цен">
                        <DatePicker
                            className={classes.priceForm_item}
                            value={currentDate}
                            onChange={setCurrentDate}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label="Тип операции">
                        <Select
                            className={classes.priceForm_item}
                            style={{ maxWidth: 400 }}
                            value={selectedOptionType}
                            allowClear
                            options={(
                                [
                                    "newPrice",
                                    "changePrice",
                                    "changePriceOnPercent",
                                    "newMarginValue",
                                    "changeMargin",
                                    "setMarginFirst",
                                    "removeMarginFisrt",
                                ] as ChangeValuesAction[]
                            ).map(getOption)}
                            onChange={(v) => {
                                setSelectedOptionType(v)
                            }}
                        />
                    </Form.Item>

                    <UNumberItem
                        bordered
                        useInForm={{
                            label: getLabel(selectedOptionType),
                            name: PriceFormField.value,
                            className: clsx({
                                "--visible":
                                    !!selectedOptionType &&
                                    !booleanAction.has(selectedOptionType),
                                "--hidden":
                                    !selectedOptionType ||
                                    booleanAction.has(selectedOptionType),
                            }),
                            wrapperCol: { span: 8 },
                        }}
                        floatRank={2}
                        max={
                            selectedOptionType === "newMarginValue"
                                ? 100
                                : undefined
                        }
                        className={clsx(
                            classes.priceForm_item,
                            "form-control",
                            "form-control-sm"
                        )}
                    />

                    <Form.Item
                        label="Задать округление"
                        name={PriceFormField.rounding}
                        className={clsx({
                            "--visible":
                                !!selectedOptionType &&
                                roundingAction.has(selectedOptionType),
                            "--hidden":
                                !selectedOptionType ||
                                !roundingAction.has(selectedOptionType),
                        })}
                    >
                        <Select
                            className={classes.priceForm_item}
                            style={{ width: "auto" }}
                            options={[
                                {
                                    label: "0.01",
                                    value: -2,
                                },
                                {
                                    label: "0.1",
                                    value: -1,
                                },
                                {
                                    label: 0,
                                    value: 0,
                                },
                                {
                                    label: 10,
                                    value: 1,
                                },
                                {
                                    label: 100,
                                    value: 2,
                                },
                            ]}
                        />
                    </Form.Item>
                    <FormLine>
                        <Flex.Row justify="start" gap={20}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={disableSubmit}
                            >
                                Применить настройку на выбранные аттрибуты
                            </Button>
                            <Button
                                type="primary"
                                className={classes.formButton}
                                disabled={disableModal}
                                onClick={() => setShowSendModal(true)}
                            >
                                Предварительный просмотр цен
                            </Button>
                        </Flex.Row>
                    </FormLine>
                </Form>
            </Card>
            <CostCollectorAttributesSaveModal
                refetch={refetch}
                visible={showSendModal}
                date={currentDate}
                hide={() => setShowSendModal(false)}
            />
        </Flex.Col>
    )
}
