import { useQuery } from "@tanstack/react-query"
import {
    Button,
    Checkbox,
    Col,
    List,
    Modal,
    Popconfirm,
    Row,
    Switch,
    Typography,
} from "antd"
import EntitiesSelect, {
    EntitySelectEmptyValues,
} from "components/Selects/EnititesSelect"
import { SelectEntity } from "components/Selects/types"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Uuid } from "types"
import { AttributeGroupProducerSeriesLink } from "."
import AttributeCopyResult, {
    CopyCompatibilitesResponse,
} from "./AttributeCopyResult"
import { getTargetKey, getToEntities, getToTarget } from "./helpers"
import { AttributeCopyKeys } from "./settings"

export default function AttributeCopy({
    mode,
    entityData,
    hide,
}: {
    mode: AttributeCopyKeys
    entityData: EntitySelectEmptyValues
    hide: () => void
}) {
    const [to, setTo] = useState<SelectEntity | null>(null)
    const [toModels, setToModels] = useState<SelectEntity[] | null>(null)
    const [showChoise, setShowChoise] = useState(false)
    const [fromValues, setFromValues] = useState<EntitySelectEmptyValues>({
        group: null,
        model: null,
        series: null,
        producer: null,
    })
    const [currentValues, setCurrentValues] = useState<EntitySelectEmptyValues>(
        { ...entityData }
    )
    const [selectedAttributes, setSelectedAttributes] = useState<Uuid[]>([])
    const [loading, setLoading] = useState(false)
    const [copyResult, setCopyResult] =
        useState<CopyCompatibilitesResponse | null>(null)

    const { showNotification } = useNotifications()

    const toEntities = useMemo(() => getToEntities(mode), [mode])
    const target = useMemo(() => getToTarget(mode), [mode])
    const targetKey = useMemo(() => getTargetKey(mode), [mode])

    const resetCopyProps = useCallback(() => {
        setTo(null)
        setToModels(null)
    }, [])

    useEffect(() => {
        setFromValues(entityData)
        setTo(null)
        setToModels(null)
    }, [entityData])

    const {
        data: attributes,
        isLoading: getAttributesLoading,
        isFetching: getAttributesFetching,
    } = useQuery({
        queryKey: [
            currentValues.group,
            currentValues.producer,
            currentValues.series,
            showChoise,
        ],
        enabled:
            !!currentValues.group &&
            !!currentValues.producer &&
            !!currentValues.series &&
            showChoise,
        queryFn: () =>
            api.getTyped<AttributeGroupProducerSeriesLink[]>(
                `attribute/list-by-group-and-producer-and-series?producer=${currentValues.producer?.value}&group=${currentValues.group?.value}&series=${currentValues.series?.value}`
            ),
        initialData: [] as AttributeGroupProducerSeriesLink[],
    })

    const copyProps = () => {
        setLoading(true)
        const modelId = fromValues.model?.value
        const group = `/itemsGroup/${currentValues?.group?.value}`
        let apiPath = `v1/model/${modelId}/compatibilities/copy`
        let attributes: Uuid[] | undefined
        switch (mode) {
            case AttributeCopyKeys.modelProducer: {
                apiPath += `${group}/producer/${to?.value}`
                break
            }
            case AttributeCopyKeys.modelSeries: {
                apiPath += `${group}/series/${to?.value}`
                if (showChoise) {
                    attributes = selectedAttributes
                }
                break
            }
        }

        const data =
            mode === AttributeCopyKeys.model
                ? { models: toModels ? toModels!.map((el) => el.value) : [to] }
                : { attributes }
        api.post(apiPath, {}, data)
            .then((response) => {
                const data = response.data as CopyCompatibilitesResponse
                hide()
                resetCopyProps()
                setCopyResult(data)
                setSelectedAttributes([])
                setShowChoise(false)
            })
            .catch((error: any) => {
                showNotification({
                    type: "danger",
                    message: error.message,
                })
            })
            .finally(() => setLoading(false))
    }

    const popConfirmTitle = useMemo(() => {
        return "Копировать свойства?"
    }, [to, toModels, mode])

    const disableCopyButton = useMemo(() => {
        return !to && (!toModels || !toModels?.length)
    }, [to, toModels])

    useEffect(() => {
        if (mode === AttributeCopyKeys.modelSeries) {
            setTo(fromValues.series)
            setToModels(null)
        }
        if (mode === AttributeCopyKeys.modelProducer) {
            setTo(fromValues.producer)
            setToModels(null)
        }
        if (mode === AttributeCopyKeys.model) {
            setTo(null)
        }
        setCurrentValues(fromValues)
        setToModels(null)
    }, [mode, fromValues, currentValues])

    useEffect(() => {
        setSelectedAttributes([])
    }, [to])

    const targetName = useMemo(() => {
        if (mode === "model") {
            return toModels?.map((el) => el.label).join(", ")
        }

        return to?.label
    }, [mode, to, toModels])

    return (
        <>
            <Row className="fw" gutter={[20, 20]} justify={"center"}>
                <Col span={24}>
                    <EntitiesSelect
                        entities={toEntities}
                        targetKey={targetKey}
                        onEmptyValues={fromValues}
                        onSelectGroup={(v) => {
                            setFromValues((prev) => ({
                                ...prev,
                                group: v ?? null,
                            }))
                            setCurrentValues((prev) => ({
                                ...prev,
                                group: v ?? null,
                                producer: null,
                                series: null,
                                model: null,
                            }))
                            setToModels(null)
                        }}
                        onSelectProducer={(v) => {
                            setCurrentValues((prev) => ({
                                ...prev,
                                producer: v ?? null,
                                series: null,
                                model: null,
                            }))
                            setToModels(null)
                            if (mode === AttributeCopyKeys.modelProducer) {
                                setTo(v)
                            }
                        }}
                        onSelectSeries={(v) => {
                            setFromValues((prev) => ({
                                ...prev,
                                series: v,
                            }))
                            setCurrentValues((prev) => ({
                                ...prev,
                                series: v ?? null,
                                model: null,
                            }))
                            setToModels(null)
                            if (mode === AttributeCopyKeys.modelSeries) {
                                setTo(v)
                            }
                        }}
                        onSelectModels={(v) => {
                            if (mode === AttributeCopyKeys.model) {
                                setToModels(v)
                            }
                        }}
                        layout="vertical"
                        outSideEntity={toModels?.[0] || to || undefined}
                    />
                    {(to || !!toModels?.length) && (
                        <Typography.Paragraph className="m-top-md m-left-md">
                            Копировать сочетания свойств в {target.name}:{" "}
                            <Typography.Text className="text-blue">
                                {targetName}
                            </Typography.Text>
                        </Typography.Paragraph>
                    )}
                    {!to && !toModels?.length && (
                        <Typography.Paragraph className="m-top-md m-left-md">
                            Выберите {target.name} {target.which} хотите
                            скопировать сочетания свойств
                        </Typography.Paragraph>
                    )}
                </Col>
                <Col>
                    <Popconfirm
                        disabled={disableCopyButton}
                        title={"Копировать сочетания свойств"}
                        description={popConfirmTitle}
                        onConfirm={copyProps}
                        okText="Скопировать"
                        cancelText="Отмена"
                    >
                        <Button
                            type="primary"
                            disabled={disableCopyButton}
                            loading={loading}
                        >
                            Копировать сочетания свойств
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            {mode === AttributeCopyKeys.modelSeries && currentValues.series && (
                <Switch
                    className="m-top-md"
                    checkedChildren="Выбрать свойства"
                    unCheckedChildren="Все свойства"
                    checked={showChoise}
                    onChange={(e) => setShowChoise(e)}
                />
            )}
            {mode === AttributeCopyKeys.modelSeries &&
                showChoise &&
                attributes &&
                currentValues.series && (
                    <List
                        dataSource={attributes}
                        loading={getAttributesLoading || getAttributesFetching}
                        renderItem={(item) => (
                            <List.Item key={item.attributeId}>
                                <Row gutter={[10, 10]}>
                                    <Col>
                                        <Checkbox
                                            checked={selectedAttributes.includes(
                                                item.attributeId
                                            )}
                                            onChange={(e) => {
                                                setSelectedAttributes(
                                                    e.target.checked
                                                        ? (prev) => [
                                                              ...prev,
                                                              item.attributeId,
                                                          ]
                                                        : (prev) =>
                                                              prev.filter(
                                                                  (el) =>
                                                                      el !==
                                                                      item.attributeId
                                                              )
                                                )
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Typography.Text>
                                            {item.attribute.name}
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                )}
            <Modal
                rootClassName="attribute-copy-modal"
                wrapClassName="attribute-copy-modal-content"
                className="attribute-conent"
                width={"100vw"}
                open={!!copyResult}
                onCancel={() => setCopyResult(null)}
                cancelButtonProps={{ hidden: true }}
                onOk={() => setCopyResult(null)}
            >
                {copyResult && <AttributeCopyResult data={copyResult} />}
            </Modal>
        </>
    )
}
