import { Listing } from "components/Listing"
import { Detail } from "pages/Detail"
import { CreateRouteData } from "./tools"

export const classificationRoutes: CreateRouteData[] = [
    {
        path: "classification-accordance",
        title: "Таблица соответствий",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "tnved",
        title: "ТНВЭД",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
    {
        path: "okrb",
        title: "ОКРБ",
        render: () => <Listing />,
        createAndUpdate: () => <Detail />,
    },
]
