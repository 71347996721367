import AccordionContainer from "components/UI/AccordionContainer"
import { useMemo } from "react"
import TableItems from "../components/TableItems"
import { useNameGenerationSettingsStore } from "../NameGenerationSettings.store"

const SettingValuesAttributesModel = () => {
    const { allSeries, activeSeriesId } = useNameGenerationSettingsStore()
    const allModels =
        allSeries.find((item) => item.id === activeSeriesId)?.models ?? []

    const dataForAccordion = useMemo(
        () =>
            allModels.map((item) => ({
                ...item,
                children: <TableItems type="model" idModel={item.id} />,
            })),
        [allModels]
    )

    return (
        <>
            <h5>Настройки названий для значений свойств модели</h5>
            <div className="control-in" style={{ width: "100%" }}>
                <AccordionContainer items={dataForAccordion} />
            </div>
        </>
    )
}

export default SettingValuesAttributesModel
