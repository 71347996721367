/** FIELDS */
const initialState = {
    initFields: [],
    currentValues: {},
}

export const fields = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "INIT_STATE_GROUP": {
            return {
                ...state,
                currentValues: { ...action.payload },
            }
        }

        case "SET_FIELDS": {
            return {
                ...state,
                initFields: [...action.payload],
            }
        }

        case "SET_CURRENT_VALUES": {
            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    [action.group]: {
                        ...state.currentValues[action.group],
                        ...action.payload,
                    },
                },
            }
        }

        case "RESET_CURRENT_VALUES": {
            return {
                ...state,
                currentValues: {},
            }
        }

        case "RESET_GOODS_ATTRIBUTES": {
            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    attributeValues: {},
                },
            }
        }

        case "DEL_ATTRIBUTE_FROM_CURRENT": {
            const id = action.payload
            const result = state.currentValues["main"].attributes.filter(
                (item: any) => item.id !== id
            )

            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    main: { ...state.currentValues.main, attributes: result },
                },
            }
        }

        case "DEL_KEYVALUE_FROM_CURRENT": {
            const id = action.payload.id
            const fieldName = action.payload.fieldName

            const result = state.currentValues.main[fieldName].filter(
                (item: any) => item.key !== id
            )
            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    main: { ...state.currentValues.main, [fieldName]: result },
                },
            }
        }

        case "DELETE_FIELDS_VALUES": {
            const { fields, group } = action.payload as {
                fields: string[]
                group: string
            }
            const values = state.currentValues[group] ?? []
            fields.forEach((key) => {
                if (values[key]) {
                    delete values[key]
                }
            })
            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    [group]: values,
                },
            }
        }

        case "DEL_FILE_FROM_CURRENT": {
            const id = action.payload.id
            const fieldName = action.payload.fieldName

            const result = state.currentValues.attributeValues[
                fieldName
            ].filter((item: any) => item.id !== id)
            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    attributeValues: {
                        ...state.currentValues.attributeValues,
                        [fieldName]: result,
                    },
                },
            }
        }

        case "DELETE_FILTER_VALUE": {
            const { key } = action.payload

            const filter = state.currentValues?.filter
                ? { ...state.currentValues.filter }
                : {}
            delete filter[key]

            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    filter: filter,
                },
            }
        }

        case "DELETE_FILTER_VALUES": {
            const { keys } = action.payload

            const filter = state.currentValues?.filter
                ? { ...state.currentValues.filter }
                : {}

            keys.forEach((key: string) => {
                delete filter[key]
            })

            return {
                ...state,
                currentValues: {
                    ...state.currentValues,
                    filter: filter,
                },
            }
        }

        default: {
            return state
        }
    }
}
