import { useLocation } from "react-router-dom"

export const useReturn = () => {
    const location = useLocation()

    const pathname = location.pathname

    const returnLink = pathname.replace(/(\/create\/?$)|(\/update-.+$)/, "")

    return {
        returnLink,
    }
}
