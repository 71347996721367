import { Uuid } from "types"
import { create } from "zustand"
import { Precision } from "../CostCollector.store"
import { applyPrecision } from "../helpers"
import { ChangeValuesAction } from "./CostCollectAttributesAction"
import {
    AttributePriceItem,
    AttributeValuePriceItem,
    OnPressAction,
} from "./CostCollectorAttributes.types"

type FocusedCell = {
    attributeId: Uuid
    id: Uuid
    type: "newMarginValue" | "newPrice"
}

type OperationType = keyof Pick<
    AttributeValuePriceItem,
    "newPrice" | "newMarginFirst" | "newMarginValue"
>

export type MassChangeData = {
    operationType: ChangeValuesAction
    rounding: Precision
    value: AttributeValuePriceItem[OperationType]
}

type TableData = Record<Uuid, AttributeValuePriceItem[]>

interface ICostCollectorAttributesStore {
    attributeRows: AttributePriceItem[]
    setAttributeRows: (v: AttributePriceItem[]) => void
    attributeValuesTableData: TableData
    currentAttributeValuesTableRows: AttributeValuePriceItem[]
    activeAttributeId: Uuid | null
    activeAttributeName: string
    toggleActiveAttribute: (v: { id: Uuid; name: string }) => void
    selectedAttrubuteIds: Uuid[]
    availableData: Record<
        Uuid,
        {
            attributes: Uuid[]
            values: Uuid[]
            modelName: string
        }
    >
    setAvailableData: (
        modelId: Uuid,
        data: { attributes: Uuid[]; values: Uuid[]; modelName: string }
    ) => void
    resetAvailableData: () => void
    selectedValues: TableData
    toggleSelectedValue: (attributeId: Uuid, v: AttributeValuePriceItem) => void
    toggleAllValues: (attributeId: Uuid) => void
    toggleSelectedAttribute: (v: Uuid) => void
    toggleAllAttributes: () => void
    focusedCell: FocusedCell | null
    setFocusedCell: (data: Omit<FocusedCell, "attributeId"> | null) => void
    onPressAction: OnPressAction
    historyPopup: {
        visibleFor: AttributeValuePriceItem | null
        show: (v: AttributeValuePriceItem) => void
        hide: () => void
    }
    reset: () => void
    changePriceValue: (
        id: Uuid,
        type: ChangeValuesAction,
        value: AttributeValuePriceItem[keyof AttributeValuePriceItem]
    ) => void
    deletePriceValue: (id: Uuid, type: "newMarginValue" | "newPrice") => void
    resetTableData: () => void
    massChangePrice: (data: MassChangeData) => void
}

const getAttributeValuesData = (
    atrributeRows: AttributePriceItem[]
): TableData => {
    return atrributeRows.reduce((acc, next) => {
        const { id: attributeId, name: attributeName } = next.attribute

        acc[attributeId] = next.attributePrices.map((data) => {
            const [id, name] = Object.entries(data.attributeValue)[0]
            const priceData = data
            const oldPrice = priceData?.value
            const newPrice = oldPrice
            const marginValue = priceData?.marginValue
            const newMarginValue = marginValue
            const marginFirst = priceData?.marginFirst ?? false
            const newMarginFirst = undefined
            return {
                id,
                name,
                oldPrice,
                newPrice,
                marginValue,
                newMarginValue,
                marginFirst,
                newMarginFirst,
                model: data.model,
                attributeId,
                attributeName,
            } as AttributeValuePriceItem
        })

        return acc
    }, {} as TableData)
}

const deleteItemValue = (
    data: AttributeValuePriceItem,
    type: "newMarginValue" | "newPrice"
) => {
    const newData = { ...data }
    newData[type] = undefined
    return newData
}

const changeItemValue = (
    data: AttributeValuePriceItem,
    type: ChangeValuesAction,
    value: AttributeValuePriceItem[keyof AttributeValuePriceItem],
    precision?: Precision
): AttributeValuePriceItem => {
    const newData = { ...data }
    if (type === "newMarginValue") {
        const percent = value as number
        if (percent === data.marginValue) {
            delete newData.newMarginValue
        } else {
            newData.newMarginValue = applyPrecision(percent, precision)
        }
    }
    if (type === "changeMargin") {
        const percent = value as number
        const newMargin = newData.marginValue + percent
        if (percent === newData.marginValue) {
            delete newData.newMarginValue
        } else {
            newData.newMarginValue = applyPrecision(newMargin, precision)
        }
    }
    if (type === "changePrice") {
        const newPrice = newData.oldPrice + (value as number)
        if (newData.oldPrice === newPrice) {
            delete newData.newPrice
        } else {
            newData.newPrice = applyPrecision(newPrice, precision)
        }
    }
    if (type === "newPrice") {
        const newPrice = applyPrecision(value as number, precision)
        newData.newPrice = newPrice
    }
    if (type === "changePriceOnPercent") {
        const percent = value as number
        const price = newData.oldPrice + (newData.oldPrice * percent) / 100
        const newPrice = applyPrecision(price, precision)
        if (newData.oldPrice === newPrice) {
            delete newData.newPrice
        } else {
            newData.newPrice = newPrice
        }
    }
    if (type === "setMarginFirst") {
        newData.newMarginFirst = true
    }
    if (type === "removeMarginFisrt") {
        newData.newMarginFirst = false
    }
    return newData
}

const changeValue = (
    attributeValuesTableData: AttributeValuePriceItem[],
    id: Uuid,
    type: ChangeValuesAction,
    value: AttributeValuePriceItem[keyof AttributeValuePriceItem],
    precision?: Precision
): AttributeValuePriceItem[] => {
    const newValues = attributeValuesTableData.map((el) => {
        if (el.id !== id) return el
        return changeItemValue(el, type, value, precision ?? -2)
    })
    return newValues
}

const deletePriceValue = (
    attributeValuesTableData: AttributeValuePriceItem[],
    id: Uuid,
    type: "newMarginValue" | "newPrice"
): AttributeValuePriceItem[] => {
    const newValues = attributeValuesTableData.map((el) => {
        if (el.id !== id) return el
        return deleteItemValue(el, type)
    })
    return newValues
}

const getFocusedCell = (
    attributeId: Uuid | null,
    rows: AttributeValuePriceItem[],
    focusedCell: FocusedCell | null,
    action: "up" | "down" | "left" | "right" | "tab"
): FocusedCell | null => {
    if (!focusedCell || !attributeId) return null
    const { id, type } = focusedCell
    const currentRowIndex = rows.findIndex((el) => el.id === id)
    const prevIndex = Math.max(currentRowIndex - 1, 0)
    const nextIndex = Math.min(currentRowIndex + 1, rows.length - 1)
    const typeStack: FocusedCell["type"][] = ["newPrice", "newMarginValue"]
    const currentStackIndex = typeStack.indexOf(type)
    const isLastInStack = currentStackIndex === typeStack.length - 1
    const nextStackIndex = Math.min(currentStackIndex + 1, typeStack.length - 1)
    const prevStackIndex = Math.max(currentStackIndex - 1, 0)
    switch (action) {
        case "up": {
            return {
                attributeId,
                id: rows[prevIndex].id,
                type,
            }
        }
        case "down": {
            return {
                attributeId,
                id: rows[nextIndex].id,
                type,
            }
        }
        case "left": {
            return {
                attributeId,
                id,
                type: typeStack[prevStackIndex],
            }
        }
        case "right": {
            return {
                attributeId,
                id,
                type: typeStack[nextStackIndex],
            }
        }
        case "tab": {
            return {
                attributeId,
                id: isLastInStack ? rows[nextIndex].id : id,
                type: isLastInStack
                    ? typeStack[0]
                    : typeStack[currentStackIndex + 1],
            }
        }
        default: {
            return null
        }
    }
}

const massChangePrice = (
    data: MassChangeData,
    tableData: TableData,
    selectedValues: TableData
): TableData => {
    return Object.entries(tableData).reduce((acc, [attibuteId, priceData]) => {
        acc[attibuteId] = priceData.map((el) =>
            (selectedValues[attibuteId] ?? []).some(
                (selectedValue) => selectedValue.id === el.id
            )
                ? changeItemValue(
                      el,
                      data.operationType,
                      data.value,
                      data.rounding
                  )
                : el
        )
        return acc
    }, {} as TableData)
}

const resetTableData = (data: TableData): TableData => {
    const newData = Object.entries(data).reduce((acc, [id, rows]) => {
        acc[id] = rows.map((el) => ({
            ...el,
            newPrice: undefined,
            newMarginValue: undefined,
        }))
        return acc
    }, {} as TableData)

    return newData
}

export const useCostCollectorAttributeStore =
    create<ICostCollectorAttributesStore>((set) => ({
        activeAttributeId: null,
        selectedAttrubuteIds: [],
        focusedCell: null,
        attributeValuesTableData: {},
        activeAttributeName: "",
        attributeRows: [],
        selectedValues: {},
        currentAttributeValuesTableRows: [],
        availableData: {},
        setAvailableData: (modelId, data) => {
            return set((state) => ({
                ...state,
                availableData: {
                    ...state.availableData,
                    [modelId]: {
                        ...state.availableData[modelId],
                        ...data,
                    },
                },
            }))
        },
        resetAvailableData() {
            return set((state) => ({
                ...state,
                availableData: {},
            }))
        },
        historyPopup: {
            visibleFor: null,
            show: (v) => {
                return set((state) => ({
                    ...state,
                    historyPopup: {
                        ...state.historyPopup,
                        visibleFor: v,
                    },
                }))
            },
            hide: () =>
                set((state) => ({
                    ...state,
                    historyPopup: {
                        ...state.historyPopup,
                        visibleFor: null,
                    },
                })),
        },
        onPressAction: {
            down() {
                return set((state) => {
                    const valuesData = state.activeAttributeId
                        ? state.attributeValuesTableData[
                              state.activeAttributeId
                          ]
                        : []
                    return {
                        ...state,
                        focusedCell: getFocusedCell(
                            state.activeAttributeId,
                            valuesData,
                            state.focusedCell,
                            "down"
                        ),
                    }
                })
            },
            up() {
                return set((state) => {
                    const valuesData = state.activeAttributeId
                        ? state.attributeValuesTableData[
                              state.activeAttributeId
                          ]
                        : []
                    return {
                        ...state,
                        focusedCell: getFocusedCell(
                            state.activeAttributeId,
                            valuesData,
                            state.focusedCell,
                            "up"
                        ),
                    }
                })
            },
            left() {
                return set((state) => {
                    const valuesData = state.activeAttributeId
                        ? state.attributeValuesTableData[
                              state.activeAttributeId
                          ]
                        : []
                    return {
                        ...state,
                        focusedCell: getFocusedCell(
                            state.activeAttributeId,
                            valuesData,
                            state.focusedCell,
                            "left"
                        ),
                    }
                })
            },
            right() {
                return set((state) => {
                    const valuesData = state.activeAttributeId
                        ? state.attributeValuesTableData[
                              state.activeAttributeId
                          ]
                        : []
                    return {
                        ...state,
                        focusedCell: getFocusedCell(
                            state.activeAttributeId,
                            valuesData,
                            state.focusedCell,
                            "right"
                        ),
                    }
                })
            },
            tab() {
                return set((state) => {
                    const valuesData = state.activeAttributeId
                        ? state.attributeValuesTableData[
                              state.activeAttributeId
                          ]
                        : []
                    return {
                        ...state,
                        focusedCell: getFocusedCell(
                            state.activeAttributeId,
                            valuesData,
                            state.focusedCell,
                            "tab"
                        ),
                    }
                })
            },
        },
        toggleActiveAttribute(v) {
            return set((state) => {
                const newActiveAttribute =
                    state.activeAttributeId === v?.id ? null : v
                return {
                    ...state,
                    activeAttributeId: newActiveAttribute?.id || null,
                    activeAttributeName: newActiveAttribute?.name ?? "",
                    currentAttributeValuesTableRows: newActiveAttribute
                        ? state.attributeValuesTableData[newActiveAttribute.id]
                        : [],
                }
            })
        },
        toggleSelectedAttribute(v) {
            return set((state) => {
                const removeSelects = state.selectedAttrubuteIds.includes(v)
                return {
                    ...state,
                    selectedAttrubuteIds: removeSelects
                        ? state.selectedAttrubuteIds.filter((el) => el !== v)
                        : [...state.selectedAttrubuteIds, v],
                    selectedValues: {
                        ...state.selectedValues,
                        [v]: removeSelects
                            ? []
                            : state.attributeValuesTableData[v],
                    },
                }
            })
        },
        toggleAllAttributes: () =>
            set((state) => {
                const removeSelects = state.selectedAttrubuteIds.length
                return {
                    ...state,
                    selectedAttrubuteIds: removeSelects
                        ? []
                        : state.attributeRows.map((el) => el.attribute.id),
                    selectedValues: Object.entries(
                        state.attributeValuesTableData
                    ).reduce((acc, [attributeId, data]) => {
                        acc[attributeId] = removeSelects ? [] : data
                        return acc
                    }, {} as TableData),
                }
            }),
        setAttributeRows(v) {
            const newTableData = getAttributeValuesData(v)
            return set((state) => ({
                ...state,
                attributeRows: v,
                attributeValuesTableData: newTableData,
            }))
        },
        reset: () =>
            set((state) => ({
                ...state,
                selectedAttrubuteIds: [],
                activeAttributeId: null,
                selectedValues: {},
            })),
        changePriceValue(id, key, value) {
            return set((state) => ({
                ...state,
                attributeValuesTableData: {
                    ...state.attributeValuesTableData,
                    [state.activeAttributeId!]: changeValue(
                        state.attributeValuesTableData[
                            state.activeAttributeId!
                        ],
                        id,
                        key,
                        value
                    ),
                },
            }))
        },
        deletePriceValue(id, type) {
            return set((state) => ({
                ...state,
                attributeValuesTableData: {
                    ...state.attributeValuesTableData,
                    [state.activeAttributeId!]: deletePriceValue(
                        state.attributeValuesTableData[
                            state.activeAttributeId!
                        ],
                        id,
                        type
                    ),
                },
            }))
        },
        setFocusedCell(data) {
            return set((state) => ({
                ...state,
                focusedCell:
                    data && state.activeAttributeId
                        ? { ...data, attributeId: state.activeAttributeId }
                        : null,
            }))
        },
        resetTableData: () =>
            set((state) => ({
                ...state,
                attributeValuesTableData: resetTableData(
                    state.attributeValuesTableData
                ),
            })),
        massChangePrice(data) {
            return set((state) => ({
                ...state,
                attributeValuesTableData: massChangePrice(
                    data,
                    state.attributeValuesTableData,
                    state.selectedValues
                ),
            }))
        },
        toggleSelectedValue(attributeId, v) {
            return set((state) => {
                const selectedValues = state.selectedValues[attributeId] ?? []
                const newSelectedValues = selectedValues.some(
                    (el) => el.id === v.id
                )
                    ? selectedValues.filter((el) => el.id !== v.id)
                    : [...selectedValues, v]
                return {
                    ...state,
                    selectedValues: {
                        ...state.selectedValues,
                        [attributeId]: newSelectedValues,
                    },
                }
            })
        },
        toggleAllValues(attributeId) {
            return set((state) => {
                const removeSelects = state.selectedValues[attributeId]?.length
                return {
                    ...state,
                    selectedValues: {
                        ...state.selectedValues,
                        [attributeId]: removeSelects
                            ? []
                            : state.attributeValuesTableData[attributeId],
                    },
                    selectedAttrubuteIds: removeSelects
                        ? state.selectedAttrubuteIds.filter(
                              (el) => el !== attributeId
                          )
                        : [...state.selectedAttrubuteIds, attributeId],
                }
            })
        },
    }))
