import { checkMimeType } from "components/inputs/helpers"
import React from "react"
import { useDispatch } from "react-redux"
import { Uuid } from "types"
import api from "../../../helpers/api"
import { useNotifications } from "../../../hooks/useNotifications"
import { SET_CURRENT_VALUES } from "../../../store/fields/actions"
interface UploadFileProps {
    id: Uuid
    name: string
}

export const UploadFile: React.FC<UploadFileProps> = ({ id, name }) => {
    const dispatch = useDispatch()
    const { showNotification } = useNotifications()

    const sendFile = (event: any) => {
        const file = event.target.files[0]

        const fileLimit: number = 20_971_520 // 20Mb

        if (
            file.size >= fileLimit ||
            file.size === 0 ||
            !checkMimeType(file.type)
        ) {
            showNotification({
                type: "danger",
                message: "Неверный формат файла, или размер!",
            })

            return false
        }

        const formData: any = new FormData()
        formData.append("file", file)

        api.post("file/upload", {}, formData).then((response: any) => {
            dispatch(
                SET_CURRENT_VALUES({ fileId: response.data.id }, "listing")
            )
        })
    }

    return (
        <input
            className={`form-control`}
            name={name + id}
            type="file"
            onChange={(event: any) => sendFile(event)}
        />
    )
}
