import { useQuery } from "@tanstack/react-query"
import { Alert, Card } from "antd"
import { Flex } from "components/UI/Flex"
import UNumberItem from "components/UNumberItem"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { Uuid } from "types"
import { Country, ModelEntity, SeriesEnity } from "types/api"
import { ChangeModelAction, useCostCollectorStore } from "./CostCollector.store"
import { CostCollectAttributes } from "./CostCollectorAttributes"
import CostCollectorHistoryModal from "./CostCollectorHistoryModal"
import { CostCollectorSelectRowsAction } from "./CostCollectorSelectRowsAction"
import { TablePriceItem } from "./priceDataProps"

export type Currency = {
    code: string
    id: string
    isoCode: string
    name: string
}

export type PriceDataModelItem = {
    id: Uuid
    model: ModelEntity
    region: {
        country: Country
        id: string
        name: string
    }
    startAt: string
    value: number
    precision?: -2 | -1 | 0 | 1 | 2
}

export type PriceDataSeriesItem = {
    series: SeriesEnity
    modelsPrices: PriceDataModelItem[]
}

export type PriceData = {
    currency: Currency
    data: PriceDataSeriesItem[]
}

const PriceInputItem = ({
    defaultValue,
    onChange,
    min,
    max,
    row,
    type,
    noZero,
}: {
    defaultValue?: number
    onChange: (v: number) => void
    min?: number
    max?: number
    row: TablePriceItem
    type: ChangeModelAction
    noZero?: boolean
}) => {
    const {
        focusedCell,
        focusNextCell,
        setActiveCell,
        focusNextRow,
        focusPrevCell,
        focusPrevRow,
    } = useCostCollectorStore()

    const focusValueRef = useRef<number>()
    const inputRef = useRef<HTMLInputElement>(null)

    const onPressRightHandler = () => {
        focusNextCell()
    }

    const onPressLeftHandler = () => {
        focusPrevCell()
    }

    const onPressUpHandler = () => {
        focusPrevRow()
    }

    const onPressDownHandler = () => {
        focusNextRow()
    }

    const onPressTabHandler = () => {
        focusNextCell()
    }

    const parsedValue = useMemo(() => {
        if (!noZero) return defaultValue
        if (typeof defaultValue === "string") return
        if (!defaultValue && noZero) return
        return defaultValue
    }, [defaultValue, noZero])

    useEffect(() => {
        if (focusedCell?.id === row.id && focusedCell?.cellType === type) {
            inputRef.current?.focus()
        } else {
            inputRef.current?.blur()
        }
    }, [focusedCell])

    return (
        <UNumberItem
            value={parsedValue}
            key={parsedValue}
            bordered={false}
            noFormControl
            noBackground
            className="fw"
            onKeyDown={(e) => {
                if (e.key === "Tab") {
                    e.preventDefault()
                    onPressTabHandler()
                }
                if (e.key === "ArrowRight") {
                    onPressRightHandler()
                }
                if (e.key === "ArrowLeft") {
                    onPressLeftHandler()
                }
                if (e.key === "ArrowUp") {
                    e.preventDefault()
                    e.stopPropagation()
                    onPressUpHandler()
                }
                if (e.key === "ArrowDown") {
                    e.stopPropagation()
                    e.preventDefault()
                    onPressDownHandler()
                }
            }}
            onClick={() => {
                setActiveCell({
                    id: row.id,
                    cellType: type,
                })
            }}
            onPressEnter={(value) => {
                if (!value && inputRef.current) {
                    inputRef.current.value = ""
                }
                if (value === focusValueRef.current) return
                focusNextCell()
            }}
            ref={inputRef}
            handleFocus={(value) => {
                focusValueRef.current = value
                setActiveCell({
                    id: row.id,
                    cellType: type,
                })
            }}
            floatRank={2}
            handleBlur={(value) => {
                if (value === focusValueRef.current) return
                onChange(value)
            }}
            min={min}
            max={max}
        />
    )
}

export const CostCollectorModelTable = () => {
    const {
        itemsGroup,
        producer,
        selectedRows,
        toggleRow,
        setSeriesTableData,
        seriesTableData,
        changeModel,
        toggleAllModels,
        toggleHistoryModal,
        expandedRowKeys,
        setExpandedRowKeys,
    } = useCostCollectorStore()

    const currentRegion = useSelector((state: RootState) => state.region.region)

    const { isFetching, refetch } = useQuery<PriceData>({
        queryKey: [itemsGroup, producer, currentRegion, setSeriesTableData],
        enabled: !!itemsGroup && !!producer && !!currentRegion,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<PriceData>("v1/economics/model/prices", {
                producer: producer?.value,
                group: itemsGroup?.value,
                region: currentRegion?.value,
            }),
        onSuccess(data) {
            setSeriesTableData(data)
        },
    })

    const onExpandRow = useCallback(
        (row: TablePriceItem) => {
            const newRows = expandedRowKeys.includes(row.id)
                ? expandedRowKeys.filter((el) => el !== row.id)
                : [...expandedRowKeys, row.id]
            setExpandedRowKeys(newRows)
        },
        [setExpandedRowKeys, expandedRowKeys]
    )

    if (!currentRegion) {
        return <Alert message="Выберите регион" className="fw" />
    }

    if (!seriesTableData) return null

    return (
        <Flex.Col gap={20} fullWidth>
            <CostCollectorSelectRowsAction refetch={refetch} />
            <Card className="fw">
                <Flex.Col
                    fullWidth
                    margin={[0, 0, 10]}
                    styles={{ maxWidth: 300 }}
                ></Flex.Col>

                <UTable
                    loading={isFetching}
                    data={seriesTableData}
                    nested={(row) => !!row.parentId}
                    onRowClick={(row, e) => {
                        if (
                            ["INPUT", "BUTTON"].includes(
                                e?.currentTarget.tagName ?? ""
                            )
                        )
                            return
                        toggleRow(row)
                    }}
                    isHidden={(row) => {
                        return (
                            !!row.parentId &&
                            !expandedRowKeys.includes(row.parentId)
                        )
                    }}
                    columns={[
                        {
                            columnName: "ID",
                            render: (row) => (
                                <span
                                    onClick={() => {
                                        window.navigator.clipboard.writeText(
                                            row.id
                                        )
                                    }}
                                >
                                    {row.id}
                                </span>
                            ),
                            width: 5,
                        },
                        {
                            columnName: () => (
                                <Flex.Col justify="center">
                                    <input
                                        className="form-check-input cursor-pointer"
                                        onChange={toggleAllModels}
                                        type="checkbox"
                                        checked={seriesTableData
                                            .filter((el) => !el.modelRowId)
                                            .every((item) =>
                                                item.innerRowIds.every(
                                                    (rowId) =>
                                                        selectedRows.some(
                                                            (el) =>
                                                                el.modelRowId ===
                                                                rowId
                                                        )
                                                )
                                            )}
                                    />
                                </Flex.Col>
                            ),
                            render: (row) => (
                                <Flex.Col className="fw" justify="center">
                                    <input
                                        type="checkbox"
                                        className="form-check-input cursor-pointer"
                                        checked={row.innerRowIds.every(
                                            (rowId) =>
                                                selectedRows.some(
                                                    (el) =>
                                                        el.modelRowId === rowId
                                                )
                                        )}
                                        onChange={() => {
                                            toggleRow(row)
                                        }}
                                    />
                                </Flex.Col>
                            ),
                            width: 4,
                        },
                        {
                            columnName: "",
                            render: (row) =>
                                row.parentId ? null : (
                                    <button
                                        className="btn btn-outline fw"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onExpandRow(row)
                                        }}
                                    >
                                        {expandedRowKeys.includes(row.id)
                                            ? "-"
                                            : "+"}
                                    </button>
                                ),
                            width: 4,
                        },
                        {
                            columnName: "Название",
                            render: (row) => row.name,
                            width: 20,
                        },
                        {
                            columnName: "Старая цена",
                            render: (row) =>
                                row.parentId ? (
                                    <span style={{ fontWeight: "bold" }}>
                                        {row.oldPrice}
                                    </span>
                                ) : (
                                    <></>
                                ),
                            width: 10,
                        },
                        {
                            columnName: "Новая цена",
                            render: (row) =>
                                row.parentId ? (
                                    <PriceInputItem
                                        noZero
                                        defaultValue={
                                            row.newPrice === row.oldPrice
                                                ? undefined
                                                : row.newPrice
                                        }
                                        onChange={(value) => {
                                            changeModel(
                                                row.id,
                                                "newPrice",
                                                +value
                                            )
                                        }}
                                        row={row}
                                        type="newPrice"
                                    />
                                ) : (
                                    <></>
                                ),
                            width: 10,
                        },
                        {
                            columnName: "Сумма наценки",
                            render: (row) =>
                                row.parentId ? (
                                    <PriceInputItem
                                        defaultValue={row.markupValue}
                                        onChange={(value) => {
                                            changeModel(
                                                row.id,
                                                "markupValue",
                                                +value
                                            )
                                        }}
                                        row={row}
                                        type="markupValue"
                                    />
                                ) : (
                                    <></>
                                ),
                            width: 10,
                        },
                        {
                            columnName: "История",
                            render: (row) =>
                                row.parentId ? (
                                    <button
                                        className="btn btn-outline fw"
                                        onClick={() => {
                                            toggleHistoryModal(row)
                                        }}
                                    >
                                        +
                                    </button>
                                ) : null,
                            cellStyles: {
                                padding: 0,
                            },
                            width: 7,
                        },
                        {
                            columnName: "% наценки",
                            render: (row) =>
                                row.parentId ? (
                                    <PriceInputItem
                                        defaultValue={row.markupPercent}
                                        onChange={(value) => {
                                            changeModel(
                                                row.id,
                                                "markupPercent",
                                                +value
                                            )
                                        }}
                                        row={row}
                                        type="markupPercent"
                                    />
                                ) : (
                                    <></>
                                ),
                            width: 10,
                        },
                        {
                            columnName: "Валюта",
                            render: (row) => row.currency?.name ?? "",
                        },
                    ]}
                />
            </Card>
            {currentRegion && (
                <CostCollectorHistoryModal regionId={currentRegion.value} />
            )}
            <CostCollectAttributes />
        </Flex.Col>
    )
}
