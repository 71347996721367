/** IMPORTS */
const initialState = {
    import: {},
    importColumns: {},
}

export const imports = (state: {} = initialState, action: any) => {
    switch (action.type) {
        case "GET_IMPORT_COLUMNS": {
            return {
                ...state,
                importColumns: { ...action.payload },
            }
        }


        case "SET_IMPORT": {
            return {
                ...state,
                import: { ...action.payload },
            }
        }

        default: {
            return state
        }
    }
}
