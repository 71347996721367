import { useQuery } from "@tanstack/react-query"
import { Empty, Tooltip } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import Sticky from "react-sticky-el"
import api from "../../../helpers/api"
import { Uuid } from "../../../types"
import { usePicturesMassUploaderContextManager } from "./index"

type PictureType = Pick<File, "name"> & { localBlobUrl: string }
export const WorkspaceScreen: React.FC = () => {
    // const [pictures, setPictures] = useState<PictureType[]>([])

    const [activePicture, setActivePicture] = useState<string>("")
    const handleSetActivePicture = (pic: PictureType) => {
        setActivePicture(pic.localBlobUrl)
    }

    const {
        resetParams,
        params,
        fillAttribute,
        filledAttributes,
        changesList,
        addChange,
        setActiveScreen,
        picturesList,
        appendPictures,
        resetPictures,
    } = usePicturesMassUploaderContextManager()

    const [attributes, setAttributes] = useState<
        {
            attribute: {
                valuesList: Record<Uuid, string>
                name: string
                id: Uuid
            }
            sortChoosing: number
        }[]
    >([])

    const [attributesAvailableValues, setAttributesAvailableValues] = useState<
        {
            attributeId: Uuid
            configurableAttributeValues: Uuid[]
        }[]
    >([])

    const [attributesValues, setAttributesValues] = useState<
        {
            attributeId: Uuid
            attribute: {
                valuesList: Record<Uuid, string>
            }
        }[]
    >([])

    useEffect(() => {
        ;(async () => {
            // todo rewrite to react-query nested dependent queries
            const attributesByGroupProducerWithSorting = await api.getTyped<
                {
                    attribute: {
                        name: string
                        id: Uuid
                        valuesList: Record<Uuid, string>
                    }
                    sortChoosing?: number
                }[]
            >("attribute/group_producer_attributes_linking_details", {
                group: params?.group?.value,
                producer: params?.producer?.value,
            })

            const attributesByGroupProducerSeries = await api.getTyped<
                {
                    attributeId: Uuid
                    attribute: {
                        valuesList: Record<Uuid, string>
                    }
                }[]
            >("attribute/list-by-group-and-producer-and-series", {
                producer: params?.producer?.value,
                group: params?.group?.value,
                series: params?.series?.value,
            })

            let availableValues: {
                attributeId: Uuid
                configurableAttributeValues: Uuid[]
            }[] = []

            if (params?.model?.value) {
                availableValues = await api.getTyped(
                    "attribute/list-by-series-and-model",
                    {
                        series: params!.series?.value,
                        model: params!.model?.value,
                    }
                )
            }

            const attributesAssignedSorts = await api
                .getTyped<any[]>("v1/pictures-generator/list-attributes-sorts")
                .then((v) =>
                    Object.fromEntries(
                        v.map((curr) => [curr.attribute.id, curr.index])
                    )
                )

            setAttributesAvailableValues(availableValues)
            setAttributesValues(attributesByGroupProducerSeries)
            setAttributes(
                attributesByGroupProducerWithSorting
                    .map((item, i) => ({
                        ...item,
                        sortChoosing: Number(
                            item.sortChoosing || 1000 + i // 1000 to indicate it was frontend-side assigned
                        ),
                    }))
                    .sort((a, b) => a.sortChoosing - b.sortChoosing)
                    .filter((v) =>
                        attributesByGroupProducerSeries.some(
                            (valuesRow) =>
                                valuesRow.attributeId === v.attribute.id
                        )
                    )
                    .filter((row) => {
                        if (!params!.model?.value) return true

                        const availibleValuesForCurrAttribute =
                            availableValues.find(
                                (v) => v.attributeId === row.attribute.id
                            )

                        if (!availibleValuesForCurrAttribute) {
                            return false
                        }

                        return (
                            availibleValuesForCurrAttribute
                                .configurableAttributeValues.length > 0
                        )
                    })
                    .sort((a, b) => {
                        return (
                            (attributesAssignedSorts[a.attribute.id] ??
                                100_000) -
                            (attributesAssignedSorts[b.attribute.id] ?? 100_000)
                        )
                    })
            )
        })()
    }, [params])

    const { data: valuesAlternativeNames } = useQuery<
        {
            attributeId: Uuid
            valuesAlternativeNames: Record<Uuid, string>
        }[]
    >({
        queryKey: [
            "configurator-alternative-names",
            params!.group?.value,
            params!.producer?.value,
        ],
        queryFn: () =>
            api.getTyped(
                "attribute/group_producer_attributes_all_list_alt_names",
                {
                    group: params!.group?.value,
                    producer: params!.producer?.value,
                }
            ),
    })

    const buildValuesListByAttribute = (row: {
        attribute: {
            name: string
            id: Uuid
        }
        sortChoosing: number
    }) => {
        const values = Object.entries(
            attributesValues.find((v) => v.attributeId === row.attribute.id)
                ?.attribute.valuesList ?? {}
        ).map(([attributeValueId, attributeValueLabel]) => {
            const isAvailable = params?.model?.value
                ? attributesAvailableValues
                      .find((v) => v.attributeId === row.attribute.id)
                      ?.configurableAttributeValues?.some(
                          (configurableAttributeId) =>
                              configurableAttributeId === attributeValueId
                      )
                : true

            const initialName = attributeValueLabel
            const alternativeName = (valuesAlternativeNames?.find(
                (v) => v.attributeId === row.attribute.id
            )?.valuesAlternativeNames ?? {})[attributeValueId]

            return {
                id: attributeValueId,
                isAvailable,
                finalName: alternativeName ?? initialName,
                initialName,
                alternativeName,
            }
        })

        return values.sort((a, b) =>
            a.finalName
                .toLowerCase()
                .trim()
                .localeCompare(b.finalName.toLowerCase().trim())
        )
    }

    // fillAttribute, filledAttributes

    const handleChangeAttribute = (attribute: Uuid, value?: Uuid) => {
        fillAttribute(attribute, value)
    }

    const unavailabilityReason = useMemo(() => {
        if (Object.keys(filledAttributes).length < 1) {
            return "Выберите свойства"
        }

        if (activePicture === "") {
            return "Выберите изображение"
        }

        return null
    }, [filledAttributes, attributes, activePicture])

    const handleUploadPictures = (files: FileList) => {
        const filelist = files || []
        const arr = [...filelist].map<PictureType>((file) => {
            return {
                name: file.name,
                localBlobUrl: URL.createObjectURL(file),
            }
        })

        appendPictures(arr ?? [])
    }

    const handleSavePicture = () => {
        const attributesDataEntries = attributes
            .filter(({ attribute }) =>
                Object.keys(filledAttributes).includes(attribute.id)
            )
            .map((v) => {
                return {
                    values: buildValuesListByAttribute(v).filter((value) =>
                        Object.values(filledAttributes).includes(value.id)
                    ),
                    attribute: v.attribute.name,
                }
            })
            .map((curr) => [
                curr.attribute,
                curr.values.map((v) => v.finalName),
            ])
        addChange(activePicture, {
            values: Object.values(filledAttributes),
            renders: Object.fromEntries(attributesDataEntries),
        })
        setActivePicture("")
        // setActiveScreen("changes")
    }

    const isAttributesLoaded = useMemo(
        () => !!attributes && !!valuesAlternativeNames,
        [attributes]
    )

    return (
        <>
            <div className="pictures-mass-uploader__workspace-screen">
                <input
                    onChange={
                        (e) => handleUploadPictures(e.target.files!)

                        // const objectUrl = URL.createObjectURL(selectedFile)
                        // setPreview(objectUrl)
                        //
                        // // free memory when ever this component is unmounted
                        // return () => URL.revokeObjectURL(objectUrl)
                    }
                    multiple={true}
                    id="workspace-massupload-fileinput"
                    accept=".png,.jpeg,.jpg"
                    type="file"
                    hidden
                />

                <div className="workspace-screen__wrapper">
                    <div className="row justify-content-center">
                        <div className="workspace-screen__left-column col-sm-4 col-xl-4 col-xxl-4 ">
                            <div className="row">
                                <div className="col-12">
                                    {isAttributesLoaded && (
                                        <Sticky
                                            stickyStyle={{ marginTop: 25 }}
                                            topOffset={-25}
                                        >
                                            <div
                                                style={{
                                                    height: "calc(100vh - 145px)",
                                                }}
                                            >
                                                <div className="pictures-mass-uploader__ui-box workspace-screen__values-wrapper">
                                                    <div className="workspace-screen__values-list">
                                                        {attributes.map(
                                                            (row) => {
                                                                return (
                                                                    <li className="list-group-item d-block border-0">
                                                                        <h6>
                                                                            {
                                                                                row
                                                                                    .attribute
                                                                                    .name
                                                                            }
                                                                        </h6>

                                                                        <ul className="list-group list-group-flush">
                                                                            {buildValuesListByAttribute(
                                                                                row
                                                                            ).map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    if (
                                                                                        !option.isAvailable
                                                                                    ) {
                                                                                        return null
                                                                                    }

                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                option.id
                                                                                            }
                                                                                            className="list-group-item d-flex justify-content-between align-items-center"
                                                                                        >
                                                                                            {
                                                                                                option.finalName
                                                                                            }
                                                                                            <div className="form-check form-switch">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"
                                                                                                    checked={
                                                                                                        filledAttributes[
                                                                                                            row
                                                                                                                .attribute
                                                                                                                .id
                                                                                                        ] ===
                                                                                                        option.id
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        e
                                                                                                            .target
                                                                                                            .checked
                                                                                                            ? handleChangeAttribute(
                                                                                                                  row
                                                                                                                      .attribute
                                                                                                                      .id,

                                                                                                                  option.id
                                                                                                              )
                                                                                                            : handleChangeAttribute(
                                                                                                                  row
                                                                                                                      .attribute
                                                                                                                      .id
                                                                                                              )
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="values-list__buttons">
                                                    <div>
                                                        <div
                                                            className="btn-group mr-2"
                                                            role="group"
                                                            aria-label="First group"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                disabled={
                                                                    !!unavailabilityReason
                                                                }
                                                                onClick={() =>
                                                                    handleSavePicture()
                                                                }
                                                            >
                                                                {unavailabilityReason ??
                                                                    "Сохранить"}
                                                            </button>

                                                            <Tooltip title="Посмотреть список изменений">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() =>
                                                                        setActiveScreen(
                                                                            "changes"
                                                                        )
                                                                    }
                                                                >
                                                                    Изменения(
                                                                    {
                                                                        Object.keys(
                                                                            changesList
                                                                        ).length
                                                                    }
                                                                    )
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Sticky>
                                    )}

                                    {!isAttributesLoaded && (
                                        <Sticky
                                            stickyStyle={{ marginTop: 25 }}
                                            topOffset={-25}
                                        >
                                            <div
                                                style={{
                                                    height: "calc(100vh - 145px)",
                                                }}
                                            >
                                                <div className="pictures-mass-uploader__ui-box workspace-screen__values-wrapper">
                                                    <div
                                                        className="workspace-screen__values-list"
                                                        style={{
                                                            overflow: "hidden",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            className="spinner-border text-primary"
                                                            role="status"
                                                        >
                                                            <span className="visually-hidden">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Sticky>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="workspace-screen__right-column col-sm-8 col-xl-8 col-xxl-8">
                            <div className="col-12 mb-4">
                                <div className="pictures-mass-uploader__ui-box d-flex justify-content-between align-items-center">
                                    <nav>
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item">
                                                {params?.group?.label}
                                            </li>
                                            <li className="breadcrumb-item">
                                                {params?.producer?.label}
                                            </li>
                                            <li
                                                className={`breadcrumb-item ${
                                                    !params?.model && "active"
                                                }`}
                                            >
                                                {params?.series?.label}
                                            </li>

                                            {params?.model && (
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page"
                                                >
                                                    {params?.model?.label}
                                                </li>
                                            )}
                                        </ol>
                                    </nav>

                                    <button
                                        type="button"
                                        className="btn btn-link p-0"
                                        style={{ textDecoration: "none" }}
                                        onClick={() => {
                                            resetParams()
                                            setActiveScreen("setup")
                                        }}
                                    >
                                        Назад
                                    </button>
                                </div>
                            </div>

                            {picturesList?.length > 0 && (
                                <div className="col-12 mb-4">
                                    <div className="pictures-mass-uploader__ui-box">
                                        <div className="workspace-screen__actions">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    document
                                                        .querySelector<HTMLInputElement>(
                                                            "#workspace-massupload-fileinput"
                                                        )!
                                                        .click()
                                                }
                                            >
                                                Загрузить изображение
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => resetPictures()}
                                            >
                                                Очистить
                                            </button>
                                        </div>

                                        <div className="workspace-screen__pictures-list">
                                            {picturesList.map((picture) => (
                                                <div
                                                    className="pictures-list__picture"
                                                    key={picture.localBlobUrl}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            handleSetActivePicture(
                                                                picture
                                                            )
                                                        }
                                                        className={`picture__img ${
                                                            activePicture ===
                                                                picture.localBlobUrl &&
                                                            "picture__img--active"
                                                        }`}
                                                        src={
                                                            picture.localBlobUrl
                                                        }
                                                        alt={picture.name}
                                                    />
                                                    <div
                                                        style={{
                                                            fontSize: "13px",
                                                            opacity: 0.9,
                                                            marginTop: "5px",
                                                            width: "100%",
                                                            wordBreak:
                                                                "break-all",
                                                        }}
                                                    >
                                                        {picture.name}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!picturesList?.length && (
                                <div className="col-12 mb-4">
                                    <div className="pictures-mass-uploader__ui-box">
                                        <div className="workspace-screen__empty-files-uploader">
                                            <Empty
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                imageStyle={{ height: 60 }}
                                                description={
                                                    <span>
                                                        Необходимо загрузить
                                                        одну или <br />{" "}
                                                        несколько картинок,
                                                        чтобы начать работу
                                                    </span>
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        document
                                                            .querySelector<HTMLButtonElement>(
                                                                "#workspace-massupload-fileinput"
                                                            )!
                                                            .click()
                                                    }
                                                >
                                                    Загрузить изображения
                                                </button>
                                            </Empty>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
