import { useRegion } from "hooks/useRegion"
import { useEffect, useMemo, useRef, useState } from "react"
import { AsyncPaginate, AsyncPaginateProps } from "react-select-async-paginate"
import { Uuid } from "types"
import { preloadListingToPaginatedSelect } from "../../helpers/preloadListingToAsyncSelect"

type SelectProps<ResponseType> = {
    dataUrl: string
    params?: object
    page?: number
    noMessage?: string
    loadMessage?: string
    noDefaultOption?: boolean
    isClearable?: boolean
    onChange: (v: any, noReset?: true) => void
    onFirstOptionLoad?: () => void
    skipValues?: Uuid[]
    externalOptions?: any[]
    filter?: (v: any) => any
    transformResponse?: (v: ResponseType) => any
    regionDependent?: boolean
} & Omit<AsyncPaginateProps<any, any, any, any>, "loadOptions" | "onChange">

export default function AsyncSelect<ResponseType>({
    dataUrl,
    params,
    noDefaultOption,
    noMessage,
    loadMessage,
    debounceTimeout,
    placeholder,
    onChange,
    page,
    isDisabled,
    className,
    isClearable,
    value,
    isMulti,
    defaultValue,
    skipValues,
    onFirstOptionLoad,
    filter,
    styles,
    externalOptions = [],
    transformResponse,
    regionDependent,
    ...props
}: SelectProps<ResponseType>) {
    const [defaultValueSet, setDefautlValueSet] = useState(false)

    const ref = useRef<any>()
    const { activeRegion } = useRegion()

    const customParams = useMemo(() => {
        const params = {
            filter,
            transformResponse,
        } as any

        if (regionDependent) {
            params.region = activeRegion?.value
        }
        return params
    }, [filter, transformResponse, regionDependent, activeRegion])

    useEffect(() => {
        if (defaultValueSet) {
            onFirstOptionLoad && onFirstOptionLoad()
        }
    }, [defaultValueSet])

    return (
        <AsyncPaginate
            selectRef={ref}
            placeholder={placeholder ?? ""}
            loadOptions={preloadListingToPaginatedSelect(
                dataUrl,
                params,
                customParams
            )}
            filterOption={(v) => {
                if (!skipValues) return true
                return !skipValues?.includes(v.value)
            }}
            defaultOptions={noDefaultOption ?? true}
            key={params ? Object.values(params).join(" ") : params}
            noOptionsMessage={() => <>{noMessage ?? "Не найдено"}</>}
            loadingMessage={() => <>{loadMessage ?? "Загрузка..."}</>}
            debounceTimeout={debounceTimeout ?? 250}
            reduceOptions={(prev, loadedOptions) => {
                const newOptions = Array.from(
                    new Map(
                        [...externalOptions, ...prev, ...loadedOptions].map(
                            (item) => [item.value, item]
                        )
                    ).values()
                )

                const option = newOptions.find(
                    (el) => el.value === defaultValue
                )
                if (defaultValue && option) {
                    ref.current?.setValue(option)
                    onChange && onChange(isMulti ? [option] : option, true)
                }
                if (option && !defaultValueSet && !isDisabled) {
                    setDefautlValueSet(true)
                    ref.current?.setValue(option)
                    onChange && onChange(isMulti ? [option] : option, true)
                } else {
                    setDefautlValueSet(true)
                }
                return newOptions
            }}
            onChange={(v) => onChange(v)}
            additional={page ? { page } : undefined}
            isDisabled={isDisabled}
            isMulti={isMulti ?? false}
            className={className}
            value={value}
            isClearable={isClearable ?? undefined}
            styles={styles}
            {...props}
        />
    )
}
