import TableItems from "../components/TableItems"

const SettingValuesAttributesSeries = () => {
    return (
        <>
            <h5>Настройки названий для значений свойств</h5>
            <div className="control-in" style={{ width: "100%" }}>
                <TableItems type="series" />
            </div>
        </>
    )
}

export default SettingValuesAttributesSeries
