const initialState = {
    status: false,
}

export const selectStatus = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "SET_SELECT_STATUS": {
            return {
                ...state,
                status: action.payload,
            }
        }

        default: {
            return state
        }
    }
}
