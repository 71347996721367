import { Listing } from "components/Listing"
import GeneralConfiguratorWrapper from "pages/Configuration/GeneralConfigurator/GeneralConfiguratorWrapper"
import MassAttributeReplace from "pages/MassAttributeReplace"
import MassGeneration from "pages/Nomenclature/MassGeneration"
import { NomenclatureDetail } from "pages/Nomenclature/NomenclatureDetail"
import { CreateRouteData } from "./tools"

export const configuratorRoutes: CreateRouteData[] = [
    {
        path: "configurator",
        title: "Конфигуратор",
        render: () => <GeneralConfiguratorWrapper />,
        permission: "configuration/configurator",
    },
    {
        path: "configurator-iframe",
        title: "Конфигуратор",
        render: () => <GeneralConfiguratorWrapper isIframe />,
        permission: "configuration/configurator",
        dontShowInMenu: true,
    },
    {
        path: "configurator-collections",
        title: "Конфигуратор на базе коллекций",
        render: () => <GeneralConfiguratorWrapper useCollections />,
        permission: "configuration/configurator",
    },
    {
        path: "configurator-collections-iframe",
        title: "Конфигуратор на базе коллекций",
        render: () => <GeneralConfiguratorWrapper useCollections isIframe />,
        permission: "configuration/configurator",
        dontShowInMenu: true,
    },
    {
        path: "nomenclature",
        title: "Номенклатуры",
        render: () => <Listing />,
        createAndUpdate: () => <NomenclatureDetail />,
    },
    {
        path: "mass-generation",
        title: "Генератор номенклатур",
        render: () => <MassGeneration />,
    },
    {
        path: "attribute-replace",
        title: "Замена свойств",
        render: () => <MassAttributeReplace />,
    },
]
