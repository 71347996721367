/** ERRORS */
const initialState: {} = {}

export const errors = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_ERRORS": {
            return { ...action.payload }
        }

        case "SET_GLOBAL_ERROR": {
            return { ...action.payload }
        }

        case "RESET_GLOBAL_ERROR": {
            return {}
        }

        case "RESET_ERROR": {
            return []
        }

        default: {
            return state
        }
    }
}
