import { SyncOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import {
    Button,
    Form,
    Input,
    InputNumber,
    Modal,
    Table,
    Typography,
} from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import Link from "antd/es/typography/Link"
import { Layout } from "components/layouts"
import QuertFormFIeld from "components/QueryBuilder/QueryFormFIeld"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import withMessage, { WithShowMessage } from "hocs/withMessage"
import withQueryBuilder from "hocs/withQueryBuilder"
import { useNotifications } from "hooks/useNotifications"
import { NomenclatureResponse } from "pages/Kits/KitsItemsShowResult"
import { useMemo, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { useHistory, useParams } from "react-router-dom"
import { Uuid } from "types"
import DeliveryTimeFieldSelector from "./DeliveryTimeFieldSelector"
import DeliveryTimeValueSelector from "./DeliveryTimeValueSelector"

type DeliveryFormData = {
    filterName: string
    deliveryTime: number
    name: string
    id?: Uuid
    query: any
}

type DeliveryPostData = {
    filterName: string
    deliveryTime: number
    name: string
    payload: {
        query: any
    }
}

type DeliveryGetData = {
    deliveryTime: number
    name: string
    query: {
        query: any
    }
}

type Paginator = {
    allPages: number
    currentPage: number
    pageSize: number
    totalCount: number
}

const defaultPaginator = {
    allPages: 0,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
}

type GetNomenclatureRequest = {
    page: number
    countOnPage: number
    payload: {
        query?: RuleGroupType<any>
    }
}

type SaveFilterRequest = {
    deliveryTime: number
    payload: {
        query?: RuleGroupType<any>
    }
}

function DeliveryTime({ showMessage }: WithShowMessage) {
    const params = useParams() as { id?: Uuid }
    const history = useHistory()

    const [form] = useForm()

    const { id: itemId } = params

    const { showNotification } = useNotifications()

    const [paginator, setPaginator] = useState<Paginator>(defaultPaginator)
    const [query, setQuery] = useState<RuleGroupType<any>>()
    const [openModal, setOpenModal] = useState(false)

    const { data } = useQuery<DeliveryFormData>({
        queryKey: [itemId],
        enabled: !!itemId,
        queryFn: () =>
            api
                .getTyped<DeliveryGetData>(`v1/delivery-time-filter/${itemId}`)
                .then((data) => ({
                    deliveryTime: data.deliveryTime,
                    name: data.name,
                    filterName: data.name,
                    query: data.query.query,
                })),
        onSuccess: (data) => {
            form.setFieldsValue({ ...data, filterName: data.name })
        },
    })

    const mutations = {
        create: (requestData: DeliveryPostData) =>
            api.post("v1/delivery-time-filter/create", {}, requestData),
        update: (requestData: DeliveryPostData) =>
            api.put(`v1/delivery-time-filter/${itemId}`, {}, requestData),
    }

    const onFinish: FormProps["onFinish"] = (values: DeliveryFormData) => {
        const requestData: DeliveryPostData = {
            filterName: values.filterName,
            name: values.filterName,
            deliveryTime: values.deliveryTime,
            payload: {
                query: values.query,
            },
        }
        const mutatationKey: keyof typeof mutations = itemId
            ? "update"
            : "create"
        const mutate = mutations[mutatationKey]
        mutate(requestData)
            .then(() => {
                setTimeout(() => {
                    history.replace(`/delivery-time-filter`)
                }, 3000)
                showMessage({
                    type: "success",
                    content: "Успешно",
                })
            })
            .catch((error) => {
                showMessage({
                    type: "error",
                    content: error.message,
                })
            })
    }

    const {
        data: found,
        isFetching,
        refetch,
    } = useQuery<NomenclatureResponse>({
        queryKey: [params.id, paginator.currentPage, paginator.pageSize, query],
        enabled: !!params.id && !!query,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api
                .post<GetNomenclatureRequest, NomenclatureResponse>(
                    `v1/query-builder/delivery-time-filter/result`,
                    {},
                    {
                        page: paginator.currentPage,
                        countOnPage: paginator.pageSize,
                        payload: {
                            query: query,
                        },
                    }
                )
                .then(({ data }) => {
                    setPaginator(data.paginator)
                    return data
                }),
    })

    const saveFilter = () => {
        const query = form.getFieldValue("query")
        const deliveryTime = form.getFieldValue("deliveryTime")

        api.post<SaveFilterRequest, any>(
            "v1/query-builder/delivery-time-filter/set-delivery-time",
            {},
            {
                deliveryTime,
                payload: {
                    query,
                },
            }
        )
            .then(() => {
                showNotification({
                    message: "Сохранено",
                    type: "success",
                })
            })
            .catch((error: any) => {
                showNotification({
                    message: error.message,
                    type: "danger",
                })
            })
    }

    const showBuilder = useMemo(() => {
        if (!itemId) return true
        return !!data
    }, [itemId, data])

    return (
        <Layout.Detail pageTitle="Сроки поставки">
            <Flex.Col className="fw" gap={10}>
                <Form
                    size="large"
                    form={form}
                    onFinish={onFinish}
                    className="fw"
                    labelCol={{ xl: 6, md: 24 }}
                    labelAlign="left"
                    prefixCls="shyki"
                >
                    <Form.Item
                        label="Название"
                        name="filterName"
                        className="fw"
                        rules={[
                            {
                                required: true,
                                message: "Обязательное поле",
                            },
                        ]}
                    >
                        <Input className="fw form-control" />
                    </Form.Item>
                    <Form.Item
                        required
                        label="Срок поставки"
                        name="deliveryTime"
                        className="fw"
                        rules={[
                            {
                                required: true,
                                message: "Обязательное поле",
                            },
                        ]}
                    >
                        <InputNumber
                            className="fw form-control"
                            type="number"
                            controls={false}
                        />
                    </Form.Item>

                    <Form.Item label="Правило" name="query">
                        {showBuilder && (
                            <QuertFormFIeld
                                defaultQuery={data?.query}
                                getEntitiesURL="v1/query-builder/delivery-time-filter/entities-list"
                                onQueryChange={(q) => {
                                    setQuery(q)
                                    form.setFieldValue("query", q)
                                }}
                                controlElements={{
                                    valueEditor: DeliveryTimeValueSelector,
                                    fieldSelector: DeliveryTimeFieldSelector,
                                }}
                                translations={{
                                    addGroup: { label: "Добавить группу" },
                                    addRule: { label: "Добавить правило" },
                                }}
                            />
                        )}
                    </Form.Item>
                    <Flex.Row
                        className="fw"
                        gap={10}
                        justify="end"
                        styles={{ marginBottom: 10 }}
                    >
                        <Button type="primary" onClick={saveFilter}>
                            Применить новый срок на номеклатуру
                        </Button>
                        <Button
                            disabled={!found?.entityList?.length}
                            onClick={() => {
                                setOpenModal(true)
                            }}
                        >
                            Показать номенклатуру
                        </Button>
                        <Button
                            loading={isFetching}
                            icon={<SyncOutlined />}
                            onClick={() => refetch()}
                        />
                        <Flex.Row
                            justify="center"
                            align="center"
                            styles={{ width: 200 }}
                        >
                            {!!found && !isFetching && (
                                <Typography.Text>
                                    {paginator.totalCount
                                        ? `Найдено: ${paginator.totalCount}`
                                        : "Не найдено"}
                                </Typography.Text>
                            )}
                        </Flex.Row>
                    </Flex.Row>
                    <Flex.Col fullWidth align="end">
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>
                    </Flex.Col>
                </Form>
                <Modal
                    open={openModal}
                    onCancel={() => setOpenModal(false)}
                    title="Найденная номенклатура"
                    cancelButtonProps={{ hidden: true }}
                    okText="Закрыть"
                    onOk={() => setOpenModal(false)}
                >
                    <Table
                        loading={isFetching}
                        dataSource={found?.entityList ?? []}
                        columns={[
                            {
                                title: "Название",
                                key: "name",
                                dataIndex: "name",
                                render(value, record) {
                                    return (
                                        <Link
                                            href={`/configuration/nomenclature/update-${record.id}`}
                                            target="_blank"
                                        >
                                            {value}
                                        </Link>
                                    )
                                },
                            },
                        ]}
                        pagination={{
                            pageSize: paginator.pageSize,
                            locale: {
                                page: "Страница",
                                next_page: "Следующая страница",
                                prev_page: "Предыдущая страница",
                                items_per_page: "на странице",
                            },
                            total: paginator.totalCount,
                            current: paginator.currentPage,
                            onChange: (page, pageSize) => {
                                setPaginator((prev) => ({
                                    ...prev,
                                    pageSize,
                                    currentPage: page,
                                }))
                            },
                        }}
                    />
                </Modal>
            </Flex.Col>
        </Layout.Detail>
    )
}

export default withQueryBuilder(withMessage(DeliveryTime))
