import { CaretDownFilled, LoadingOutlined } from "@ant-design/icons"
import { Empty, Spin, Tooltip } from "antd"
import cls from "classnames"
import { Flex } from "components/UI/Flex"
import { ReactNode, useCallback, useMemo, useRef, useState } from "react"
import CommonTable from "./CommonTable"
import { TableCol, TableProps } from "./types"
import VirtualTable from "./VirtualTable"

type SortState = {
    activeColumn: string | null
    sortDirection: "down" | "up"
    sort?: (rowA: any, rowB: any) => number
}

const HeaderTooltip = ({
    columnName,
    textAlign,
    sortState,
    onClick,
    columnKey,
    isDisabled,
}: {
    columnName: TableCol<any>["columnName"]
    textAlign: TableProps<any>["textAlign"]
    sortState: SortState
    onClick?: () => void
    columnKey: string
    isDisabled: TableCol<any>["isDisabled"]
}) => {
    if (typeof columnName === "function") {
        return (
            <div
                className={cls({
                    "virtual-table_cell": true,
                    "cursor-pointer": !!onClick,
                    "virtual-table_cell__isDisabled": isDisabled,
                })}
                style={{
                    textAlign: textAlign,
                }}
                onClick={onClick}
            >
                {columnName()}
                {columnKey === sortState.activeColumn && (
                    <div
                        className={cls(["utable-sort-icon"], {
                            "utable-sort-icon-rotated":
                                sortState.sortDirection === "up",
                        })}
                    >
                        <CaretDownFilled className="fw" />
                    </div>
                )}
            </div>
        )
    }
    return (
        <Tooltip title={columnName}>
            <div
                className={cls({
                    "virtual-table_cell": true,
                    "cursor-pointer": !!onClick,
                    "virtual-table_cell__isDisabled": isDisabled,
                })}
                style={{
                    textAlign: textAlign,
                }}
                onClick={onClick}
            >
                {columnName}
                {columnKey === sortState.activeColumn && (
                    <div
                        className={cls(["utable-sort-icon"], {
                            "utable-sort-icon-rotated":
                                sortState.sortDirection === "up",
                        })}
                    >
                        <CaretDownFilled className="fw" />
                    </div>
                )}
            </div>
        </Tooltip>
    )
}

export default function UTable<T extends object>(
    props: Omit<TableProps<T>, "children"> & {
        children?: ReactNode | T[]
        isDisableFocusInput?: boolean
    }
) {
    const {
        search,
        columns,
        data,
        searchCondition,
        isHidden,
        height,
        loading,
        sort,
        disableListVirtualization,
        VIRTUAL_ROW_COUNT = 100,
        textAlign,
        tableProps,
        children,
    } = props
    const [searchValue, setSearchValue] = useState("")

    const searchInterval = useRef<ReturnType<typeof setTimeout>>()
    const [sortState, setSortState] = useState<SortState>({
        activeColumn: null,
        sortDirection: "down",
    })
    const theadRef = useRef<HTMLDivElement>(null)

    const containerRef = useRef<HTMLDivElement>(null)

    const gridTemplateColumns = columns
        .map((el) => (el.width ? `${el.width}%` : "auto"))
        .join(" ")

    const currentSortFunction = useMemo(() => {
        if (sortState.sort) {
            return sortState.sortDirection === "down"
                ? sortState.sort
                : (rowA: T, rowB: T) => -sortState.sort!(rowA, rowB)
        }
        return sort
    }, [sort, sortState])

    const filtredData = useMemo(() => {
        const searchData = searchCondition
            ? data.filter((row) => searchCondition(row, searchValue))
            : data
        const sortData = currentSortFunction
            ? searchData.sort(currentSortFunction)
            : searchData
        const withoutHidden = isHidden
            ? sortData.filter((row) => !isHidden(row))
            : sortData
        return withoutHidden
    }, [searchCondition, data, searchValue, currentSortFunction, isHidden])

    const notVirtual = useMemo(() => {
        if (disableListVirtualization) return true
        return data.length < VIRTUAL_ROW_COUNT
    }, [disableListVirtualization, VIRTUAL_ROW_COUNT, data])

    const onChangeSort = useCallback(
        (columnKey: string, sort: SortState["sort"]) => {
            if (!sort) return
            const currentSorter = sortState.activeColumn
            if (!currentSorter || currentSorter !== columnKey) {
                setSortState({
                    activeColumn: columnKey,
                    sort,
                    sortDirection: "down",
                })
            } else {
                setSortState((prev) =>
                    prev.sortDirection === "down"
                        ? {
                              ...prev,
                              sortDirection: "up",
                          }
                        : {
                              activeColumn: null,
                              sort: undefined,
                              sortDirection: "down",
                          }
                )
            }
        },
        [sortState, setSortState]
    )

    const onSearchChange = useCallback(
        (v: string) => {
            if (searchInterval.current) {
                clearTimeout(searchInterval.current)
            }
            setSearchValue(v)
            searchInterval.current = setTimeout(() => {
                if (props.onSearch) {
                    props.onSearch(v)
                }
            }, 500)
        },
        [setSearchValue, props.onSearch]
    )

    return (
        <Flex.Col fullWidth gap={5}>
            {search && (
                <input
                    className="virtual-table_search"
                    placeholder={
                        filtredData.length
                            ? `Поиск среди ${filtredData.length} строк`
                            : "Поиск"
                    }
                    value={searchValue}
                    onChange={(e) => onSearchChange(e.target.value)}
                />
            )}
            {Array.isArray(children) ? null : children}
            <div className="virtual-table">
                {loading && (
                    <div className="virtual-table_loading">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            }
                        />
                    </div>
                )}
                <div
                    className="virtual-table_thead"
                    style={{
                        gridTemplateColumns,
                        paddingRight: notVirtual ? 0 : 10,
                        fontSize: tableProps?.fontSize,
                    }}
                    ref={theadRef}
                >
                    {columns.map((column, idx) => {
                        const columnKey = "header" + idx
                        return (
                            <HeaderTooltip
                                key={columnKey}
                                columnKey={columnKey}
                                columnName={column.columnName}
                                textAlign={textAlign}
                                sortState={sortState}
                                isDisabled={column.isDisabled}
                                onClick={
                                    column.sorter
                                        ? () => {
                                              onChangeSort(
                                                  columnKey,
                                                  column.sorter
                                              )
                                          }
                                        : undefined
                                }
                            />
                        )
                    })}
                </div>
                <div
                    className="virtual-table_tbody"
                    ref={containerRef}
                    style={{
                        height:
                            height ??
                            (props.maxRowsVisible
                                ? props.maxRowsVisible * 35
                                : undefined),
                        fontSize: tableProps?.fontSize,
                    }}
                >
                    {props.isFetching && (
                        <Flex.Col
                            fullHeight
                            fullWidth
                            absolute
                            align="center"
                            justify="center"
                            className="virtual-table_fetch-loader"
                        >
                            <Spin />
                        </Flex.Col>
                    )}
                    {!filtredData.length && (
                        <Flex.Col
                            fullHeight
                            fullWidth
                            absolute
                            justify="center"
                            align="center"
                            styles={{
                                background: "#fff",
                            }}
                        >
                            <Empty />
                        </Flex.Col>
                    )}
                    {notVirtual ? (
                        <CommonTable
                            {...props}
                            data={filtredData}
                            height={height}
                        />
                    ) : (
                        <VirtualTable
                            {...props}
                            data={filtredData}
                            height={height}
                            resetScrollKey={props.resetScrollKey}
                        />
                    )}
                </div>
            </div>
        </Flex.Col>
    )
}
