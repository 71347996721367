import { useQuery } from "@tanstack/react-query"
import NamingSelectWithActions from "components/NamingSelectWithActions"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { getCurrentAttributes } from "pages/NameGenerationSettings/helper"
import { useNameGenerationSettingsStore } from "pages/NameGenerationSettings/NameGenerationSettings.store"
import { FC, useEffect, useState } from "react"
import { Uuid } from "types"

interface IValue {
    value: string
    label: string
    sort: number
}

const SettingsModel: FC<{
    activeSeriesId: Uuid
    activeModelId: Uuid
    accountingNameAttributes?: IValue[]
    nameAttributes?: IValue[]
    label: string
}> = ({
    activeSeriesId,
    activeModelId,
    accountingNameAttributes = [],
    nameAttributes = [],
    label,
}) => {
    const { setModelsAttributes } = useNameGenerationSettingsStore()
    const [options, setOptions] = useState<IValue[] | []>([])

    const { data, isFetched } = useQuery({
        queryKey: [
            "configurator-attributes-configurable-by-model",
            activeSeriesId,
            activeModelId,
        ],
        queryFn: () =>
            api.getTyped("model/get-attribute-links", {
                series: activeSeriesId,
                model: activeModelId,
            }),
    })

    useEffect(() => {
        const {
            options: defaultOptions,
            currentAccountingAttributes,
            currentNameAttributes,
        } = getCurrentAttributes(data)

        setOptions(defaultOptions)
        setModelsAttributes({
            typeAttribute: "name",
            value: currentNameAttributes,
            idModel: activeModelId,
            idSeries: activeSeriesId,
        })
        setModelsAttributes({
            typeAttribute: "accounting",
            value: currentAccountingAttributes,
            idModel: activeModelId,
            idSeries: activeSeriesId,
        })
    }, [data])

    if (!isFetched) {
        return <div>Загрузка...</div>
    }

    return (
        <Flex.Col>
            <NamingSelectWithActions
                dubName={label}
                attributes={data}
                values={{
                    accountingNameAttributes: accountingNameAttributes,
                    nameAttributes: nameAttributes,
                }}
                onChange={(prop) =>
                    setModelsAttributes({
                        ...prop,
                        idModel: activeModelId,
                        idSeries: activeSeriesId,
                    })
                }
                type="model"
                options={options}
                hiddenCopyButton
            />
        </Flex.Col>
    )
}

export default SettingsModel
