import { SelectEntity } from "components/Selects/types"
import { Uuid } from "types"
import { create } from "zustand"

type EnitityState = SelectEntity | null
type ChangeEnityState = (v: SelectEntity | null) => void

interface IReadyPictureConfiguratorStore {
    activeProducer: EnitityState
    setActiveProducer: ChangeEnityState
    activeGroup: EnitityState
    setActiveGroup: ChangeEnityState
    activeSeries: EnitityState
    setActiveSeries: ChangeEnityState
    activeModel: EnitityState
    setActiveModel: ChangeEnityState
    filledAttributes: Record<Uuid, string>
    setFilledAttributes: (v: Record<Uuid, string>) => void
    applied: boolean
    setApplied: (v: boolean) => void
    reset: () => void
    onFillAttribute: (attributeId: Uuid, valueId?: Uuid) => void
}

export const useReadyPictureConfiguratorStore =
    create<IReadyPictureConfiguratorStore>((set) => ({
        activeProducer: null,
        activeGroup: null,
        activeModel: null,
        activeSeries: null,
        filledAttributes: {},
        applied: false,
        setApplied(v) {
            return set((state) => ({
                ...state,
                applied: v,
            }))
        },
        reset() {
            return set((state) => ({
                ...state,
                activeGroup: null,
                activeModel: null,
                activeProducer: null,
                activeSeries: null,
                applied: false,
            }))
        },
        setActiveGroup: (v) =>
            set((state) => ({
                ...state,
                activeGroup: v,
            })),
        setActiveProducer: (v) =>
            set((state) => ({
                ...state,
                activeProducer: v,
            })),
        setActiveSeries: (v) =>
            set((state) => ({
                ...state,
                activeSeries: v,
            })),
        setActiveModel: (v) =>
            set((state) => ({
                ...state,
                activeModel: v,
            })),
        setFilledAttributes: (v) =>
            set((state) => {
                return {
                    ...state,
                    filledAttributes: v,
                }
            }),
        onFillAttribute: (attributeId, valueId) =>
            set((state) => {
                let curr = state.filledAttributes
                curr = valueId
                    ? {
                          ...curr,
                          [attributeId]: valueId,
                      }
                    : Object.keys(curr).reduce((prev, currKey) => {
                          if (currKey === attributeId.toString()) {
                              return prev
                          }
                          return {
                              ...prev,
                              [currKey]: curr[currKey],
                          }
                      }, {})
                return {
                    ...state,
                    filledAttributes: curr,
                }
            }),
    }))
