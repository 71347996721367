import { Select } from "antd"
import { useMemo } from "react"
import { FieldSelectorProps } from "react-querybuilder"

export default function FieldSelector({
    value,
    handleOnChange,
    options,
}: FieldSelectorProps) {
    const builderOptions = options as Array<{ name: string; label: string }>

    const _options = useMemo(() => {
        return builderOptions.map((el) => ({
            label: el.label,
            value: el.name,
        }))
    }, [builderOptions])

    return (
        <Select
            showSearch
            className="fw"
            options={_options}
            value={value}
            onChange={(e) => handleOnChange(e)}
            filterOption={(input, option) => {
                return (
                    option?.label
                        ?.toLocaleString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) || false
                )
            }}
        />
    )
}
