import { Tooltip } from "antd"
import clsx from "clsx"
import { SelectEntity } from "components/Selects/types"
import { AsyncSelect } from "components/UI"
import api from "helpers/api"
import React, { useCallback, useEffect, useMemo } from "react"
import { ModelReference, Uuid } from "types"
import { Entity } from "types/api"
import { ConfiguratorContainersWrapper } from "./ConfiguratorContainersWrapper"
import { ConfiguratorListing } from "./ConfiguratorListing"
import GeneralConfiguratorCollection from "./GeneralConfigurator.Collection"
import { useConfiguratorStore } from "./GeneralConfiguratorWrapper"

export interface ConfiguratorParams {
    producer?: Uuid
    group?: Uuid
    model?: SelectEntity
    series?: Uuid
}
export const GeneralConfigurator: React.FC<{
    useCollections?: boolean
    isIframe?: boolean
}> = ({ useCollections, isIframe }) => {
    document.title = "Конфигуратор"
    const {
        activeGroup,
        activeModel,
        activeProducer,
        activeSeries,
        setActiveGroup,
        setActiveModel,
        setActiveProducer,
        setActiveSeries,
        setActiveCollection,
        activeCollection,
        isLoading,
        setIsLoading,
        setApply,
        params,
        apply,
        filledAttributes,
        setFilledAttribute,
        resetFilledAttributes,
    } = useConfiguratorStore()

    const handleApplyActiveParams = () => {
        setIsLoading(true)
        setApply(true)
        setTimeout(() => setIsLoading(false), 4000)
    }

    const activeParams = useMemo(() => {
        if (!params) return params
        const values = Object.values(params).filter(Boolean)
        return values.length === 4 ? params : null
    }, [params, useCollections])

    const isPublishedFilter = useCallback((value: Entity) => {
        return value.fields?.some((el) => el.name === "isPublished" && el.value)
    }, [])

    const reset = () => {
        setActiveGroup(null)
        setActiveModel(null)
        setActiveProducer(null)
        setActiveSeries(null)
        setActiveCollection(null)
        setApply(false)
        resetFilledAttributes()
    }

    const isModelEnabled = useMemo(() => {
        return useCollections ? !!activeGroup : !!activeSeries
    }, [useCollections, activeSeries, activeGroup])

    const isButtonDisabled = useMemo(() => {
        return useCollections
            ? !activeGroup || !activeModel || isLoading
            : !activeGroup?.value ||
                  !activeProducer?.value ||
                  !activeModel?.value ||
                  !activeSeries?.value ||
                  isLoading
    }, [
        useCollections,
        isLoading,
        activeGroup,
        activeProducer,
        activeSeries,
        activeModel,
    ])

    useEffect(() => {
        if (useCollections && activeModel) {
            setIsLoading(true)
            api.get<ModelReference>("reference/model/" + activeModel.value)
                .then((response) => {
                    const data = response.data
                    const series = data.series
                    const producer = series.producer
                    setActiveProducer({
                        label: producer.name,
                        value: producer.id,
                    })
                    setActiveSeries({
                        label: series.name,
                        value: series.id,
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [activeModel, useCollections, setActiveSeries, setActiveProducer])

    return (
        <>
            {!isIframe && <h1 className="h3 mb-3">Конфигуратор</h1>}
            <div
                className={clsx({
                    control: true,
                    configuratorIframe: isIframe,
                })}
            >
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className={clsx({ "control-view": !isIframe })}>
                            <div className="control-view__main">
                                <div className="control-in">
                                    {useCollections && (
                                        <GeneralConfiguratorCollection />
                                    )}

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            <Tooltip
                                                placement="right"
                                                overlayInnerStyle={{
                                                    width: 310,
                                                    textAlign: "center",
                                                }}
                                                title={
                                                    activeGroup?.value ??
                                                    "значение не выбрано"
                                                }
                                            >
                                                Номенклатурная группа
                                            </Tooltip>
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                dataUrl="items-group"
                                                value={activeGroup}
                                                params={{
                                                    collection:
                                                        activeCollection?.value,
                                                }}
                                                onChange={(e) => {
                                                    setActiveProducer(null)
                                                    setActiveSeries(null)
                                                    setActiveModel(null)
                                                    setActiveGroup(
                                                        e as typeof activeGroup
                                                    )
                                                    setApply(false)
                                                    resetFilledAttributes()
                                                }}
                                                page={1}
                                                filter={isPublishedFilter}
                                            />
                                        </div>
                                    </div>

                                    {!useCollections && (
                                        <div className="row mb-3">
                                            <label className="col-xl-3 col-form-label">
                                                <Tooltip
                                                    placement="right"
                                                    overlayInnerStyle={{
                                                        width: 310,
                                                        textAlign: "center",
                                                    }}
                                                    title={
                                                        activeProducer?.value ??
                                                        "значение не выбрано"
                                                    }
                                                >
                                                    Поставщик
                                                </Tooltip>
                                            </label>
                                            <div className="col-xl-9">
                                                <AsyncSelect
                                                    isDisabled={
                                                        !activeGroup?.value
                                                    }
                                                    value={activeProducer}
                                                    onChange={(e) => {
                                                        setActiveSeries(null)
                                                        setActiveModel(null)
                                                        setActiveProducer(
                                                            e as typeof activeProducer
                                                        )
                                                        setApply(false)
                                                        resetFilledAttributes()
                                                    }}
                                                    dataUrl="producer"
                                                    params={{
                                                        collection:
                                                            activeCollection?.value,
                                                        itemsGroups:
                                                            activeGroup?.value,
                                                    }}
                                                    filter={isPublishedFilter}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {!useCollections && (
                                        <div className="row mb-3">
                                            <label className="col-xl-3 col-form-label">
                                                <Tooltip
                                                    placement="right"
                                                    overlayInnerStyle={{
                                                        width: 310,
                                                        textAlign: "center",
                                                    }}
                                                    title={
                                                        activeSeries?.value ??
                                                        "значение не выбрано"
                                                    }
                                                >
                                                    Серия
                                                </Tooltip>
                                            </label>
                                            <div className="col-xl-9">
                                                <AsyncSelect
                                                    isDisabled={
                                                        !activeGroup?.value ||
                                                        !activeProducer?.value
                                                    }
                                                    value={activeSeries}
                                                    onChange={(e) => {
                                                        setActiveSeries(
                                                            e as typeof activeSeries
                                                        )
                                                        setActiveModel(null)
                                                        setApply(false)
                                                        resetFilledAttributes()
                                                    }}
                                                    dataUrl="series"
                                                    params={{
                                                        producer:
                                                            activeProducer?.value,
                                                        itemsGroup:
                                                            activeGroup?.value,
                                                        collection:
                                                            activeCollection?.value,
                                                    }}
                                                    filter={isPublishedFilter}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            <Tooltip
                                                placement="right"
                                                overlayInnerStyle={{
                                                    width: 310,
                                                    textAlign: "center",
                                                }}
                                                title={activeModel?.value ?? ""}
                                            >
                                                Модель
                                            </Tooltip>
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={!isModelEnabled}
                                                onChange={(e) => {
                                                    setActiveModel(
                                                        e as typeof activeModel
                                                    )
                                                    setApply(false)
                                                    resetFilledAttributes()
                                                }}
                                                value={activeModel}
                                                dataUrl="model"
                                                params={{
                                                    collection:
                                                        activeCollection?.value,
                                                    series: activeSeries?.value,
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                                filter={isPublishedFilter}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleApplyActiveParams}
                                            disabled={isButtonDisabled}
                                        >
                                            {isLoading ? (
                                                <div
                                                    className="spinner-border spinner-border-sm text-light"
                                                    role="status"
                                                ></div>
                                            ) : (
                                                "Применить"
                                            )}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-3"
                                            onClick={reset}
                                        >
                                            Сбросить
                                        </button>
                                    </div>
                                </div>

                                {activeParams && apply && (
                                    <ConfiguratorContainersWrapper
                                        params={activeParams}
                                        filledAttributes={filledAttributes}
                                        setFilledAttributes={setFilledAttribute}
                                        iframe={!!isIframe}
                                    />
                                )}
                            </div>

                            {!isIframe && (
                                <div className="control-view__info"></div>
                            )}
                        </div>
                    </div>

                    {apply &&
                        activeParams &&
                        Object.keys(filledAttributes).length > 0 && (
                            <div className="tab-pane fade show active mt-3">
                                <div className="control-view">
                                    <div className="control-view__main">
                                        <ConfiguratorListing
                                            filled={filledAttributes}
                                            params={activeParams}
                                        />
                                    </div>

                                    {!isIframe && (
                                        <div className="control-view__info"></div>
                                    )}
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}
