import { useQuery } from "@tanstack/react-query"
import { Button } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import { TableCol } from "components/UTable/types"
import api from "helpers/api"
import { useNameGenerationSettingsStore } from "pages/NameGenerationSettings/NameGenerationSettings.store"
import { FC, useEffect, useState } from "react"
import { AttributeListItem, Uuid } from "types"
import ColumnSorterSwitcher from "../../ColumnSorterSwitcher"
import PropertiesInput from "./PropertiesInput"
import styles from "./propertiesTable.module.scss"

type Type = "model" | "series"
interface IPropertiesTableProps {
    isHiddenSort?: boolean
    activeAttributeId?: Uuid
    setActiveAttributeId: (val: Uuid) => void
    type?: Type
    activeModelId?: Uuid
}

type Row = Required<AttributeListItem> & {
    sortChoosingProducer?: Uuid
    sortAccountingNaming?: Uuid
    sort?: number
    error?: {
        isError: boolean
        message: string
    } | null
    disabled?: boolean
}

enum Sort {
    "",
    "up",
    "down",
}

const filterArray = (
    array: Row[],
    search: string,
    sort: boolean,
    availableSort: boolean
) => {
    const currentArray = array?.map((item) => ({
        ...item,
        disabled:
            (!!search.trim() &&
                (Number.isNaN(Number(search))
                    ? !new RegExp(search, "i").test(item.attribute.name)
                    : (availableSort
                          ? item.sort
                          : item.sortChoosingProducer) !== Number(search))) ||
            (sort && (!item.sortAccountingNaming || !item.sortNaming)),
    }))

    const comparator = new Intl.Collator()

    if (!search && !sort) {
        return currentArray.sort((rowA, rowB) =>
            comparator.compare(rowA.attribute.name, rowB.attribute.name)
        )
    }

    return currentArray
}

const PropertiesTable: FC<IPropertiesTableProps> = ({
    isHiddenSort = false,
    setActiveAttributeId,
    activeAttributeId,
    type = "series",
    activeModelId,
}) => {
    const { activeGroupId, activeSeriesId, activeProducerId } =
        useNameGenerationSettingsStore()

    const { data } = useQuery({
        queryKey: [activeGroupId, activeSeriesId, type, activeModelId],
        enabled: !!activeSeriesId && !!activeGroupId,
        queryFn: async () => {
            if (type === "series") {
                const attributeProducer = (await api.getTyped(
                    "attribute/group_producer_attributes_linking_details",
                    {
                        group: activeGroupId,
                        producer: activeProducerId,
                    }
                )) as {
                    attribute: {
                        valuesList: Record<Uuid, string>
                        name: string
                        id: Uuid
                    }
                    sortChoosing?: number
                }[]

                const allAttributes = (
                    (await api.getTyped(
                        `v1/series/${activeSeriesId}/items-group/${activeGroupId}/attribute-links`
                    )) as (AttributeListItem & {
                        attributeConfigurable: boolean
                    })[]
                ).filter((item) => !!item.attributeConfigurable)

                const availableSortChoosing = allAttributes?.some(
                    (item) =>
                        item.sortChoosing !== null &&
                        item.sortChoosing !== undefined
                )

                return allAttributes.map((item, index) => {
                    const currentSortChoosingProducer = attributeProducer?.find(
                        (producer) =>
                            producer.attribute.id === item.attribute.id
                    )?.sortChoosing

                    const currentItem = {
                        ...item,
                        sortChoosingProducer:
                            currentSortChoosingProducer || index + 1000,
                    }

                    if (availableSortChoosing) {
                        const currentValue = item.sortChoosing

                        const isEmpty =
                            currentValue?.toString().trim() === "" ||
                            currentValue === null

                        return {
                            ...currentItem,
                            error: {
                                isError: isEmpty,
                                message:
                                    "Не заполнена очередность у свойств на серии",
                            },
                        }
                    }

                    return currentItem
                })
            }

            return api.getTyped("model/get-attribute-links", {
                series: activeSeriesId,
                model: activeModelId,
            })
        },
        initialData: [],
    }) as { data: Row[] }

    const [rows, setRows] = useState<Row[] | []>(data)
    const [isInMask, setIsInMask] = useState(false)
    const [isSortForNumber, setIsSortForNumber] = useState(false)
    const [typeSort, setTypeSort] = useState(0)
    const [searchCondition, setSearchCondition] = useState("")

    useEffect(() => {
        setRows(
            data.map((item) => ({
                ...item,
                disabled: false,
                ...(isHiddenSort ? null : { sort: item.sortChoosing }),
            }))
        )
    }, [data])

    useEffect(() => {
        setActiveAttributeId("")
    }, [activeSeriesId])

    const filterPropertiesInMask = () => {
        const currentValue = !isInMask
        setIsInMask(currentValue)

        setRows((state) =>
            filterArray(state, searchCondition, currentValue, availableSort)
        )
    }

    const onSearch = (val: string) => {
        if (isSortForNumber) {
            setIsSortForNumber(false)
        }

        setSearchCondition(val)
        setRows((state) => {
            const updatedRows = filterArray(state, val, isInMask, availableSort)

            const groupedRows = updatedRows?.reduce(
                (acc, item) => {
                    if (item.disabled) {
                        acc.disabled.push(item)
                    } else {
                        acc.notDisabled.push(item)
                    }
                    return acc
                },
                { notDisabled: [] as Row[], disabled: [] as Row[] }
            )

            return [...groupedRows.notDisabled, ...groupedRows.disabled]
        })
    }

    const availableSort = rows?.some(
        (item) => item.sort !== null && item.sort !== undefined
    )

    const maxCountSymbols = rows.reduce((max, item) => {
        const currentSort = availableSort
            ? item.sort
            : item.sortChoosingProducer

        const length = currentSort ? String(currentSort).length : 0
        return Math.max(max, length)
    }, 0)

    const defaultColumns: TableCol<Row>[] = [
        type === "series" && {
            columnName() {
                return (
                    <Flex.Row
                        onClick={() => {
                            const currentTypeSort =
                                typeSort + 1 === 3 ? 0 : typeSort + 1
                            const comparator = new Intl.Collator()

                            setTypeSort(currentTypeSort)

                            setRows((state) =>
                                state.sort((rowA, rowB) => {
                                    if (!!currentTypeSort) {
                                        const sortA = Number(
                                            availableSort
                                                ? rowA.sort
                                                : rowA.sortChoosingProducer
                                        )
                                        const sortB = Number(
                                            availableSort
                                                ? rowB.sort
                                                : rowB.sortChoosingProducer
                                        )

                                        return currentTypeSort === 1
                                            ? sortA - sortB
                                            : sortB - sortA
                                    }

                                    return comparator.compare(
                                        rowA.attribute.name,
                                        rowB.attribute.name
                                    )
                                })
                            )
                        }}
                    >
                        <span>№</span>{" "}
                        <ColumnSorterSwitcher
                            typeSort={Sort[typeSort] as keyof typeof Sort}
                        />
                    </Flex.Row>
                )
            },
            render(v: Row) {
                return (
                    <PropertiesInput<Row>
                        id={v.id}
                        value={availableSort ? v.sort : v.sortChoosingProducer}
                        attribute="sort"
                        setValue={setRows}
                        error={v.error}
                        sendValue={(sort) => {
                            api.post(
                                `v1/group/${activeGroupId}/producer/${activeProducerId}/series/${activeSeriesId}/attribute/${v.attribute.id}/link/choosing_sort/${sort}`
                            )
                        }}
                    />
                )
            },

            className(row?: Row) {
                return row?.disabled ? styles.disabled : ""
            },
        },
        {
            columnName: "Свойства",
            render({ attribute }: Row) {
                return attribute.name
            },
            className(row?: Row) {
                return row?.disabled ? styles.disabled : ""
            },
        },
    ].filter((item): item is Exclude<typeof item, false> => !!item)

    return (
        <Flex.Col
            className={type === "series" ? `row-count-${maxCountSymbols}` : ""}
        >
            <UTable
                data={rows}
                search
                maxRowsVisible={10}
                onRowClick={({ attribute }, e) => {
                    setActiveAttributeId(attribute.id)
                }}
                isRowActive={({ attribute }) =>
                    activeAttributeId === attribute.id
                }
                isDisableFocusInput
                columns={defaultColumns}
                children={
                    <div>
                        <Button onClick={filterPropertiesInMask} type="link">
                            {isInMask ? "Показать" : "Скрыть"} свойства не в
                            маске
                        </Button>
                    </div>
                }
                onSearch={onSearch}
            />
        </Flex.Col>
    )
}

export default PropertiesTable
