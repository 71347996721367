import { Spin, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { getFieldsValues } from "helpers/getFieldValue"
import { useCallback, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Uuid } from "types"
import { ListingResponse, Pagination } from "types/api"
import StandartKitLogItem from "./StandartKitLogItem"

export type StandardLogItem = {
    id: Uuid
    createdAt: string
    log: string[]
}

export default function StandartKits({
    lastGeneretedKitId,
}: {
    lastGeneretedKitId: boolean
}) {
    const [logItems, setLogItems] = useState<StandardLogItem[]>([])
    const [pagination, setPagination] = useState<Pagination>()

    const [visibleItem, setVisibleItem] = useState<Uuid | null>(null)

    const fetchData = useCallback(async () => {
        api.getTyped<ListingResponse>("listing/standard-kit-generation-log", {
            count_on_page: pagination?.pageSize ?? 20,
            page: pagination ? pagination.currentPage + 1 : 1,
            method: "desc",
            sort: "createdAt",
        }).then((response) => {
            setLogItems((prev) => [
                ...prev,
                ...response.entities.map((el) =>
                    getFieldsValues<StandardLogItem>(
                        el.fields,
                        "id",
                        "createdAt",
                        "log"
                    )
                ),
            ])
            setPagination(response.pagination)
        })
    }, [api, pagination, setLogItems])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Flex.Col fullWidth gap={10}>
            <Typography.Title level={5}>
                История генераций комплектов
            </Typography.Title>
            <InfiniteScroll
                className="fw"
                style={{
                    width: "100%",
                    display: "flex",
                    overflow: "hidden",
                    flexDirection: "column",
                }}
                dataLength={logItems?.length}
                next={fetchData}
                loader={<Spin />}
                endMessage={<Typography.Text>Всё загружено</Typography.Text>}
                hasMore={
                    pagination
                        ? pagination.currentPage < pagination.allPages
                        : true
                }
            >
                {logItems.map((el) => (
                    <StandartKitLogItem
                        key={el.id}
                        data={el}
                        visibleItem={visibleItem}
                        toggleItem={(v) =>
                            setVisibleItem((prev) => (prev === v ? null : v))
                        }
                    />
                ))}
            </InfiniteScroll>
        </Flex.Col>
    )
}
