import { Button, Col, Row } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import LoadingOverlayWrapper from "react-loading-overlay-ts"
import { Uuid } from "types"
import api from "../../../helpers/api"
import AllToAllModal, { SaveAllToAllData } from "./AllToAllModal"
import { AttributesChooser } from "./AttributesChooser"
import { AttributesValuesChooser } from "./AttributesValuesChooser"
import { AttributeGroupProducerSeriesLink } from "./index"

interface AttributesChooserProps {
    groupId: Uuid
    producerId: Uuid
    seriesId: Uuid
    modelId: Uuid
    attributes: AttributeGroupProducerSeriesLink[]
    loadingAttributes: boolean
}

interface AttributeToAttributePair {
    attribute: Uuid
    value: string
}

export const AttributesContainersWrapper: React.FC<AttributesChooserProps> = (
    props
) => {
    const [activeAttribute, setActiveAttribute] = useState<Uuid | undefined>()
    const [activeValue, setActiveValue] = useState<Uuid | undefined>()
    const [matchAllData, setMatchAllData] = useState<SaveAllToAllData | null>(
        null
    )

    const [matchAllAtributeValues, setMathAllAttributeValues] = useState<
        Uuid[]
    >([])

    const [activeOppositeAttribute, setActiveOppositeAttribute] = useState<
        Uuid | undefined
    >()

    const [currentPairLinks, setCurrentPairLinks] = useState<
        AttributeToAttributePair[]
    >([])

    const fetchMutualComatibility = useCallback(() => {
        api.getTyped<AttributeToAttributePair[]>(
            "attribute/mutual-compatibility/list-compatible-values-by-attribute",
            {
                group: props.groupId,
                model: props.modelId,
                attribute: activeAttribute,
                attributeValue: activeValue,
            }
        ).then((links) => {
            setCurrentPairLinks(links)
            setMatchAllData(null)
        })
    }, [
        props.groupId,
        props.modelId,
        activeAttribute,
        activeValue,
        setCurrentPairLinks,
        setMatchAllData,
        api,
    ])

    useEffect(() => {
        fetchMutualComatibility()
    }, [
        activeAttribute,
        activeValue,
        props.groupId,
        props.modelId,
        fetchMutualComatibility,
    ])

    const handleBulkAttributeValues = (state: boolean, attribute?: Uuid) => {
        const activeOppositeAttribute_ = attribute ?? activeOppositeAttribute!
        const oppositeValues_ =
            props.attributes.find(
                (v) => v.attribute.id === activeOppositeAttribute_
            )?.datatypeOptions ?? {}
        const checkedOppositeValues_ = currentPairLinks
            .filter(({ attribute }) => attribute === activeOppositeAttribute_)
            .map(({ value }) => value)

        if (state) {
            api.post(
                "v1/attribute/bulk-link/mutual-compatibility",
                {},
                {
                    group: props.groupId,
                    model: props.modelId,
                    attribute: activeAttribute,
                    compatibleAttribute: activeOppositeAttribute_,
                    attributeValue: activeValue,
                    compatibleAttributeValues: Object.keys(oppositeValues_),
                }
            )

            return setCurrentPairLinks([
                ...currentPairLinks.filter(
                    ({ attribute }) => attribute !== activeOppositeAttribute_
                ),
                ...Object.keys(oppositeValues_).map((value) => ({
                    attribute: activeOppositeAttribute_,
                    value,
                })),
            ])
        }

        api.post(
            "v1/attribute/bulk-unlink/mutual-compatibility",
            {},
            {
                group: props.groupId,
                model: props.modelId,
                attribute: activeAttribute,
                compatibleAttribute: activeOppositeAttribute_,
                attributeValue: activeValue,
                compatibleAttributeValues: checkedOppositeValues_,
            }
        )

        return setCurrentPairLinks(
            currentPairLinks.filter(
                (pair) => pair.attribute !== activeOppositeAttribute_
            )
        )
    }

    const handleToggleAttributeValue = (id: Uuid, state: boolean) => {
        if (!activeOppositeAttribute) return

        api.post(
            `attribute/mutual-compatibility/${state ? "add" : "remove"}`,
            {},
            {
                group: props.groupId,
                model: props.modelId,
                attribute: activeAttribute,
                compatibleAttribute: activeOppositeAttribute,
                attributeValue: activeValue,
                compatibleAttributeValue: id,
            }
        )

        if (state) {
            if (
                currentPairLinks.some(
                    (v) =>
                        v.attribute === activeOppositeAttribute &&
                        v.value === id
                )
            )
                return

            return setCurrentPairLinks([
                ...currentPairLinks,
                {
                    attribute: activeOppositeAttribute,
                    value: id,
                },
            ])
        }

        setCurrentPairLinks(
            currentPairLinks.filter((pair) => {
                return (
                    pair.attribute !== activeOppositeAttribute ||
                    pair.value !== id
                )
            })
        )
    }

    const oppositeValues = useMemo(() => {
        return (
            props.attributes.find(
                (v) => v.attribute.id === activeOppositeAttribute
            )?.datatypeOptions ?? {}
        )
    }, [activeOppositeAttribute, props.attributes])

    const checkedOppositeValues = useMemo(() => {
        return currentPairLinks
            .filter(({ attribute }) => attribute === activeOppositeAttribute)
            .map(({ value }) => value)
    }, [activeOppositeAttribute, currentPairLinks])

    const saveAllToAllData: SaveAllToAllData | null = useMemo(() => {
        const attribute = props.attributes.find(
            (el) => el.attribute.id === activeAttribute
        )
        if (
            !props.groupId ||
            !props.modelId ||
            !activeAttribute ||
            !matchAllAtributeValues.length ||
            !attribute
        )
            return null
        const data: SaveAllToAllData = {
            model: props.modelId,
            group: props.groupId,
            attribute: activeAttribute,
            attributeValues: matchAllAtributeValues,
            attributeValuesNames: matchAllAtributeValues.map((el) => {
                return attribute.attribute.valuesList[el] ?? ""
            }),
            onClose: () => {
                fetchMutualComatibility()
            },
        }
        return data
    }, [
        matchAllAtributeValues,
        props.groupId,
        props.modelId,
        activeAttribute,
        props.attributes,
        fetchMutualComatibility,
    ])

    return (
        <LoadingOverlayWrapper
            active={props.loadingAttributes}
            styles={{
                overlay: {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    textAlign: "center",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    zIndex: "800",
                    transition: "opacity 500ms ease-in",
                    opacity: 1,
                },
            }}
            spinner={
                <div
                    className="spinner-border text-primary"
                    role="status"
                ></div>
            }
        >
            <Row gutter={[10, 10]} className="mb-3">
                <Col span={24} sm={{ span: 12 }} xxl={{ span: 8 }}>
                    <AttributesChooser
                        attributes={props.attributes}
                        onChange={(id) => {
                            setActiveAttribute(id)
                            setActiveValue(undefined)
                            setActiveOppositeAttribute(undefined)
                        }}
                        active={activeAttribute}
                        withCheckboxes={false}
                    />
                </Col>

                <Col
                    span={24}
                    sm={{ span: 12 }}
                    xxl={{ span: 8 }}
                    style={{ opacity: activeAttribute ? 1 : 0 }}
                >
                    <AttributesValuesChooser
                        withCheckboxes={false}
                        onChange={(id) => {
                            setActiveValue(id)
                            setActiveOppositeAttribute(undefined)
                        }}
                        onChangeAllToAll={setMathAllAttributeValues}
                        allToAllValues={matchAllAtributeValues}
                        active={activeValue}
                        values={
                            props.attributes.find(
                                (v) => v.attribute.id === activeAttribute
                            )?.datatypeOptions ?? {}
                        }
                    />
                    <div className="fw" style={{ height: 32 }}>
                        {!!matchAllAtributeValues.length && (
                            <Button
                                disabled={!saveAllToAllData}
                                type="primary"
                                onClick={() =>
                                    setMatchAllData(saveAllToAllData)
                                }
                            >
                                Все ко всему
                            </Button>
                        )}
                    </div>
                </Col>

                <Col
                    span={24}
                    sm={{ span: 12 }}
                    xxl={{ span: 8 }}
                    style={{ opacity: activeValue ? 1 : 0 }}
                >
                    <AttributesChooser
                        attributes={props.attributes.filter(
                            (v) => v.attribute.id !== activeAttribute
                        )}
                        onChange={(id) => {
                            setActiveOppositeAttribute(id)
                        }}
                        active={activeOppositeAttribute}
                        withCheckboxes={true}
                        onBulk={(state, attribute) =>
                            handleBulkAttributeValues(state, attribute)
                        }
                        checked={currentPairLinks.map((v) => v.attribute)}
                        checkedValues={currentPairLinks.map((v) => v.value)}
                    />
                </Col>
                <div
                    className="col"
                    style={{
                        opacity:
                            !activeOppositeAttribute || !activeValue ? 0 : 1,
                    }}
                >
                    <AttributesValuesChooser
                        withCheckboxes={true}
                        onUseCheckbox={handleToggleAttributeValue}
                        checked={checkedOppositeValues}
                        onBulk={handleBulkAttributeValues}
                        values={oppositeValues}
                    />
                </div>
            </Row>
            <AllToAllModal data={matchAllData} />
        </LoadingOverlayWrapper>
    )
}
