import { usePageData } from "hooks/usePageData"
import React, { ChangeEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Uuid } from "types"
import api from "../../../helpers/api"
import { useNotifications } from "../../../hooks/useNotifications"
import { SET_INLINE_DATA } from "../../../store/inlineData/actions"

const SUPER_ADMIN_ID = "0f6c4828-d576-4988-86bc-d089ba33d247"
interface InputProps {
    id: Uuid
    name: string
    value: any
    type: string
    listing: { [key: string]: any }
}

export const Checkbox: React.FC<InputProps> = ({
    id,
    name,
    listing = {},
    type,
}) => {
    const dispatch = useDispatch()

    const { showNotification } = useNotifications()

    const { slug } = usePageData()

    const entities =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.entities
        ) || []
    const isChecked = entities
        .find((item: any) => item.id === id)
        .fields.find((item: any) => item.name === name).value

    const [currentValue, setValue] = React.useState(isChecked)

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist()
        event.target.blur()
        api.put(
            `listing/update/${slug}/${id}`,
            {},
            { [name]: event.target.checked }
        )
            .then(() => {
                dispatch(SET_INLINE_DATA({ [name]: event.target.checked }))
                showNotification({
                    type: "success",
                    message: "Успешно обновлено!",
                })
            })
            .catch(() => {
                showNotification({
                    type: "danger",
                    message: "Ошибка обновления!",
                })
            })
    }

    React.useEffect(() => {
        setValue(isChecked)
    }, [isChecked])

    return (
        <input
            name={name + id}
            checked={currentValue}
            type={type}
            disabled={
                listing.isReadOnly || (slug === "user" && id === SUPER_ADMIN_ID)
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                dispatch(SET_INLINE_DATA({ [name]: event.target.checked }))
                setValue(event.target.checked)
                onChangeHandler(event)
            }}
        />
    )
}
