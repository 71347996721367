import { useMutation } from "@tanstack/react-query"
import React, { useMemo, useState } from "react"
import toast from "react-hot-toast"
import Sticky from "react-sticky-el"
import { Uuid } from "types"
import api from "../../../helpers/api"
import { ConfiguratorPartAttributes } from "./ConfiguratorPartAttributes"
import { ConfiguratorPartNaming } from "./ConfiguratorPartNaming"
import { ConfiguratorParams } from "./index"

export const ConfiguratorContainersWrapper: React.FC<{
    iframe: boolean
    params: ConfiguratorParams
    filledAttributes: {
        [attributeId: Uuid]: string
    }
    setFilledAttributes: (v: { [attributeId: Uuid]: string }) => void
}> = ({ params, filledAttributes, setFilledAttributes }) => {
    const [isFormComplete, setIsFormComplete] = useState(false)
    const [inStock] = useState(false)
    const [lastNomenclature, setLastNomenclature] = useState("")

    useMemo(() => {
        setLastNomenclature("")
    }, [filledAttributes])

    useMemo(() => {
        if (!Object.keys(filledAttributes).length) {
            return
        }

        setFilledAttributes({})
    }, [params])

    const createNomenclature = useMutation<
        { id: Uuid },
        unknown,
        {
            name: string
            accountingName: string
            itemsGroup?: Uuid
            producer?: Uuid
            series?: Uuid
            model?: Uuid
            isInStock: boolean
            values: Record<Uuid, string>
        }
    >((payload) => {
        return api
            .post<any, { id: Uuid }>("v1/nomenclature/create", {}, payload)
            .then((v) => v.data)
    })

    const handleCreateNomenclature = async () => {
        setIsFormComplete(false)
        await toast.promise(
            createNomenclature.mutateAsync(
                {
                    name: "",
                    accountingName: "",
                    itemsGroup: params.group,
                    producer: params.producer,
                    series: params.series,
                    model: params.model?.value,
                    isInStock: inStock,
                    values: filledAttributes,
                },
                {
                    onError: () => setIsFormComplete(true),
                    onSuccess: (data) => {
                        setFilledAttributes({})
                        setLastNomenclature(data.id)
                        const opener = window.opener || window.parent
                        opener.postMessage(JSON.stringify(data), "*")
                    },
                }
            ),
            {
                loading: "Cохранение...",
                success: <b>Номенклатура успешно создана</b>,
                error: ({ response: { data: serverTextResponse } }) => {
                    const error =
                        serverTextResponse ??
                        "Не удалось создать номенклатуру, неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 7500 }
        )
    }

    return (
        <>
            <Sticky

            // stickyStyle={{ marginTop: 25 }}
            // topOffset={-25}
            >
                <ConfiguratorPartNaming
                    params={params}
                    filled={filledAttributes}
                />
            </Sticky>

            <ConfiguratorPartAttributes
                onIsFormCompleted={(v) => setIsFormComplete(v)}
                isFormCompleted={isFormComplete}
                params={params}
            />

            <div className="d-flex align-items-center">
                <button
                    className="btn btn-primary"
                    onClick={handleCreateNomenclature}
                    disabled={!isFormComplete}
                >
                    Создать номенклатурную позицию
                </button>
                {/* {!iframe && (
                    <div
                        className="form-check form-switch ms-3"
                        style={{ marginRight: "auto" }}
                    >
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={inStock}
                            onChange={(e) => setInStock(e.target.checked)}
                            disabled={!isFormComplete}
                        />
                        <label className="form-check-label">
                            Складская единица
                        </label>
                    </div>
                )} */}
                {lastNomenclature !== "" && (
                    <a
                        className="btn btn-link"
                        href={`/configuration/nomenclature/update-${lastNomenclature}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                    >
                        Просмотреть созданную номенклатуру
                    </a>
                )}
            </div>
        </>
    )
}
