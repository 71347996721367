import { getUrlFilters } from "utils/url"
import api from "../../helpers/api"
import { SAVE_FOR_TEMP } from "../bufferTemp/actions"
import { SET_COMPARE_TYPE } from "../compareType/actions"
import { SET_CURRENT_VALUES } from "../fields/actions"
/** SET_USER_SETTINGS */
export const SET_USER_SETTINGS = (payload: any) => ({
    type: "SET_USER_SETTINGS",
    payload,
})

/** THUNK */
export const getUserSettings: any = (
    route: string,
    apiPath: string,
    search: string
) => {
    return async (dispatch: any, getState: any) => {
        const currentState = getState()

        const headers = currentState?.listing.headers ?? []

        api.get<any>(route)
            .then((result) => {
                if (result.data.showedColumns.length === 0) {
                    dispatch(SET_USER_SETTINGS(result.data))
                    dispatch(SET_USER_SETTINGS({ showedColumns: [...headers] }))
                } else {
                    dispatch(SET_USER_SETTINGS(result.data))
                }
                dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "" }))
                return result.data
            })
            .then((data) => {
                if (search.length > 0) {
                    const parsedSearch = window.location.search
                        .replace(/^\?/, "")
                        .split("&")
                        .map((keyEqualValue) => {
                            const [key, value] = keyEqualValue.split("=")
                            return key + "=" + value
                        })

                    const decodedSearchParams = new URLSearchParams(
                        parsedSearch.join("&")
                    )
                    let cat = 0

                    dispatch(
                        SET_USER_SETTINGS({
                            sortedColumns: {
                                columnName:
                                    decodedSearchParams.get("sort") ?? "id",
                                sortMethod:
                                    decodedSearchParams.get("method") ?? "asc",
                            },
                        })
                    )

                    if (decodedSearchParams.get("cat")) {
                        cat = Number.parseInt(decodedSearchParams.get("cat")!)
                        dispatch(SET_CURRENT_VALUES({ category: cat }))
                    } else {
                        cat = 0
                    }

                    const { filter, compareTypes, isEmpty, filtersString } =
                        getUrlFilters(window.location.search)

                    if (!isEmpty) {
                        dispatch(SET_COMPARE_TYPE(compareTypes))
                        dispatch(SET_CURRENT_VALUES(filter, "filter"))
                        dispatch(SAVE_FOR_TEMP({ filter: filtersString }))
                    }

                    if (search.includes("&attributeFilter=")) {
                        const decodedQuery =
                            decodedSearchParams.get("attributeFilter")

                        const inputs = decodeURI(search)
                            .split(";")
                            .reduce((acc: any, inputObj: any) => {
                                dispatch(
                                    SET_COMPARE_TYPE({
                                        [inputObj.split("--")[0]]:
                                            inputObj.split("--")[1],
                                    })
                                )
                                acc[inputObj.split("--")[0]] = inputObj
                                    .split("--")[2]
                                    .split("|")
                                return acc
                            }, {})

                        dispatch(SET_CURRENT_VALUES(inputs, "attributeFilter"))
                        dispatch(SAVE_FOR_TEMP({ attributes: decodedQuery }))
                    }
                }
                return data
            })
            .catch(() => {})
    }
}
