import { useQuery } from "@tanstack/react-query"
import { Button, Checkbox, Spin } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useNameGenerationSettingsStore } from "pages/NameGenerationSettings/NameGenerationSettings.store"
import { useEffect, useState } from "react"
import { Uuid } from "types"
import MasksInput from "./MasksInput"

interface MasksTableProps {
    activeAttributeId?: Uuid
    type?: "model" | "series"
    activeModelId?: Uuid
}

enum Attribute {
    "name" = "alt_names",
    "accounting" = "alt_accounting_names",
}

type Rows = {
    id: Uuid
    label: string
    checked: boolean
    name?: string
    altName?: string
    error?: boolean
    producerGroupName?: string
    producerGroupAltName?: string
}

const MasksTable = ({
    activeAttributeId,
    type = "series",
    activeModelId,
}: MasksTableProps) => {
    const { activeSeriesId, activeGroupId, activeProducerId } =
        useNameGenerationSettingsStore()
    const { data, isFetching } = useQuery<Rows[]>({
        queryKey: [activeSeriesId, activeGroupId, activeAttributeId, type],
        enabled: !!activeSeriesId && !!activeGroupId && !!activeAttributeId,

        queryFn: async () => {
            const comparator = new Intl.Collator()
            const names = await api.getTyped<Record<
                Uuid,
                string | null
            > | null>(
                `v1/${
                    type === "series"
                        ? `group/${activeGroupId}/producer/${activeProducerId}/series/${activeSeriesId}`
                        : `model/${activeModelId}`
                }/attribute/${activeAttributeId}/link/alt_names`
            )

            const producerGroupNames = await api.getTyped<Record<Uuid, string>>(
                "attribute/group_producer_attributes_list_alt_names",
                {
                    group: activeGroupId,
                    producer: activeProducerId,
                    attribute: activeAttributeId,
                }
            )

            const altNames = await api.getTyped<Record<
                Uuid,
                string | null
            > | null>(
                `v1/${
                    type === "series"
                        ? `group/${activeGroupId}/producer/${activeProducerId}/series/${activeSeriesId}`
                        : `model/${activeModelId}`
                }/attribute/${activeAttributeId}/link/alt_accounting_names`
            )

            const producerGroupAltNames = await api.getTyped<
                Record<Uuid, string>
            >(
                `attribute/group_producer_attributes_list_alt_accounting_names?group=${activeGroupId}&producer=${activeProducerId}&attribute=${activeAttributeId}`
            )

            const res = await api.getTyped(
                type === "series"
                    ? `v1/series/${activeSeriesId}/items-group/${activeGroupId}/attribute/${activeAttributeId}/link`
                    : "attribute/list-by-series-and-model",
                {
                    series: activeSeriesId,
                    model: activeModelId,
                }
            )

            const currentRes =
                type === "series"
                    ? res
                    : res.find(
                          ({ attribute }: { attribute: { id: Uuid } }) =>
                              attribute.id === activeAttributeId
                      )

            return (
                currentRes?.configurableAttributeValues
                    .map((item: Uuid) => ({
                        id: item,
                        label: currentRes.attribute.valuesList[item],
                        checked: false,
                        name: names?.[item],
                        producerGroupName: producerGroupNames?.[item],
                        altName: altNames?.[item],
                        producerGroupAltName: producerGroupAltNames?.[item],
                    }))
                    .sort((a: Rows, b: Rows) =>
                        comparator.compare(a.label, b.label)
                    ) ?? []
            )
        },
        initialData: [],
    })

    const [rows, setRows] = useState<Rows[]>([])
    const [defaultRows, setDefaultRows] = useState<Rows[]>([])

    useEffect(() => {
        if (!!data) {
            setRows(data)
            setDefaultRows(data)
        }
    }, [data])

    const onSearch = (val: string) =>
        setRows(
            defaultRows?.filter((item) => {
                const regex = new RegExp(val, "i")
                return (
                    regex.test(item.label) ||
                    regex.test(item?.name ?? "") ||
                    regex.test(item?.altName ?? "")
                )
            })
        )

    const changeNameInInput = ({
        typeName,
        values,
    }: {
        typeName: "name" | "accounting"
        values?: Rows[]
    }) => {
        api.post(
            `v1/${
                type === "series"
                    ? `group/${activeGroupId}/producer/${activeProducerId}/series/${activeSeriesId}`
                    : `model/${activeModelId}`
            }/attribute/${activeAttributeId}/link/${Attribute[typeName]}`,
            {},
            {
                [typeName === "name" ? "altNames" : "altAccountingNames"]:
                    Object.fromEntries(
                        (values ?? rows).map((curVal) => [
                            curVal.id,
                            curVal[typeName === "name" ? "name" : "altName"],
                        ])
                    ),
            }
        )
    }

    const copyValues = ({ typeName }: { typeName: "name" | "accounting" }) => {
        const currentValues = rows.map((item) => {
            const key = typeName === "name" ? "name" : "altName"
            const newValue = item.checked
                ? key === "name"
                    ? item.label
                    : item.name
                : item[key]

            return {
                ...item,
                [key]: newValue,
            }
        })

        changeNameInInput({ typeName, values: currentValues })
        setRows(currentValues)
    }

    return (
        <Spin spinning={!!isFetching}>
            <UTable
                search
                data={rows}
                maxRowsVisible={10}
                columns={[
                    {
                        columnName() {
                            return (
                                <Checkbox
                                    indeterminate={
                                        !!rows?.find((item) => item.checked) &&
                                        !!rows?.find((item) => !item.checked)
                                    }
                                    onClick={() =>
                                        setRows((state) =>
                                            state.map((item) => ({
                                                ...item,
                                                checked: !!rows?.find(
                                                    (item) => !item.checked
                                                ),
                                            }))
                                        )
                                    }
                                    checked={
                                        !!rows.length &&
                                        !rows?.find((item) => !item.checked)
                                    }
                                />
                            )
                        },
                        render({ id, checked }) {
                            return (
                                <Checkbox
                                    checked={checked}
                                    onClick={() =>
                                        setRows((state) =>
                                            state.map((item) => ({
                                                ...item,
                                                checked:
                                                    item.id === id
                                                        ? !item.checked
                                                        : item.checked,
                                            }))
                                        )
                                    }
                                />
                            )
                        },
                    },
                    {
                        columnName: "Значение",
                        render({ label }) {
                            return label
                        },
                    },
                    {
                        columnName: "Пользовательское",
                        render({ name, producerGroupName, error = false, id }) {
                            return (
                                <MasksInput
                                    id={id}
                                    value={
                                        name !== null && name !== undefined
                                            ? name
                                            : producerGroupName
                                    }
                                    attribute={"name"}
                                    setValue={setRows}
                                    sendChange={() =>
                                        changeNameInInput({
                                            typeName: "name",
                                        })
                                    }
                                    error={error}
                                />
                            )
                        },
                    },
                    {
                        columnName: "Бухгалтерское",
                        render({
                            altName,
                            producerGroupAltName,
                            error = false,
                            id,
                        }) {
                            return (
                                <MasksInput
                                    id={id}
                                    value={altName ?? producerGroupAltName}
                                    attribute={"altName"}
                                    setValue={setRows}
                                    sendChange={() =>
                                        changeNameInInput({
                                            typeName: "accounting",
                                        })
                                    }
                                    error={error}
                                />
                            )
                        },
                    },
                ]}
                children={
                    <Flex.Row justify="end" fullWidth>
                        <Button
                            type="link"
                            onClick={() => copyValues({ typeName: "name" })}
                            disabled={!rows?.some((item) => item.checked)}
                        >
                            Скопировать все из значений
                        </Button>
                        <Button
                            type="link"
                            onClick={() =>
                                copyValues({ typeName: "accounting" })
                            }
                            disabled={!rows?.some((item) => item.checked)}
                        >
                            Скопировать все из пользов-кого
                        </Button>
                    </Flex.Row>
                }
                onSearch={onSearch}
            />
        </Spin>
    )
}

export default MasksTable
