import {
    DetailAdditionalData,
    DetailAdditionalFields,
} from "components/DatailAdditional"
import NamingSelect from "components/NamingSelect"
import { useDetailData } from "hooks/useDetailData"
import { usePageData } from "hooks/usePageData"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ContentForm from "../components/ContentForm"
import { SaveMenu } from "../components/UI/SaveMenu"
import { isEmptyObject } from "../helpers/isEmptyObject"
import {
    getCurrentValues,
    getFieldsFromServer,
    INIT_STATE_GROUP,
} from "../store/fields/actions"

export const Detail: React.FC = () => {
    const fields: object[] = useSelector(
        (state: { [key: string]: any }) => state.fields.initFields
    )
    const currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues?.["main"]
    )
    const attributeName: string = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.name
    )

    const { slug } = usePageData()

    const { showNamingSelect } = useDetailData()

    const dispatch = useDispatch()
    const routerParams: { [key: string]: string } = useParams()

    const title: string = routerParams.id ? "Редактирование" : "Создание"

    const onModifySendData = React.useCallback((data: any) => {
        for (const code in data) {
            // * Clear ID
            if (code === "id") {
                delete data[code]
            }

            if (typeof data[code] === "object" && data[code]?.id) {
                data[code] = data[code].id
            }
        }

        if (data.isPublished === null) {
            data.isPublished = false
        }

        if (data["isActive"] !== true && data["isActive"] !== false) {
            data["isActive"] = true
        }

        if (data["isRequired"] !== true && data["isRequired"] !== false) {
            data["isRequired"] = true
        }
    }, [])

    useEffect(() => {
        document.title = title
        dispatch(INIT_STATE_GROUP({ main: {} }))

        dispatch(getFieldsFromServer(`reference/${slug}/fields`))
        dispatch(getCurrentValues(`reference/${slug}`, routerParams.id, "main"))
    }, [])

    const v1Url = useMemo(() => {
        if (["model"].includes(slug)) {
            return {
                post: `v1/${slug}/create`,
                put: `v1/${slug}/update`,
            }
        }
    }, [slug])

    return (
        <>
            <h1 className="h3 mb-3">
                {title}
                {routerParams.id
                    ? ` "${
                          (slug === "uploaded-attribute" && routerParams.id) ||
                          attributeName ||
                          "..."
                      }"`
                    : null}
            </h1>
            <SaveMenu
                url={`reference/${slug}`}
                v1Url={v1Url}
                onModify={onModifySendData}
            />
            <div className="control">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="edit-settings-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#edit-settings"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            Настройка
                        </button>
                    </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    <h5>Основное</h5>
                                    {isEmptyObject(currentValues) ? (
                                        <div>Загрузка...</div>
                                    ) : (
                                        <ContentForm
                                            isCreate={!routerParams.id}
                                            group={"main"}
                                            fields={fields}
                                        />
                                    )}
                                    {showNamingSelect === "series" && (
                                        <NamingSelect />
                                    )}
                                    <DetailAdditionalFields
                                        data={currentValues}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DetailAdditionalData data={currentValues} />
            <SaveMenu
                v1Url={v1Url}
                url={`reference/${slug}`}
                onModify={onModifySendData}
            />
        </>
    )
}
