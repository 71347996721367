import { Col, Row } from "antd"
import ApiSortableMultiSelect from "components/Selects/newSelects/ApiSortableMultiSelect"
import { ReactNode } from "react"
import { Uuid } from "types"

interface IValue {
    value: string
    label: string
    sort: number
}

export default function AccountingSelectSeries({
    options,
    children,
    value,
    onChange,
    defaultValue = [],
    type = "series",
}: {
    options: IValue[]
    children?: ReactNode
    value: IValue[]
    onChange: (val: IValue[]) => void
    defaultValue?: IValue[]
    type?: "model" | "series"
}) {
    return (
        <Row gutter={[15, 10]}>
            <Col span={24}>
                <Row gutter={[15, 0]}>
                    <Col md={24} xl={6}>
                        Бухгалтерское
                    </Col>
                    <Col md={24} xl={18}>
                        <ApiSortableMultiSelect
                            options={options}
                            value={value}
                            onChange={onChange}
                            defaultValue={defaultValue}
                            urls={{
                                add: () => `v1/${type}/links/accounting-naming`,
                                remove: () =>
                                    `v1/${type}/links/accounting-naming`,
                                getAddParams(list) {
                                    return list.reduce((acc, next, i) => {
                                        return {
                                            ...acc,
                                            [next.value]: i + 1,
                                        }
                                    }, {} as Record<Uuid, number | null>)
                                },
                                getRemoveParams(list, prop) {
                                    if (!list.length) {
                                        return {
                                            [prop as any]: null,
                                        }
                                    }
                                    return list.reduce((acc, next, i) => {
                                        return {
                                            ...acc,
                                            [next.value]: i + 1,
                                            [prop as any]: null,
                                        }
                                    }, {} as Record<Uuid, number | null>)
                                },
                                remodeMethod: "post",
                                addMethod: "post",
                            }}
                        />
                        {children}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
