import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import api from "../../helpers/api"
import { useNotifications } from "../../hooks/useNotifications"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const checkMimeType = (mimeType: string) => {
    return (
        mimeType === "image/png" ||
        mimeType === "image/jpeg" ||
        mimeType === "image/jpg" ||
        mimeType === "image/gif"
    )
}

const InputFile: React.FC<InputProps> = (props) => {
    const { propertyContainer, textError, group } = props

    const state = useSelector((state: { [key: string]: any }) => state)
    const cover =
        state.fields.currentValues[group]?.[propertyContainer.getName()]

    const { showNotification } = useNotifications()

    const [showCover, setShowCover] = useState<boolean>(false)

    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    const imageSrc = cover
        ? cover.public_link
        : "https://via.placeholder.com/300x200"

    const sendFile = (file: any) => {
        const fileLimit: number = 20_971_520 // 20Mb

        if (file.size >= fileLimit || !checkMimeType(file.type)) {
            showNotification({
                type: "danger",
                message: "Неверный формат файла, или файл слишком большой!",
            })

            return false
        }

        const formData: any = new FormData()
        formData.append("file", file)

        api.post("file/upload", {}, formData).then((response: any) => {
            dispatch(
                SET_CURRENT_VALUES(
                    {
                        [propertyContainer.getName()]: {
                            ...response.data,
                            type: "file",
                        },
                    },
                    group
                )
            )
        })
    }

    const showCoverHandler = (file?: any) => {
        if (checkMimeType(file)) {
            setShowCover(true)
        } else {
            setShowCover(false)
        }
    }

    const deleteImage = (event: Event) => {
        event.preventDefault()
        dispatch(
            SET_CURRENT_VALUES({ [propertyContainer.getName()]: null }, group)
        )
    }

    useEffect(() => {
        if (cover && checkMimeType(cover.mime_type)) {
            setShowCover(true)
        } else {
            setShowCover(false)
        }
    }, [cover])

    return (
        <>
            {showCover ? (
                <div className="row mb-3">
                    <label className="col-xl-3 col-form-label">Превью</label>
                    <div className="col-xl-9">
                        <div className="mb-3 position-relative d-inline-block">
                            <img
                                className="img-preview"
                                src={imageSrc}
                                alt=""
                            />
                            <a
                                href="!#"
                                className="photogallery__close"
                                onClick={(event: any) => deleteImage(event)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    className="feather feather-x"
                                >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="row mb-3">
                <label
                    htmlFor={propertyContainer.getId()}
                    className="col-xl-3 col-form-label"
                >
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <input
                        className={`form-control ${isInvalid}`}
                        type="file"
                        id={propertyContainer.getId()}
                        disabled={propertyContainer.get("readonly")}
                        onChange={(event: any) => {
                            dispatch(
                                SET_CURRENT_VALUES(
                                    {
                                        [propertyContainer.getName()]:
                                            event.target.files[0].name,
                                    },
                                    group
                                )
                            )
                            showCoverHandler(event.target.files[0])
                            sendFile(event.target.files[0])
                        }}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputFile
