import { Modal, Typography } from "antd"
import { SelectEntity } from "components/Selects/types"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { usePageId } from "hooks/usePageId"
import { useState } from "react"

export default function EntityCopyRegionModal({
    chosenRegion,
    entity,
    visible,
    itemsGroup,
    hide,
}: {
    chosenRegion: SelectEntity | null
    entity: "series" | "producer"
    visible: boolean
    itemsGroup: SelectEntity
    hide: () => void
}) {
    const [loading, setLoading] = useState(false)
    const modalName = `Копирование очередности свойств ${
        entity === "series" ? "серии" : "поставшика"
    } с другого региона`

    const { id } = usePageId()

    const { showNotification, handleApiError } = useNotifications()

    const mutate = async () => {
        setLoading(true)
        try {
            await api.post(
                `v1/economics/${entity}/${id}/order/copy`,
                {},
                {
                    itemsGroup: itemsGroup.value,
                    region: chosenRegion?.value,
                }
            )
            showNotification({
                type: "success",
                message: "Успешно",
            })
            return hide()
        } catch (error: any) {
            handleApiError(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            title={modalName}
            okText="Скопировать"
            cancelText="Отменить"
            open={visible}
            onOk={mutate}
            onCancel={hide}
            okButtonProps={{
                loading,
            }}
        >
            <Typography.Paragraph>
                Вы уверены что хотите скопировать очередность свойств из
                выбранного региона {chosenRegion?.label}
            </Typography.Paragraph>
        </Modal>
    )
}
