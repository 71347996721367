import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import api from "../../helpers/api"
import { isEmptyObject } from "../../helpers/isEmptyObject"
import { GateContainer } from "../../partials/GateContainer"
import Loader from "../../partials/Loader"
import { SAVE_FOR_TEMP } from "../../store/bufferTemp/actions"
import {
    DELETE_FILTER_VALUES,
    getFieldsFromServer,
    RESET_CURRENT_VALUES,
    SET_CURRENT_VALUES,
} from "../../store/fields/actions"
import {
    CLEAR_EMPTY_FILTER,
    CLEAR_LISTING,
    getListing,
    SET_EMPTY_FILTER,
    SET_LISTING,
} from "../../store/listing/actions"
import { CLEAR_LOADER, SET_LOADER } from "../../store/loader/actions"
import { AttributeComponentSelecter } from "./attributeFilter/AttributeComponentSelecter"
import { FilterComponentSelecter } from "./FilterComponentSelecter"

import { usePageData } from "hooks/usePageData"
import queryString from "query-string"
import { RouterSlugs } from "routes/routes"
import { Filter as FilterType } from "types"
import { listingsMap } from "../../helpers/recognisePage"
import { useValidateHelper } from "../../hooks/useValidateHelper"
import {
    RESET_COMPARE_TYPE,
    SET_COMPARE_TYPE,
} from "../../store/compareType/actions"
import { RESET_ERROR } from "../../store/errors/actions"
import FieldSettings from "./FieldSettings"
import InputEntities, {
    FilterInputEntity,
} from "./filterComponents/InputEntities"
import PresetControl from "./PresetControl"

interface FilterProps {
    visibility: boolean
}

export const Filter: React.FC<FilterProps> = ({ visibility }) => {
    const [attributeFields] = React.useState([])

    const dispatch = useDispatch()
    const validateInputs = useValidateHelper()
    const [activePreset, setActivePreset] = React.useState<number>(0)

    const { loaderShowed } = useSelector(
        (state: { [key: string]: any }) => state?.loaderShowed
    )
    const fields: object[] = useSelector(
        (state: { [key: string]: any }) => state.fields.initFields
    )
    const showedFields: any =
        useSelector(
            (state: { [key: string]: any }) => state.savedUserSettings.filters
        ) || []

    const currentValuesFilter = useSelector(
        (state: { [key: string]: any }) => state.fields?.currentValues.filter
    )
    const currentValuesAttributeFilter = useSelector(
        (state: { [key: string]: any }) =>
            state.fields?.currentValues.attributeFilter
    )
    const paginationSettings =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.pagination
        ) || {}
    const categoryID =
        useSelector(
            (state: { [key: string]: any }) =>
                state.fields.currentValues["main"]?.category
        ) || 1
    const compareType = useSelector(
        (state: { [key: string]: any }) => state.compareType
    )

    const sortedSettings =
        useSelector(
            (state: { [key: string]: any }) =>
                state.savedUserSettings?.sortedColumns
        ) || []
    const columnName = sortedSettings.columnName
    const sortMethod = sortedSettings.sortMethod

    const location: { [key: string]: any } = useLocation()
    const history: { [key: string]: any } = useHistory()
    const { slug, apiPath } = usePageData()

    const onClickHandler = React.useCallback(() => {
        // Отрезаем точку с запятой в конце пути
        const sliceSemicolon = (endPoint: string): string => {
            return endPoint.slice(-1) === ";" ? endPoint.slice(0, -1) : endPoint
        }

        let addressQuery = location.search

        const parsedAddressQuery = queryString.parse(addressQuery)

        for (const code in parsedAddressQuery) {
            if (code === "filter") {
                delete parsedAddressQuery[code]
            }
        }

        for (const code in parsedAddressQuery) {
            if (code === "attributeFilter") {
                delete parsedAddressQuery[code]
            }
        }
        addressQuery =
            categoryID && categoryID > 1
                ? `?cat=${categoryID}&count_on_page=${
                      paginationSettings.pageSize || 20
                  }&method=${sortMethod}&page=1&sort=${columnName}`
                : `?count_on_page=${
                      paginationSettings.pageSize || 20
                  }&method=${sortMethod}&page=1&sort=${columnName}`

        let filter = addressQuery.indexOf("?") ? "?filter=" : "&filter="
        let attribute = addressQuery.indexOf("?")
            ? "?attributeFilter="
            : "&attributeFilter="

        let stringifyFilter = ""
        for (const key in currentValuesFilter) {
            const parsedKey = key.replace("items-group", "itemsGroup")
            if (compareType[key] === "eq") {
                stringifyFilter += `${parsedKey}--eq--${currentValuesFilter[key]};`
            } else if (currentValuesFilter[key].length > 0) {
                stringifyFilter += `${key}--${compareType[key] || "eq"}--${
                    currentValuesFilter[key]
                };`
            }
        }

        let stringifyAttributeFilter = ""

        for (const key in currentValuesAttributeFilter) {
            stringifyAttributeFilter +=
                typeof currentValuesAttributeFilter[key] === "string"
                    ? `${
                          currentValuesAttributeFilter[key]
                              ? `${key}--${compareType[key] || "eq"}--${
                                    currentValuesAttributeFilter[key]
                                };`
                              : ""
                      }`
                    : `${
                          currentValuesAttributeFilter[key].includes("", 0)
                              ? ""
                              : `${key}--${
                                    compareType[key]
                                }--${currentValuesAttributeFilter[key].join(
                                    "|"
                                )};`
                      }`
        }

        dispatch(
            SAVE_FOR_TEMP({
                attributes: sliceSemicolon(stringifyAttributeFilter),
            })
        )

        const endPointTemp =
            addressQuery +
            (stringifyFilter ? filter : "") +
            (stringifyAttributeFilter
                ? stringifyFilter.slice(0, -1)
                : stringifyFilter) +
            (stringifyAttributeFilter ? attribute : "") +
            stringifyAttributeFilter

        const endPoint = sliceSemicolon(endPointTemp)

        history.push(endPoint || slug)

        dispatch(SAVE_FOR_TEMP({ filter: `${stringifyFilter.slice(0, -1)}` }))
        dispatch(SET_LOADER())

        api.get(apiPath + endPoint)
            .then((res: any) => {
                if (Array.isArray(res.data)) {
                    dispatch(CLEAR_LISTING())
                    dispatch(SET_EMPTY_FILTER())
                } else {
                    dispatch(CLEAR_EMPTY_FILTER())
                    dispatch(SET_LISTING(res.data))
                }

                dispatch(CLEAR_LOADER())
            })
            .catch(() => {
                dispatch(CLEAR_LOADER())
            })
    }, [
        location.search,
        history,
        slug,
        dispatch,
        apiPath,
        currentValuesFilter,
        currentValuesAttributeFilter,
        compareType,
    ])

    const setEntityValue = useCallback(
        (key: FilterInputEntity, value: any) => {
            dispatch(SET_CURRENT_VALUES({ [key]: value }, "filter"))
            dispatch(SET_COMPARE_TYPE({ [key]: "eq" }))
        },
        [dispatch]
    )

    const resetEnitityValues = useCallback(
        (keys: FilterInputEntity[]) => {
            dispatch(DELETE_FILTER_VALUES({ keys }))
        },
        [dispatch]
    )

    const resetHandler = React.useCallback(() => {
        setActivePreset(0)
        dispatch(RESET_ERROR())
        dispatch(CLEAR_EMPTY_FILTER())
        dispatch(getListing(apiPath))
        dispatch(RESET_CURRENT_VALUES())
        dispatch(SET_CURRENT_VALUES({ category: categoryID }, "main"))
        dispatch(RESET_COMPARE_TYPE({}))
        dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "" }))
        if (history.location.search.includes("&filter")) {
            history.push(history.location.search.split("&filter")[0])
        }
        if (
            !history.location.search.includes("&filter") &&
            history.location.search.includes("&attributeFilter")
        ) {
            history.push(history.location.search.split("&attributeFilter")[0])
        }
    }, [])

    React.useEffect(() => {
        // todo refactor
        const referenceSlugs: RouterSlugs[] = [
            "model",
            "group",
            "producer",
            "series",
            "import",
            "import-log",
            "economic-nomenclature-filters",
            "availability-nomenclature-filter",
            ...[...listingsMap].map(([key]) => key),
        ]
        if (referenceSlugs.includes(slug)) {
            dispatch(getFieldsFromServer(`reference/${slug}/fields`))
        } else {
            dispatch(getFieldsFromServer(`${slug}/fields`))
        }
    }, [categoryID, slug])

    const _fields = showedFields.length === 0 ? fields : showedFields

    const enititesFields = _fields
        .filter((el: FilterType) =>
            ["items-group", "model", "producer", "series"].includes(
                el.name.replace("itemsGroup", "items-group")
            )
        )
        .map((el: FilterType) => el.name.replace("itemsGroup", "items-group"))

    return (
        <>
            {loaderShowed ? <Loader /> : null}
            <div
                className={`toggle-content-filter ${
                    visibility ? "active" : ""
                }`}
            >
                <div className="control">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            Фильтр
                        </button>
                    </div>

                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                        >
                            <div className="goods-filet-toggle">
                                <div className="row pe-3">
                                    <div className="col-xl-3">
                                        <PresetControl
                                            categoryID={categoryID}
                                            resetHandler={resetHandler}
                                            activePreset={activePreset}
                                            setActivePreset={setActivePreset}
                                            preFIlteringHandler={onClickHandler}
                                        />
                                    </div>
                                    <div className="col-xl-9 filter-content">
                                        {_fields
                                            .filter(
                                                (el: FilterType) =>
                                                    ![
                                                        "items-group",
                                                        "model",
                                                        "producer",
                                                        "series",
                                                    ].includes(
                                                        el.name.replace(
                                                            "itemsGroup",
                                                            "items-group"
                                                        )
                                                    )
                                            )
                                            .map((field: FilterType) => {
                                                if (
                                                    !isEmptyObject(
                                                        field.filter
                                                    ) &&
                                                    field.filter.display
                                                ) {
                                                    return (
                                                        <FilterComponentSelecter
                                                            key={field.name}
                                                            field={field}
                                                            onClickHandler={
                                                                onClickHandler
                                                            }
                                                        />
                                                    )
                                                }
                                                return null
                                            })}
                                        {!!enititesFields.length && (
                                            <InputEntities
                                                entities={enititesFields}
                                                setValue={setEntityValue}
                                                resetValues={resetEnitityValues}
                                            />
                                        )}
                                        <GateContainer
                                            isShow={slug === "good"}
                                            plug={null}
                                        >
                                            <hr />
                                            <h5 className="mb-4">
                                                Фильтр по свойствам
                                            </h5>
                                            {attributeFields.length ? (
                                                <p>Нет привязанных свойств</p>
                                            ) : (
                                                attributeFields &&
                                                attributeFields.map(
                                                    (field: {
                                                        [key: string]: any
                                                    }) => {
                                                        return (
                                                            <AttributeComponentSelecter
                                                                key={
                                                                    field
                                                                        .attribute
                                                                        .id
                                                                }
                                                                field={
                                                                    field.attribute
                                                                }
                                                                onClickHandler={
                                                                    onClickHandler
                                                                }
                                                            />
                                                        )
                                                    }
                                                )
                                            )}
                                        </GateContainer>
                                        <div
                                            className="position-sticky d-flex justify-content-between mt-2"
                                            style={{
                                                bottom: "16px",
                                                backgroundColor: "#f5f5f5",
                                            }}
                                        >
                                            <div className="btn-group">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        dispatch(RESET_ERROR())
                                                        validateInputs(
                                                            onClickHandler
                                                        )
                                                    }}
                                                    disabled={
                                                        isEmptyObject(
                                                            currentValuesFilter
                                                        ) &&
                                                        isEmptyObject(
                                                            currentValuesAttributeFilter
                                                        ) &&
                                                        isEmptyObject(
                                                            compareType
                                                        )
                                                    }
                                                >
                                                    Фильтровать
                                                </button>
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={resetHandler}
                                                    disabled={
                                                        isEmptyObject(
                                                            currentValuesFilter
                                                        ) &&
                                                        isEmptyObject(
                                                            currentValuesAttributeFilter
                                                        ) &&
                                                        isEmptyObject(
                                                            compareType
                                                        )
                                                    }
                                                >
                                                    Сбросить
                                                </button>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#fieldModal"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-gear-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                                </svg>
                                                <span className="mobile-hide">
                                                    &nbsp;Настроить поля
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {slug ? <FieldSettings /> : null}
        </>
    )
}
