import { arrayMoveImmutable } from "array-move"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { FC, ReactNode } from "react"
import Select, { components } from "react-select"
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc"
import { IApiMuslitSelect, ReactSelectList } from "../types"

const DragHandle = SortableHandle(() => (
    <div
        style={{
            height: "100%",
            marginRight: "auto",
            cursor: "grab",
            padding: "0px 4px",
        }}
        onMouseDown={(e) => {
            e.stopPropagation()
        }}
    >
        ::
    </div>
))

interface ISortableComponent {
    value: string
    label: string
    remove: (val: string) => void
}

const SortableItem = SortableElement<ISortableComponent>(
    ({ value, label, remove }: ISortableComponent) => (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "hsl(0, 0%, 90%)",
                margin: 2,
                borderRadius: "2px",
                userSelect: "none",
            }}
        >
            <DragHandle />
            <div
                style={{
                    fontSize: "85%",
                    padding: "3px 3px 3px 6px",
                    whiteSpace: "nowrap",
                }}
            >
                {label}
            </div>

            <div
                style={{
                    marginLeft: "8px",
                    cursor: "pointer",
                    padding: "0px 4px",
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    remove(value)
                }}
                onMouseDown={(e) => {
                    e.stopPropagation()
                }}
            >
                &times;
            </div>
        </div>
    )
)

interface ISortableContainer {
    items: { value: string; label: string }[]
    children: ReactNode
    remove: (val: string) => void
}

const SortableMultiValue = SortableContainer<any>(
    ({ items, remove, children }: ISortableContainer) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {items.map(({ value, label }, index) => (
                    <SortableItem
                        key={value}
                        index={index}
                        value={value}
                        label={label}
                        remove={remove}
                    />
                ))}
                {children}
            </div>
        )
    }
)

const CustomValueContainer: FC<{
    addParams: (list: ReactSelectList) => void
    removeParams: (list: ReactSelectList, prop: any) => void
    children: ReactNode[]
}> = ({ children, addParams, removeParams, ...props }) => {
    const [inputValue] = children
    const currentProps = props as any

    return (
        <components.ValueContainer {...currentProps}>
            <SortableMultiValue
                items={currentProps.getValue()}
                onSortEnd={({
                    oldIndex,
                    newIndex,
                }: {
                    oldIndex: number
                    newIndex: number
                }) => {
                    const newValue = arrayMoveImmutable(
                        currentProps.getValue(),
                        oldIndex,
                        newIndex
                    )
                    currentProps.setValue(newValue)

                    addParams(newValue as any[])
                }}
                axis="xy"
                remove={(value: string) => {
                    const newValue = currentProps
                        .getValue()
                        .filter((v: { value: string }) => v.value !== value)
                    currentProps.setValue(newValue)

                    removeParams(
                        currentProps.getValue() as ReactSelectList,
                        value
                    )
                }}
                useDragHandle
                helperClass="sortable-helper"
            >
                {inputValue}
            </SortableMultiValue>
        </components.ValueContainer>
    )
}

const ApiSortableMultiSelect: FC<
    IApiMuslitSelect & { value: any[]; onChange: (val: any) => void }
> = ({
    loading,
    disabled,
    defaultValue = [],
    options,
    urls,
    value,
    onChange,
}) => {
    const { showNotification } = useNotifications()

    const apiMethod = urls.addMethod ?? "post"
    const removeMethod = urls.remodeMethod ?? "delete"

    const addParams = (list: ReactSelectList) => {
        const params = urls.getAddParams ? urls.getAddParams(list) : {}

        const addUrl = urls.add()

        onChange(list)
        api[apiMethod](addUrl, {}, params).catch((error) =>
            showNotification({
                type: "danger",
                message: error.message,
            })
        )
    }

    const removeParams = (list: ReactSelectList, prop: any) => {
        onChange(list.filter((v) => v.value !== prop))

        if (!!urls.getRemoveParams) {
            const data = urls.getRemoveParams(list, prop)
            api[removeMethod](urls.remove(prop), {}, data)
        }
    }

    return (
        <Select
            value={value}
            isMulti
            isLoading={loading}
            isDisabled={disabled}
            isClearable={false}
            placeholder=""
            key={JSON.stringify(defaultValue)}
            defaultValue={defaultValue}
            onChange={(list, prop) => {
                if (
                    prop.action === "remove-value" ||
                    prop.action === "pop-value"
                ) {
                    if (urls.getRemoveParams) {
                        const data = urls.getRemoveParams(list, prop)
                        onChange(list)
                        return api[removeMethod](urls.remove(prop), {}, data)
                    }
                    if (prop.removedValue?.value) {
                        onChange(list)
                        api[removeMethod](urls.remove(prop), {}).catch(
                            (error) =>
                                showNotification({
                                    type: "danger",
                                    message: error.message,
                                })
                        )
                        return
                    }
                }
                prop.option?.value && addParams(list)
            }}
            options={options}
            components={{
                ValueContainer: (props: any) => (
                    <CustomValueContainer
                        {...props}
                        addParams={addParams}
                        removeParams={removeParams}
                    />
                ),
            }}
        />
    )
}

export default ApiSortableMultiSelect
