import { useQuery } from "@tanstack/react-query"
import { Col, Progress, Row, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import { useApiJobs } from "hooks/useApiJobs"
import { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { IdNameItem, Uuid } from "types"
import { useCostCollectorStore } from "../CostCollector.store"
import { CostCollectAttributesAction } from "./CostCollectAttributesAction"
import { useCostCollectorAttributeStore } from "./CostCollectorAttributes.store"
import { AttributePriceItem } from "./CostCollectorAttributes.types"
import CostCollectorValuesTable from "./CostCollectorValuesTable"

type GetPricesJob = {
    model: IdNameItem
    region: Uuid
}

type GetPricesRequest = {
    model: Uuid
    region: Uuid
}
export const CostCollectoryMultipyModelsAttributes = () => {
    const { selectedRows, producer, itemsGroup } = useCostCollectorStore()
    const {
        activeAttributeId,
        toggleActiveAttribute,
        selectedAttrubuteIds,
        toggleSelectedAttribute,
        setAttributeRows,
        toggleAllAttributes,
        reset,
        resetTableData,
        setAvailableData,
        resetAvailableData,
    } = useCostCollectorAttributeStore()
    const region = useSelector((state: RootState) => state.region.region)

    const { fetchJobs, progress, jobString, clearCatchData } = useApiJobs()

    const { data, isFetching, refetch, remove } = useQuery<
        AttributePriceItem[]
    >({
        queryKey: [selectedRows, region, "get-prices", fetchJobs],
        enabled: !!selectedRows?.length && !!region?.value,
        queryFn: () => {
            resetAvailableData()
            const jobs = selectedRows
                .filter((el) => el.parentId)
                .map(
                    (el) =>
                        ({
                            model: el.model,
                            region: region!.value,
                        } as GetPricesJob)
                )
            return fetchJobs<
                GetPricesJob,
                GetPricesRequest,
                AttributePriceItem[],
                Array<{ data: AttributePriceItem[]; model: IdNameItem }>
            >(
                {
                    url: "v1/economics/attribute/get-prices",
                    getResultData(v, data, jobData) {
                        return [
                            ...data,
                            {
                                data: v,
                                model: jobData.model,
                            },
                        ]
                    },
                    getProcessString(v) {
                        return `Получение данных - ${v.model.name}`
                    },
                    getRequestData(v) {
                        return {
                            model: v.model.id,
                            region: v.region,
                        }
                    },
                    initialResult: [] as Array<{
                        data: AttributePriceItem[]
                        model: IdNameItem
                    }>,
                    initialLength: jobs.length,
                    method: "post",
                },
                jobs
            ).then((data) => {
                return data.reduce((acc, next) => {
                    const data = next.data
                    const model = next.model
                    const availableAttributes = data.map(
                        (el) => el.attribute.id
                    )
                    const availableValues = [] as Uuid[]
                    data.forEach((el) => {
                        availableValues.push(
                            ...el.attributePrices.map(
                                (el) => Object.keys(el.attributeValue)[0]
                            )
                        )
                        const foundAttribute = acc.find(
                            (priceData) =>
                                priceData.attribute.id === el.attribute.id
                        )
                        if (foundAttribute) {
                            foundAttribute.attributePrices.push(
                                ...el.attributePrices
                            )
                        } else {
                            acc.push(el)
                        }
                    })
                    setAvailableData(model.id, {
                        attributes: availableAttributes,
                        values: availableValues,
                        modelName: model.name,
                    })
                    return acc
                }, [] as AttributePriceItem[])
            })
        },
        initialData: [],
        onSuccess(data) {
            setAttributeRows(data)
            resetTableData()
            if (!data.some((el) => el.attribute.id === activeAttributeId)) {
                reset()
            }
        },
    })

    const refetchJobs = useCallback(
        (clearCacheFor: object[]) => {
            clearCatchData(clearCacheFor).then(() => {
                refetch()
            })
        },
        [clearCatchData, refetch]
    )

    useEffect(() => {
        remove()
        reset()
        resetTableData()
    }, [producer, remove, itemsGroup, reset, resetTableData])

    return (
        <Flex.Col fullWidth className="--visible" gap={20}>
            {!!progress && (
                <Flex.Col gap={10} fullWidth>
                    <Typography.Text>{jobString}</Typography.Text>
                    <Progress percent={Math.round(progress)} />
                </Flex.Col>
            )}

            {!progress && !!selectedRows.length && (
                <>
                    <CostCollectAttributesAction refetch={refetchJobs} />
                    <Row gutter={[10, 0]} className="fw">
                        <Col md={{ span: 8 }} xs={{ span: 24 }}>
                            <UTable
                                data={data}
                                loading={isFetching}
                                isRowActive={(row) =>
                                    activeAttributeId === row.attribute.id
                                }
                                onRowClick={(row) =>
                                    toggleActiveAttribute({
                                        id: row.attribute.id,
                                        name: row.attribute.name,
                                    })
                                }
                                columns={[
                                    {
                                        columnName: () => (
                                            <Flex.Col justify="center">
                                                <input
                                                    className="form-check-input cursor-pointer"
                                                    onChange={
                                                        toggleAllAttributes
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        selectedAttrubuteIds.length ===
                                                        data.length
                                                    }
                                                />
                                            </Flex.Col>
                                        ),
                                        render: (row) => (
                                            <Flex.Col
                                                className="fw"
                                                justify="center"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input cursor-pointer"
                                                    checked={selectedAttrubuteIds.includes(
                                                        row.attribute.id
                                                    )}
                                                    onChange={() => {
                                                        toggleSelectedAttribute(
                                                            row.attribute.id
                                                        )
                                                    }}
                                                />
                                            </Flex.Col>
                                        ),
                                        width: 10,
                                    },
                                    {
                                        columnName: "ID",
                                        render: (row) => row.attribute.id,
                                        width: 15,
                                    },
                                    {
                                        columnName: "Свойство",
                                        render: (row) => row.attribute.name,
                                    },
                                ]}
                            />
                        </Col>
                        <CostCollectorValuesTable />
                    </Row>
                </>
            )}
        </Flex.Col>
    )
}
