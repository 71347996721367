import { Form, FormItemProps } from "antd"
import cls from "classnames"
import { isDefined } from "helpers/checkType"
import {
    ChangeEvent,
    FocusEvent,
    ForwardedRef,
    forwardRef,
    KeyboardEvent,
    useEffect,
    useRef,
    useState,
} from "react"
import styles from "./UNumberItem.module.sass"

type UNumberInputProps = {
    bordered?: boolean
    debounce?: number
    floatRank?: number
    onPressEnter?: (value: number) => void
    navigateProps?: {
        onNext: () => void
        onPrev: () => void
        onUp?: () => void
        onDown?: () => void
    }
    min?: number
    max?: number
    focused?: boolean
    handleBlur?: (value: number) => void
    handleFocus?: (value: number) => void
    handleChange?: (value: number | null) => void
    useInForm?: FormItemProps
    noBackground?: boolean
    prefix?: string
    noFormControl?: boolean
    allowEmpty?: boolean
    disableInnerKeydownLogic?: boolean
    removeOnEmptyString?: () => void
}

const applySpaces = (number: string | number) => {
    const [leftPart, rightPart = ""] = ("" + number).split(",")
    const separator = " "
    return (
        leftPart.replaceAll(
            /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
            "$1" + separator
        ) + (rightPart ? "," + rightPart : rightPart)
    )
}

const checkMinMax = (number: string, min?: number, max?: number) => {
    const parsedNumber = +number
    if (!isDefined(min) && !isDefined(max)) return number
    if (isDefined(min) && parsedNumber < min!) return "" + (min || "")
    if (isDefined(max) && parsedNumber > max!) return "" + (max || "")
    return number
}

const getNumber = (value: string): number => {
    const numberString = value.replaceAll(",", ".").replaceAll(" ", "")
    return +numberString
}

const getInputValue = (value: string, floatRank?: number) => {
    const initalValue = value.replaceAll(".", ",")
    const negative = value[0] === "-" ? "-" : ""
    if (floatRank === 0) {
        return (
            negative + initalValue.replace(/,.*$/, "").replaceAll(/[^0-9]/g, "")
        )
    }
    if (initalValue.startsWith(",")) {
        return ","
    }
    let newValue = [...initalValue]
        .filter((symb, idx, array) => {
            if (idx === 0 && symb === "-") return true
            if (!/[0-9,]/.test(symb)) return false
            const prevPart = array.slice(0, idx)
            if (symb === ",") return !prevPart.includes(symb)
            return true
        })
        .join("")
    if (floatRank) {
        const [leftPart, rightPart] = newValue.split(",")
        if (rightPart && rightPart.length > floatRank) {
            newValue = [leftPart, rightPart.slice(0, floatRank)].join(",")
        }
    }
    return newValue
}

export const getUNumberValue = (value: any) => {
    if (typeof value === "string") return getNumber(value)
    if (typeof value === "number") return value
    return
}

export const getUStringValue = (value?: number) => {
    if (!value) return ""
    return applySpaces(value).replace(".", ",")
}

function UNumberInput(
    props: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > &
        UNumberInputProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    const [currentValue, setCurrentValue] = useState(
        props.value ? applySpaces(props.value as string) : ""
    )

    const onFocus = (e: FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (props.handleFocus) {
            props.handleFocus(getNumber(e.currentTarget.value))
        }
        setCurrentValue((prev) => prev.replaceAll(" ", ""))
        e.target.select()
    }

    const onBlur = (e: FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (props.handleBlur) {
            props.handleBlur(getNumber(e.currentTarget.value))
        }
        setCurrentValue((prev) => applySpaces(prev))
    }

    const handleChange = (value: string) => {
        const negative =
            (props.min ?? 0) < 0 ? "" : ("" + value).match(/^-/)?.[0] ?? ""
        const sumbols = [...value]
        const stack = [] as string[]
        sumbols.forEach((el) => {
            const symbol = [",", "."].includes(el) ? "," : el
            if (
                (symbol === "," && stack.includes(",")) ||
                !/[0-9,]/.test(symbol)
            )
                return
            stack.push(symbol)
        })
        const newValue = checkMinMax(
            negative + stack.join(""),
            props.min,
            props.max
        )
        if (props.floatRank) {
            const lastDotIndex = newValue.lastIndexOf(",")
            return lastDotIndex === -1
                ? newValue
                : newValue.slice(0, lastDotIndex + props.floatRank + 1)
        }
        return newValue
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        const newValue = handleChange(value)
        setCurrentValue(newValue.replace(",", "."))
        if (value === "-") return
        if (props.handleChange) {
            if (props.allowEmpty && !newValue) {
                props.handleChange(null)
            } else {
                props.handleChange(getNumber(newValue))
            }
        }
    }

    const onInput = (e: KeyboardEvent<HTMLInputElement>) => {
        const newValue = getInputValue(e.currentTarget.value, props.floatRank)
        e.currentTarget.value = newValue
        if (props.handleChange && newValue !== "-") {
            props.handleChange(getNumber(newValue) ?? null)
        }
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (props.disableInnerKeydownLogic) {
            if (props.onKeyDown) {
                props.onKeyDown(e)
            }
            return
        }
        if (e.key === "Enter" && props.onPressEnter) {
            if (props.removeOnEmptyString) {
                return props.removeOnEmptyString()
            }
            props.onPressEnter(+currentValue.replace(",", "."))
        } else if (
            ["ArrowRight", "Tab"].includes(e.key) &&
            props.navigateProps?.onNext
        ) {
            e.preventDefault()
            e.stopPropagation()
            props.navigateProps.onNext()
        } else if (e.key === "ArrowLeft" && props.navigateProps?.onPrev) {
            e.preventDefault()
            e.stopPropagation()
            props.navigateProps.onPrev()
        } else if (e.key === "ArrowUp" && props.navigateProps?.onUp) {
            e.preventDefault()
            e.stopPropagation()
            props.navigateProps.onUp()
        } else if (e.key === "ArrowDown" && props.navigateProps?.onDown) {
            e.preventDefault()
            e.stopPropagation()
            props.navigateProps.onDown()
        }
        if (props.onKeyDown) {
            props.onKeyDown(e)
        }
    }

    const htmlInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!htmlInputRef.current) return
        props.focused
            ? htmlInputRef.current.focus()
            : htmlInputRef.current.blur()
    }, [props.focused])

    useEffect(() => {
        setCurrentValue(
            props.value === undefined ? "" : applySpaces(props.value as string)
        )
    }, [props.value])

    return props.useInForm ? (
        <div className={styles.wrapper} style={props.style}>
            <Form.Item {...props.useInForm}>
                <input
                    style={{
                        paddingLeft: props.prefix?.length
                            ? 15 + props.prefix.length * 10
                            : undefined,
                    }}
                    className={cls(
                        {
                            [styles.uNumberInput]: true,
                            [styles["not-bordered"]]: !props.bordered,
                            [styles.noBackground]: props.noBackground,
                            "form-control": !props.noFormControl,
                        },
                        props.className
                    )}
                    onFocus={(e) => {
                        e.currentTarget.value =
                            e.currentTarget.value.replaceAll(" ", "")
                    }}
                    onBlur={(e) => {
                        e.currentTarget.value = applySpaces(
                            e.currentTarget.value
                        )
                    }}
                    ref={htmlInputRef}
                    onKeyDown={onKeyDown}
                    onInput={onInput}
                    disabled={props.disabled}
                />
            </Form.Item>
            {props.prefix && (
                <span className={styles.prefix}>{props.prefix}</span>
            )}
        </div>
    ) : (
        <div className={styles.wrapper} style={props.style}>
            {props.prefix && (
                <span className={styles.prefix}>{props.prefix}</span>
            )}
            <input
                style={{
                    paddingLeft: props.prefix?.length
                        ? 15 + props.prefix.length * 10
                        : undefined,
                }}
                className={cls(
                    {
                        [styles.uNumberInput]: true,
                        [styles["not-bordered"]]: !props.bordered,
                        [styles.noBackground]: props.noBackground,
                        "form-control": !props.noFormControl,
                    },
                    props.className
                )}
                ref={ref ?? htmlInputRef}
                onKeyDown={onKeyDown}
                value={currentValue.replace(".", ",")}
                onInput={onInput}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    )
}

export default forwardRef(UNumberInput)
