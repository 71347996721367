import { Uuid } from "types"
import { create } from "zustand"
import { changeAttributes, deleteAllAttributes } from "./helper"

enum Value {
    producer = "activeProducerId",
    group = "activeGroupId",
    series = "activeSeriesId",
    model = "activeModelId",
    allModels = "allModels",
    allSeries = "allSeries",
}

enum Attribute {
    name = "nameAttributes",
    accounting = "accountingNameAttributes",
}

enum AvailabilityAttribute {
    name = "availabilityName",
    accounting = "availabilityAccountingName",
}

interface IValue {
    value: string
    label: string
    sort: number
}

type typeDefaultState<T> = {
    value?: T | null
    setValue: (value: T | null, type: keyof typeof Value) => void
}

interface IItem {
    id: Uuid
    label: string
    availabilityName: boolean
    availabilityAccountingName: boolean
    attributes: Record<Attribute, IValue[]>
    models?: IItem[]
}

interface INameGenerationSettings {
    activeProducerId: typeDefaultState<string>["value"]
    activeGroupId: typeDefaultState<string>["value"]
    activeSeriesId: typeDefaultState<string>["value"]
    activeModelId: typeDefaultState<string>["value"]
    allSeries: IItem[] | []
    setValue: typeDefaultState<any>["setValue"]
    setSeriesAttributes: (props: {
        id: Uuid
        typeAttribute: keyof typeof Attribute
        value: IValue[]
    }) => void
    setModelsAttributes: (props: {
        idSeries: Uuid
        idModel: Uuid
        typeAttribute: keyof typeof Attribute
        value: IValue[]
    }) => void
    deleteAllSeriesAttributes: (props: { id: Uuid }) => void
    deleteAllModelAttributes: (props: { idSeries: Uuid; idModel: Uuid }) => void
    copyAllSeriesAttributes: (props: {
        toAttributes: keyof typeof Attribute
        id: Uuid
    }) => void

    reset: () => void
}

const defaultState = {
    activeGroupId: null,
    activeSeriesId: null,
    activeModelId: null,
    activeProducerId: null,
    allSeries: [],
}

export const useNameGenerationSettingsStore = create<INameGenerationSettings>(
    (set, get) => ({
        ...defaultState,
        setValue(value, type) {
            if (type === "allModels") {
                return set((state) => ({
                    ...state,
                    allSeries: state.allSeries.map((item) =>
                        item.id === value.id
                            ? { ...item, models: value.data }
                            : item
                    ),
                }))
            }

            return set((state) => ({
                ...state,
                [Value[type]]: value,
            }))
        },
        setSeriesAttributes({ id, typeAttribute, value }) {
            return set((state) => ({
                ...state,
                allSeries: state.allSeries.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              [AvailabilityAttribute[typeAttribute]]:
                                  !!value.length,
                              attributes: {
                                  ...item?.attributes,
                                  [Attribute[typeAttribute]]: value,
                              },
                          }
                        : item
                ),
            }))
        },
        setModelsAttributes({ idSeries, idModel, typeAttribute, value }) {
            return set((state) => ({
                allSeries: state.allSeries.map((series) => ({
                    ...series,
                    models:
                        series.id === idSeries
                            ? series.models?.map((model) =>
                                  model.id === idModel
                                      ? {
                                            ...model,
                                            [AvailabilityAttribute[
                                                typeAttribute
                                            ]]: !!value.length,
                                            attributes: {
                                                ...model?.attributes,
                                                [Attribute[typeAttribute]]:
                                                    value,
                                            },
                                        }
                                      : model
                              )
                            : series.models,
                })),
            }))
        },
        async deleteAllSeriesAttributes({ id }) {
            const currentState = get()

            set((state) => ({
                allSeries: state.allSeries.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              availabilityName: false,
                              availabilityAccountingName: false,
                              attributes: {
                                  [Attribute.name]: [],
                                  [Attribute.accounting]: [],
                              },
                          }
                        : item
                ),
            }))

            await deleteAllAttributes({
                attributes: currentState.allSeries.find(
                    (series) => series.id === id
                )?.attributes,
                type: "series",
            })
        },
        async deleteAllModelAttributes({ idSeries, idModel }) {
            const currentState = get()

            set((state) => ({
                allSeries: state.allSeries.map((series) => ({
                    ...series,
                    models:
                        series.id === idSeries
                            ? series.models?.map((model) =>
                                  model.id === idModel
                                      ? {
                                            ...model,
                                            availabilityName: false,
                                            availabilityAccountingName: false,
                                            attributes: {
                                                [Attribute.name]: [],
                                                [Attribute.accounting]: [],
                                            },
                                        }
                                      : model
                              )
                            : series.models,
                })),
            }))

            await deleteAllAttributes({
                attributes: currentState.allSeries
                    .find((series) => series.id === idSeries)
                    ?.models?.find((model) => model.id === idModel)?.attributes,
                type: "model",
            })
        },
        async copyAllSeriesAttributes({ id, toAttributes }) {
            const currentState = get()

            const fromAttributes =
                toAttributes === "name" ? "accounting" : "name"

            set((state) => ({
                allSeries: state.allSeries.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              [AvailabilityAttribute[toAttributes]]:
                                  !!item.attributes?.[Attribute[fromAttributes]]
                                      .length,
                              attributes: {
                                  ...item.attributes,

                                  [Attribute[toAttributes]]:
                                      item.attributes?.[
                                          Attribute[fromAttributes]
                                      ],
                              },
                          }
                        : item
                ),
            }))

            await changeAttributes({
                attributes: currentState.allSeries.find(
                    (series) => series.id === id
                )?.attributes,
                toAttributes: toAttributes,
                fromAttributes: fromAttributes,
                type: "series",
            })
        },

        reset() {
            return set(() => defaultState)
        },
    })
)
