import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { useListingSearch } from "hooks/useListingSearch"
import { usePageData } from "hooks/usePageData"
import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { CLEAR_LOADER } from "store/loader/actions"
import { useNotifications } from "../../hooks/useNotifications"
import { usePaginationHelper } from "../../hooks/usePaginationHelper"
import { GateContainer } from "../../partials/GateContainer"
import Loader from "../../partials/Loader"
import { getListing, processLisingResult } from "../../store/listing/actions"
import { getUserSettings } from "../../store/saveData/actions"
import { ParentSection } from "../Category/ParentSection"
import { Filter } from "../Filter"
import { ColumnSettings } from "./ColumnSettings"
import { GlobalAction } from "./partials/GlobalAction"
import { handleClickBatchActions } from "./partials/helpers/handleClickBatchActions"
import { Pagination } from "./partials/Pagination"
import { Table } from "./partials/Table"

let checkedForRemove: number[] = []

export const clearCheckedForRemove = () => {
    checkedForRemove = []
}

export const Listing: FC<{
    noCreate?: boolean
    editOrCreatePath?: string
    apiPath?: string
}> = ({ noCreate, editOrCreatePath, apiPath: propsApiPath }) => {
    const dispatch = useDispatch()
    const resolveRoute = usePaginationHelper()
    const { isValidSearch } = useListingSearch()
    const { showNotification } = useNotifications()

    const history = useHistory()
    const { location } = history
    const { apiPath: pageDataApiPath, slug } = usePageData()
    const apiPath = propsApiPath ?? pageDataApiPath
    const { isSearchResult } = useSelector((state: any) => state.isSearchResult)

    const listing =
        useSelector((state: { [key: string]: any }) => state?.listing) || {}
    const { entities, isEmptyFilter, pageName, batch_actions, pagination } =
        listing

    const { pageSize, currentPage, totalCount } = pagination || {}

    const { columnName, sortMethod } =
        useSelector(
            (state: { [key: string]: any }) =>
                state.savedUserSettings?.sortedColumns
        ) || []

    const categoryID = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.category
    )

    const [visibility, setVisibility] = React.useState(false)

    const fetchFilteredGoods = (id: number): void => {
        let query = `count_on_page=${pageSize}&method=${sortMethod}&page=1&sort=${columnName}`
        if (id || id !== 1) {
            dispatch(getListing(`${apiPath}?cat=${id}&${query}`))
        } else {
            dispatch(getListing(`${apiPath}?${query}`))
        }
    }

    const selectedForRemove = React.useCallback(
        (
            event: { target: { checked: boolean } },
            item: { [key: string]: any }
        ) => {
            checkedForRemove = event.target.checked
                ? [...checkedForRemove, item.id]
                : (checkedForRemove = checkedForRemove.filter(
                      (id) => id !== item.id
                  ))
        },
        []
    )

    const autoRemove = React.useCallback((flag: boolean, items: number[]) => {
        if (flag) {
            return items.forEach((item) => {
                checkedForRemove = [...checkedForRemove, item]
            })
        }

        return items.forEach((item) => {
            checkedForRemove = checkedForRemove.filter((id) => id !== item)
        })
    }, [])

    React.useEffect(() => {
        if (apiPath) {
            dispatch(
                getUserSettings(
                    `listing/saved-data/${slug}`,
                    apiPath,
                    location.search
                )
            )
        }
    }, [apiPath, dispatch, slug, location])

    useQuery({
        queryKey: [slug, location.search, "get-listing", isValidSearch],
        enabled: isValidSearch,
        queryFn: () => {
            return api.get(`listing/${slug + location.search}`)
        },
        onSuccess: (data) => {
            dispatch(processLisingResult(data, slug))
        },
        onSettled: () => {
            dispatch(CLEAR_LOADER())
        },
    })

    const { listingActionName } = usePageData()

    if (!listing.pageName && !isEmptyFilter) return <Loader />

    return (
        <>
            <h1 className="h3 mb-3">
                {isSearchResult ? "Результаты поиска: " : pageName}
            </h1>
            <div
                className={`${
                    entities?.length > 0 && "btn-toolbar"
                } justify-content-between users-toolbar`}
                role="toolbar"
                aria-label="Панель инструментов с группами кнопок"
            >
                <GateContainer isShow={!isSearchResult}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <button
                                type="button"
                                className="btn btn-outline-primary me-3"
                                onClick={() => setVisibility(!visibility)}
                            >
                                <svg
                                    className={`bi bi-arrow-up-circle ${
                                        visibility ? "arrow-down" : ""
                                    }`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                </svg>
                                <span className="mobile-hide">
                                    &nbsp;Развернуть фильтр
                                </span>
                            </button>

                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#columnModal"
                                disabled={entities?.length === 0}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-gear-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                </svg>
                                <span className="mobile-hide">
                                    &nbsp;Настроить колонки
                                </span>
                            </button>
                        </div>
                        {entities?.length <= 0 && !noCreate && (
                            <Link
                                to={`${editOrCreatePath ?? slug}/create`}
                                className="btn btn-primary"
                            >
                                {listingActionName}
                            </Link>
                        )}
                    </div>
                    <GlobalAction
                        noCreate={noCreate}
                        editOrCreatePath={editOrCreatePath}
                    />
                </GateContainer>
            </div>
            {apiPath ? <Filter visibility={visibility} /> : null}
            {isEmptyFilter ? (
                <>
                    <div className=" my-5 py-5 text-center text-muted">
                        <div className="mt-5 h4">
                            По вашему запросу ничего не найдено
                        </div>
                        <div className="mt-2 h6">
                            Попробуйте сбросить значения в фильтре или изменить
                            запрос поиска
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="row">
                        <div
                            className={
                                slug === "good" && !isSearchResult
                                    ? "col-md-9"
                                    : "col-md-12"
                            }
                        >
                            <div className="table-wrap">
                                <GateContainer
                                    isShow={
                                        entities.length !== 0 &&
                                        !!apiPath &&
                                        !!slug
                                    }
                                    plug="По вашему запросу ничего не найдено"
                                >
                                    <Table
                                        selectedForRemove={selectedForRemove}
                                        autoRemove={autoRemove}
                                    />
                                </GateContainer>
                            </div>
                        </div>
                        <GateContainer
                            isShow={slug === "good" && !isSearchResult}
                        >
                            <div className="col-md-3 tree mt-4 ps-2">
                                {slug ? (
                                    <ParentSection
                                        categoryGoodsID={categoryID}
                                        isGoods={true}
                                        pageSlug={slug}
                                        fetchFilteredGoods={fetchFilteredGoods}
                                    />
                                ) : null}
                            </div>
                        </GateContainer>
                    </div>
                    <div className="btn-toolbar justify-content-between users-result">
                        <div className="users-result__pre">
                            <div className="users-result__actions">
                                <span className="mobile-pre-message">
                                    C выбранными:
                                </span>
                                <select
                                    className="form-select"
                                    aria-label="Default select"
                                >
                                    {batch_actions &&
                                        batch_actions.map(
                                            (action: {
                                                type: string
                                                name: string
                                                label: string
                                                action: string
                                            }) => {
                                                return (
                                                    <option
                                                        key={action.name}
                                                        value="Удалить"
                                                    >
                                                        {action.label}
                                                    </option>
                                                )
                                            }
                                        )}
                                </select>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                        handleClickBatchActions(
                                            checkedForRemove,
                                            slug,
                                            dispatch,
                                            apiPath,
                                            history,
                                            showNotification
                                        )
                                    }}
                                >
                                    Применить
                                </button>
                            </div>
                        </div>
                        <div className="show-add">
                            <span className="mobile-pre-message">
                                Показывать по:{" "}
                            </span>
                            <select
                                className="form-select"
                                aria-label="Default select"
                                defaultValue={pageSize}
                                onChange={(event) => {
                                    resolveRoute(
                                        apiPath,
                                        currentPage,
                                        Number(event.target.value)
                                    )
                                }}
                            >
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="all">Все</option>
                            </select>
                        </div>
                    </div>
                </>
            )}
            <div className="btn-toolbar justify-content-between users-pagination-toolbar">
                <GateContainer isShow={entities?.length > 0}>
                    <Pagination apiPath={apiPath} />
                </GateContainer>
                <div className="users-total">
                    Всего элементов:{" "}
                    <span>{entities?.length > 0 ? totalCount : 0}</span>
                </div>
            </div>
            {slug ? <ColumnSettings /> : null}
        </>
    )
}
