import api from "helpers/api"
import React, { useEffect, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import LoadingOverlay from "react-loading-overlay-ts"

import { useMutation } from "@tanstack/react-query"
import { Spin } from "antd"
import { Uuid } from "types"
import { EntityType, ModelAttribute } from "./types"

interface EntityData {
    group: Uuid
    producer: Uuid
    series: Uuid | null
}
interface ItemsGroupRowsChooserProps {
    attributes: ModelAttribute[]
    onChangeActive: (selected: Uuid) => void
    active?: Uuid
    loading?: boolean
    onToggleActiveRowConfigurability: (id: Uuid, state: boolean) => void
    activeLink?: ModelAttribute
    entityType?: EntityType
    entityData: EntityData
    setActiveLink: (v?: ModelAttribute) => void
}

const useStyles = createUseStyles({
    valueCell: {
        width: "100%",
    },
    idCell: {
        minWidth: 60,
        textAlign: "center",
    },
    headlineCell: {
        width: "100%",
    },
    searchCell: {
        width: "100%",
    },
    searchLabel: {
        paddingLeft: 15,
    },
    tableBody: {
        height: "450px",
        display: "block",
    },
    searchNotFound: {
        textAlign: "center",
    },
    searchBoxInput: {
        width: "100%",
        outline: "none",
        border: "1px solid #dee2e6",
        borderBottom: "none",
        padding: "5px 10px",
    },
    tableClickableRow: {
        cursor: "pointer",
        "&:first-child": {
            borderTop: "none",
        },
        "&:hover": {
            background: "#eee",
        },
    },
    activeRow: {
        background: "#eee",
    },
    exportCell: {
        minWidth: "80px",
        textAlign: "center",
    },

    checkboxCell: {
        minWidth: "35px",
    },

    idLineWrapper: {
        display: "block",
        height: "20px",
        overflow: "hidden",
        width: 38,
    },
})

const AttibuteItem = ({
    data,
    isActive,
    onSelect,
    activeLink,
    isSeries,
    entityData,
    setActiveLink,
}: {
    data: ModelAttribute
    isActive: boolean
    onSelect: (v: Uuid) => void
    activeLink?: ModelAttribute
    isSeries: boolean
    entityData: EntityData
    setActiveLink: (v?: ModelAttribute) => void
}) => {
    const classes = useStyles()

    const { attribute, attributeConfigurable } = data
    const [checked, setChecked] = useState(attributeConfigurable)

    useEffect(() => {
        setChecked(attributeConfigurable)
    }, [attributeConfigurable])

    const {
        mutateAsync: changeConfigurability,
        isLoading: changeConfigurabilityLoading,
    } = useMutation({
        mutationFn: (state: boolean) => {
            return api[state ? "post" : "delete"](
                `v1/series/${entityData.series}/items-group/${entityData.group}/attribute/${data.attribute.id}/link`,
                {}
            )
                .then((response) => {
                    if (state) {
                        if (activeLink) return
                        const data = response.data as ModelAttribute
                        setActiveLink(data)
                        onSelect(data.attribute.id)
                    } else {
                        if (activeLink?.attribute.id === data.attribute.id) {
                            setActiveLink(undefined)
                        }
                    }
                    return response.data
                })
                .then(() => setChecked(state))
        },
    })

    const handleClick = (v: boolean) => {
        changeConfigurability(v)
    }

    const onRowClick = () => {
        onSelect(attribute.id)
    }

    return (
        <tr
            key={attribute.id}
            className={`${classes.tableClickableRow} ${
                isActive ? classes.activeRow : undefined
            }`}
            onClick={onRowClick}
        >
            <td className={classes.checkboxCell}>
                {isSeries && !changeConfigurabilityLoading && (
                    <input
                        style={{ cursor: "pointer" }}
                        onClick={(e) => e.stopPropagation()}
                        className="form-check-input"
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => {
                            handleClick(e.target.checked)
                        }}
                    />
                )}
                {changeConfigurabilityLoading && <Spin size="small" />}
            </td>
            <td className={classes.idCell}>
                <span className={classes.idLineWrapper}>{attribute.id}</span>
                <span className={classes.idLineWrapper}>{attribute.code}</span>
            </td>
            <td className={classes.valueCell}>{attribute.name}</td>
        </tr>
    )
}

export const AttributesChooser: React.FC<ItemsGroupRowsChooserProps> = (
    props
) => {
    const {
        onChangeActive,
        activeLink,
        entityType,
        entityData,
        setActiveLink,
        attributes: attributesList,
    } = props

    const classes = useStyles()
    const [search, setSearch] = useState("")

    const attributes = useMemo(() => {
        return search
            ? attributesList.filter(
                  ({ attribute: { name, id } }) =>
                      name.toLowerCase().includes(search.toLowerCase()) ||
                      id.toString().includes(search)
              )
            : attributesList
    }, [attributesList, search])

    // @ts-ignore
    return (
        <LoadingOverlay
            active={props.loading === true}
            styles={{
                overlay: {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    textAlign: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: "800",
                    transition: "opacity 500ms ease-in",
                    opacity: 1,
                    backdropFilter: "blur(15px) grayscale(1)",
                },
            }}
            spinner={
                <div
                    className="spinner-border text-primary"
                    role="status"
                ></div>
            }
        >
            <h5>Свойства</h5>
            <div>
                <input
                    className={classes.searchBoxInput}
                    type="text"
                    value={search}
                    placeholder="Поиск"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <table
                className="table table-bordered table-users"
                style={{
                    display: "block",
                    width: "100%",
                }}
            >
                <thead>
                    <tr>
                        <th className={classes.checkboxCell}></th>
                        <th className={classes.idCell}>ID</th>
                        <th className={classes.valueCell}>Свойства</th>
                    </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {attributes.map((data, idx) => (
                        <AttibuteItem
                            data={data}
                            isActive={props.active === data.attribute.id}
                            key={"attribute-" + idx}
                            onSelect={onChangeActive}
                            activeLink={activeLink}
                            isSeries={entityType === EntityType.series}
                            entityData={entityData}
                            setActiveLink={setActiveLink}
                        />
                    ))}
                </tbody>
            </table>
        </LoadingOverlay>
    )
}
