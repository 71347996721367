import { Button, Card, DatePicker, Form, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import { DefaultOptionType } from "antd/es/select"
import { FormProps } from "antd/lib"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import UNumberItem, { getUNumberValue } from "components/UNumberItem"
import { isDefined, isNull } from "helpers/checkType"
import { useMemo, useState } from "react"
import { PriceFormField } from "./CostCollector"
import classes from "./CostCollector.module.sass"
import { ChangeModelAction, useCostCollectorStore } from "./CostCollector.store"
import CostCollectorModelsModal from "./CostCollectorModelsModal"

export enum OperationType {
    SetPrice = "newPrice",
    MarkupValue = "markupValue",
    SetMarkupPercent = "markupPercent",
    ChangeMarkupPercent = "changeMarkupPercent",
    ChangePrice = "changePrice",
    ChangePriceOnPercent = "changePriceOnPercent",
    SetPrice2 = "setPrice2",
    ChangePrice2 = "changePrice2",
    ChangePriceOnPercent2 = "changePriceOnPercent2",
}

export const operationTypeOptions: DefaultOptionType[] = [
    {
        label: "Установить цену",
        value: OperationType.SetPrice,
    },
    {
        label: "Установить сумму наценки",
        value: OperationType.MarkupValue,
    },
    {
        label: "Установить процент наценки",
        value: OperationType.SetMarkupPercent,
    },
]

export const attributesOperaionTypeOptions: DefaultOptionType[] = [
    {
        label: "Установить цену",
        value: OperationType.SetPrice,
    },
    {
        label: "Изменить цену",
        value: OperationType.ChangePrice,
    },
    {
        label: "Изменить цену на процент",
        value: OperationType.ChangePriceOnPercent,
    },
    {
        label: "Установить процент наценки",
        value: OperationType.SetMarkupPercent,
    },
    {
        label: "Изменить процент наценки",
        value: OperationType.ChangeMarkupPercent,
    },
    {
        label: "Установить цену 2",
        value: OperationType.SetPrice2,
    },
    {
        label: "Изменить цену 2",
        value: OperationType.ChangePrice2,
    },
    {
        label: "Изменить цену на процент 2",
        value: OperationType.ChangePriceOnPercent2,
    },
]

export const CostCollectorSelectRowsAction = ({
    refetch,
}: {
    refetch: () => void
}) => {
    const {
        selectedRows,
        massChangeModelData,
        showSendModal,
        modelPrecision,
        setModelPrecision,
        modelDate,
        setModelDate,
        modelOperationType: operationType,
        setModelOperationType: setOperationType,
        seriesTableData,
    } = useCostCollectorStore()

    const [form] = useForm()

    const [value, setValue] = useState<number | null>(null)

    const onFinish: FormProps["onFinish"] = (values) => {
        massChangeModelData(
            values[PriceFormField.operationType] as ChangeModelAction,
            getUNumberValue(values[PriceFormField.value]) ?? 0,
            values[PriceFormField.rounding]
        )
    }

    const valueLabelTitle = useMemo(() => {
        switch (operationType) {
            case OperationType.SetPrice: {
                return "Значение цены"
            }
            case OperationType.MarkupValue: {
                return "Значение наценки"
            }
            case OperationType.SetMarkupPercent: {
                return "Процент"
            }
            default: {
                return "Значение"
            }
        }
    }, [operationType])

    return (
        <Flex.Col fullWidth className={"--visible"}>
            <Card className="fw">
                <Flex.Col fullWidth gap={10}>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        labelCol={{
                            xs: {
                                span: 24,
                            },
                            md: {
                                span: 5,
                            },
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            xs: {
                                span: 24,
                            },
                            md: {
                                span: 19,
                            },
                        }}
                        className="fw"
                    >
                        <Form.Item
                            label="Дата установки цен"
                            name={PriceFormField.priceSettingDate}
                        >
                            <DatePicker
                                value={modelDate}
                                defaultValue={modelDate || undefined}
                                className={classes.priceForm_item}
                                onChange={setModelDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Тип операции"
                            name={PriceFormField.operationType}
                        >
                            <Select
                                className={classes.priceForm_item}
                                style={{ maxWidth: 400 }}
                                options={operationTypeOptions}
                                allowClear
                                onChange={(v) => {
                                    setValue(null)
                                    form.setFieldValue("value", "")
                                    setOperationType(v)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={valueLabelTitle}
                            name={PriceFormField.value}
                            className={clsx({
                                "--visible": !!operationType,
                                "--hidden": !operationType,
                            })}
                            wrapperCol={{ span: 8 }}
                        >
                            <UNumberItem
                                className={clsx([
                                    "form-control form-control-sm",
                                    classes.priceForm_item,
                                ])}
                                handleChange={(v) => setValue(v)}
                                useInForm={{
                                    name: "value",
                                }}
                                floatRank={2}
                                bordered
                            />
                        </Form.Item>
                        <Form.Item
                            label="Задать округление"
                            name={PriceFormField.rounding}
                            className={clsx({
                                "--visible": !!operationType,
                                "--hidden": !operationType,
                            })}
                        >
                            <Select
                                value={modelPrecision}
                                onChange={setModelPrecision}
                                className={classes.priceForm_item}
                                style={{ width: "auto" }}
                                options={[
                                    {
                                        label: "0,01",
                                        value: -2,
                                    },
                                    {
                                        label: "0,1",
                                        value: -1,
                                    },
                                    {
                                        label: 0,
                                        value: 0,
                                    },
                                    {
                                        label: 10,
                                        value: 1,
                                    },
                                    {
                                        label: 100,
                                        value: 2,
                                    },
                                ]}
                            />
                        </Form.Item>
                        <FormLine>
                            <Flex.Row gap={20} justify="start">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={classes.formButton}
                                    disabled={
                                        !selectedRows.length ||
                                        isNull(value) ||
                                        !operationType
                                    }
                                >
                                    Применить настройку на выбранные модели
                                </Button>
                                <Button
                                    type="primary"
                                    className={classes.formButton}
                                    disabled={seriesTableData.every(
                                        (el) =>
                                            (!el.newPrice ||
                                                el.newPrice === el.oldPrice) &&
                                            !isDefined(el.markupValue) &&
                                            !isDefined(el.markupPercent)
                                    )}
                                    onClick={showSendModal}
                                >
                                    Предварительный просмотр цен
                                </Button>
                            </Flex.Row>
                        </FormLine>
                    </Form>
                </Flex.Col>
            </Card>
            <CostCollectorModelsModal
                refetch={() => {
                    form.resetFields([
                        PriceFormField.value,
                        PriceFormField.rounding,
                    ])
                    setValue(null)
                    form.setFieldValue("value", "")
                    refetch()
                }}
            />
        </Flex.Col>
    )
}
