export const pageIDs = {
    shop: "shop",
    store: "store",
    price: "price",
    region: "region",
    currency: "currency",
    priceType: "price-type",
    tagPage: "tag-page",
    group: "items-group",
    producer: "producer",
    tnved: "tnved",
    okrb: "okrb",
    classificationAccordance: "classification-accordance",
    attribute: "attribute",
    good: "good",
    series: "series",
    model: "model",
    nomenclature: "nomenclature",
    nomenclatureMassGeneration: "mass-generation",
    category: "catalog",
    import: "import",
    importLog: "import-log",
    role: "role",
    user: "user",
    pictureDemo: "layer-by-layer",
    readyPicturesConfigurator: "ready-pictures-configurator",
    pictureMassUploader: "picture-mass-uploader",
    picturesSettings: "settings",
    importedCategory: "imported-category",
    uploadedAttribute: "uploaded-attribute",
    modelsAttributesValuesBindingConfigurator:
        "models-attributes-values-binding",
    attributeValuesCompatibilityConfigurator: "attribute-values-compatibility",
    generalConfigurator: "configurator",
    generalConfiguratorWithCollections: "configurator-collections",
    massAttributeResplace: "attribute-replace",
    attributeValuesСompatibility:
        "configuration/attribute-values-compatibility",
    kits: "kits",
    deliveryTime: "delivery-time-filter",
    modelAttribute: "model-attribute",
    costCollector: "cost-collector",
    economicSeries: "economic/series",
    economicProducer: "producer",
    economicNomeclatureFilters: "price-nomenclature-filter",
    priceMarginPreset: "price-margin-preset",
    calculationRules: "price-calculation-rule",
    modelAttributeValueCriteria: "model-attribute-value-criteria",
    pricesRecalculate: "prices-recalculate",
    attributeValuesPrices: "economic/attribute-values-prices",
    attributeCombination: "attribute-combination",
    staller: "model-value-staller",
    availabilityNomenclatureFilters: "availability-nomenclature-filter",
    requisites: "requisites",
    standardKit: "standard-kit",
    setNomenclaturePrices: "set-nomenclature-prices",
    collections: "compatible-collection",
    test: "test",
} as const

export type PageID = keyof typeof pageIDs
