import { ItemType } from "types/types"

export enum AttributeCopyKeys {
    model = "model",
    modelSeries = "model-series",
    modelProducer = "model-producer",
}

export const menuData: ItemType[] = [
    {
        label: "Модель",
        key: AttributeCopyKeys.model,
    },
    {
        label: "Модель - серия",
        key: AttributeCopyKeys.modelSeries,
    },
    {
        label: "Модель - поставщик",
        key: AttributeCopyKeys.modelProducer,
    },
]
