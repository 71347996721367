import { useQuery } from "@tanstack/react-query"
import { Form, Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { FormItemProps } from "antd/lib"
import api from "helpers/api"
import { Uuid } from "types"

export const selfCostValue = "Цена от поставщика"

export function PriceTypeFormSelect({
    ...props
}: FormItemProps & {
    region: string | null
    disabled?: boolean
    selfCost?: boolean
    onChange?: (v: string) => void
}) {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: [
            "v1/price-calculation-rule/calculation-type",
            props.region,
            props.selfCost,
        ],
        queryFn: () =>
            api
                .getTyped<Record<Uuid, string>>(
                    "v1/price-calculation-rule/calculation-type",
                    {
                        region: props.region,
                    }
                )
                .then((response) => {
                    const responseObjects = Object.entries(response).map(
                        ([value, label]) => ({
                            value,
                            label,
                        })
                    )
                    const propsObjects = props.selfCost
                        ? [
                              {
                                  value: selfCostValue,
                                  label: "Цена от поставщика",
                              },
                          ]
                        : []
                    return [...responseObjects, ...propsObjects]
                }),
        initialData: [],
    })

    return (
        <Form.Item {...props}>
            <Select
                options={data}
                disabled={props.disabled}
                loading={isFetching}
                size="large"
                allowClear
                onChange={props.onChange}
            />
        </Form.Item>
    )
}
