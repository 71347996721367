import { Input } from "antd"
import { Uuid } from "types"

type State<T> = ({ id: Uuid; error?: boolean } & {
    [K in keyof T]: K extends "checked" ? boolean : T[K]
})[]

interface IPropertiesInputProps<T> {
    value?: string
    error: boolean
    sendChange: () => void
    attribute: keyof T
    setValue: (val: State<T> | ((prev: State<T>) => State<T>)) => void
    id: Uuid
}

const MasksInput = <T,>({
    sendChange,
    error,
    attribute,
    setValue,
    value,
    id,
}: IPropertiesInputProps<T>) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value

        setValue((state) =>
            state.map((item) => ({
                ...item,
                [attribute]: item.id === id ? val : item[attribute],
            }))
        )
    }

    const onBlur = () => {
        sendChange()
    }

    const onFocus = () => {
        setValue((state) =>
            state.map((item) => ({
                ...item,
                error: item.id === id ? false : item.error,
            }))
        )
    }

    return (
        <Input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            status={error ? "error" : ""}
        />
    )
}

export default MasksInput
