import { useQuery } from "@tanstack/react-query"
import { Spin, Typography } from "antd"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { Uuid } from "types"
import { Model } from "types/api"
import { getDateString } from "utils/date"
import classes from "./StandartKitLogItem.module.sass"
import { StandardLogItem } from "./StandartKits"

interface ImportCountry {
    id: string
    name: string
    code: string
    alpha2: string
    alpha3: string
}

interface ItemsGroup {
    id: string
    name: string
    isActive: boolean
    shortRepresentation: ShortRepresentation
    isPublished: boolean
}

interface Kit {
    id: string
    itemsGroup: ItemsGroup
    producer: Producer
    series: Series
    model: Model | null
    createdAt: string
    kitValues: KitValues[]
    name: string
}

interface KitValues {
    id: string
    kit: string
    itemGroup: ItemsGroup
    isGroupRequired: boolean
    pcCount: string
    kitCount: string
    query: Query
    valueOrder: string
}

interface Owner {
    id: string
    email: string
    password: string
    roles: any
    first_name: string
    last_name: string
    phone: string
    is_active: boolean
}

interface Producer {
    id: string
    name: string
    importCountry: ImportCountry
    producingCountry: ImportCountry
    isPublished: boolean
}

interface Query {
    id: string
    not: boolean
    rules: Rules[]
    combinator: string
}

interface Rules {
    id: string
    field: string
    value: string
    operator: string
    valueSource: string
}

interface Series {
    id: string
    name: string
    deliveryTime: number
    copyType: string
    isPublished: boolean
    producer: Producer
    shortRepresentation: ShortRepresentation
    itemsGroups: ItemsGroup[]
}

interface ShortRepresentation {
    code: string
    name: string
}

interface LogResponse {
    id: string
    owner: Owner
    kit: Kit
    taskCount: number
    taskSuccess: number
    taskFailed: number
    log: string[]
    errors: string[]
    createdAt: string
}

function StandartKitLogItem({
    data,
    visibleItem,
    toggleItem,
}: {
    data: StandardLogItem
    visibleItem: Uuid | null
    toggleItem: (v: Uuid) => void
}) {
    const { data: logData, isFetching } = useQuery<LogResponse>({
        queryKey: [
            "reference/standard-kit-generation-log",
            data.id,
            visibleItem,
        ],
        enabled: visibleItem === data.id,
        queryFn: () =>
            api.getTyped(`reference/standard-kit-generation-log/${data.id}`),
    })

    return (
        <Flex.Col
            fullWidth
            className={classes.collapse}
            onClick={() => toggleItem(data.id)}
        >
            <div className={classes.collapseHeader}>
                <div className={classes.logHeader}>
                    <Typography.Text
                        style={{
                            fontWeight: "bold",
                            marginRight: 5,
                        }}
                    >
                        {getDateString(data.createdAt)}
                    </Typography.Text>
                    <Typography.Text ellipsis>
                        {data.log.length
                            ? data.log.join("; ")
                            : "Нет сгенерированных комплектов"}
                    </Typography.Text>
                </div>
            </div>

            <div
                className={clsx("fw", {
                    [classes.collapseBody]: true,
                    [classes.collapseBody__visible]: visibleItem === data.id,
                    [classes.collapseBody__hidden]: visibleItem !== data.id,
                })}
            >
                {isFetching && !logData && (
                    <Flex.Col fullWidth>
                        <Spin />
                    </Flex.Col>
                )}
                {logData && (
                    <>
                        <Flex.Row gap={10} fullWidth justify="start">
                            <Typography.Text strong>
                                Название шаблона
                            </Typography.Text>
                            <Typography.Text>
                                {logData.kit.name}
                            </Typography.Text>
                        </Flex.Row>

                        <Flex.Row gap={10} fullWidth justify="start">
                            <Typography.Text strong>Серия</Typography.Text>
                            <Typography.Text>
                                {logData.kit.series.name}
                            </Typography.Text>
                        </Flex.Row>

                        {logData.kit.model && (
                            <Flex.Row gap={10} fullWidth justify="start">
                                <Typography.Text strong>Модель</Typography.Text>
                                <Typography.Text>
                                    {logData.kit.model.name}
                                </Typography.Text>
                            </Flex.Row>
                        )}

                        {!!logData.errors?.length && (
                            <Flex.Col gap={10} fullWidth align="start">
                                <Typography.Text type="danger">
                                    Ошибки
                                </Typography.Text>
                                {logData.errors.map((error, idx) => (
                                    <Typography.Text
                                        type="danger"
                                        key={`error-${idx}`}
                                    >
                                        {error}
                                    </Typography.Text>
                                ))}
                            </Flex.Col>
                        )}
                    </>
                )}
            </div>
        </Flex.Col>
    )
}

export default StandartKitLogItem
