import { useQuery } from "@tanstack/react-query"

import api from "helpers/api"
import { FC, useEffect, useState } from "react"

import NamingSelectWithActions from "components/NamingSelectWithActions"
import { Flex } from "components/UI/Flex"
import { getCurrentAttributes } from "pages/NameGenerationSettings/helper"
import { useNameGenerationSettingsStore } from "pages/NameGenerationSettings/NameGenerationSettings.store"
import { AttributeListItem } from "types"
import SettingsModels from "../SettingsModels"

interface IValue {
    value: string
    label: string
    sort: number
}

interface IProps {
    activeSeriesId: string
    accountingNameAttributes?: IValue[]
    nameAttributes?: IValue[]
}

const SettingSeries: FC<IProps> = ({
    activeSeriesId,
    accountingNameAttributes = [],
    nameAttributes = [],
}) => {
    const {
        activeGroupId,
        setSeriesAttributes,
        copyAllSeriesAttributes,
        allSeries,
    } = useNameGenerationSettingsStore()
    const [options, setOptions] = useState<IValue[] | []>([])

    const { data, isFetched } = useQuery<AttributeListItem[]>({
        queryKey: [
            `v1/series/${activeSeriesId}/items-group/${activeGroupId}/attribute-links`,
            activeGroupId,
        ],
        enabled: !!activeSeriesId && !!activeGroupId,
        cacheTime: 0,
        queryFn: () =>
            api.getTyped(
                `v1/series/${activeSeriesId}/items-group/${activeGroupId}/attribute-links`
            ),
    })

    useEffect(() => {
        const {
            options: defaultOptions,
            currentAccountingAttributes,
            currentNameAttributes,
        } = getCurrentAttributes(data)

        setOptions(defaultOptions)
        setSeriesAttributes({
            typeAttribute: "name",
            value: currentNameAttributes,
            id: activeSeriesId,
        })
        setSeriesAttributes({
            typeAttribute: "accounting",
            value: currentAccountingAttributes,
            id: activeSeriesId,
        })
    }, [data])

    if (!isFetched) {
        return <div>Загрузка...</div>
    }

    const dubName = allSeries.find((item) => item.id === activeSeriesId)
        ?.models?.[0]?.label

    return (
        <Flex.Col>
            <NamingSelectWithActions
                dubName={dubName}
                attributes={data}
                values={{
                    accountingNameAttributes: accountingNameAttributes,
                    nameAttributes: nameAttributes,
                }}
                onChange={(prop) =>
                    setSeriesAttributes({
                        ...prop,
                        id: activeSeriesId,
                    })
                }
                copy={(prop) =>
                    copyAllSeriesAttributes({
                        ...prop,
                        id: activeSeriesId,
                    })
                }
                type="series"
                options={options}
            />
            <SettingsModels activeSeriesId={activeSeriesId} />
        </Flex.Col>
    )
}

export default SettingSeries
