import { useMutation } from "@tanstack/react-query"
import { Spin } from "antd"
import api from "helpers/api"
import { isDiemnsionAttribute } from "helpers/isDimensionAttribute"
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { createUseStyles } from "react-jss"
import LoadingOverlay from "react-loading-overlay-ts"
import { Uuid } from "types"
import AttributValuesDimensionsParse from "./AttributeValuesDimensionsParse/AttributeValuesDimensionsParse"
import { ActiveEntityType, EntityType, ModelAttribute } from "./types"

const useStyles = createUseStyles({
    valueCell: {
        width: "100%",
    },
    idCell: {
        minWidth: 160,
        textAlign: "center",
        display: "block",
        overflow: "hidden",
        height: "30px",
        width: "35px",
        border: "none",
    },
    headlineCell: {
        width: "100%",
    },
    searchCell: {
        width: "100%",
    },
    searchLabel: {
        paddingLeft: 15,
    },
    tableBody: {
        height: "450px",
        display: "block",
    },
    searchNotFound: {
        textAlign: "center",
    },
    searchBoxInput: {
        width: "100%",
        outline: "none",
        border: "1px solid #dee2e6",
        borderBottom: "none",
        padding: "5px 10px",
    },
    tableClickableRow: {
        cursor: "pointer",
        "&:first-child": {
            borderTop: "none",
        },
        "&:hover": {
            background: "#eee",
        },
    },
    activeRow: {
        background: "#f1f1f1",
    },
    exportCell: {
        minWidth: "90px",
        textAlign: "center",
    },

    checkboxCell: {
        minWidth: "30px",
    },
})

const ValueItem = ({
    valueId,
    value,
    entityType,
    groupId,
    isActive,
    linkId,
    isNamingActive,
    changeConfigurability,
    seriesId,
}: {
    valueId: Uuid
    value: string
    entityType?: EntityType
    isActive?: boolean
    isNamingActive: boolean
    groupId: Uuid
    linkId: Uuid
    seriesId?: Uuid
    changeConfigurability: (valueId: Uuid, state: boolean) => void
}) => {
    const classes = useStyles()

    const [configurable, setConfigurable] = useState(!!isActive)
    const [namingActive, setNamingActive] = useState(isNamingActive)

    const mutationNaming = useMutation({
        mutationFn: (state: boolean) =>
            entityType === EntityType.model
                ? api
                      .post(
                          `model/attribute-link-set-attribute-value-naming-applicability`,
                          {},
                          {
                              id: linkId,
                              value: valueId,
                              state,
                          }
                      )
                      .then(() => {
                          setNamingActive(state)
                      })
                      .catch(() => {
                          setNamingActive(!state)
                      })
                : api
                      .put(
                          `v1/series/${seriesId}/items-group/${groupId}/value/${valueId}/naming-applicability`,
                          {},
                          {
                              namingApplicability: state,
                          }
                      )
                      .then(() => {
                          setNamingActive(state)
                      })
                      .catch(() => {
                          setNamingActive(!state)
                      }),
    })

    const { mutate: configure, isLoading: configureLoading } = useMutation({
        mutationFn: (state: boolean) => {
            if (entityType === EntityType.model) {
                return api
                    .post(
                        `${entityType}/attribute-link-set-attribute-value-configurability`,
                        {},
                        {
                            id: linkId,
                            value: valueId,
                            state,
                        }
                    )
                    .then(() => {
                        setConfigurable(state)
                        return state
                    })
                    .catch(() => {
                        setConfigurable(!state)
                    })
            } else {
                return state
                    ? api
                          .post(
                              `v1/series/${seriesId}/items-group/${groupId}/value/link/${valueId}`,
                              {},
                              {
                                  state,
                              }
                          )
                          .then(() => {
                              setConfigurable(state)
                              return state
                          })
                          .catch(() => {
                              setConfigurable(!state)
                          })
                    : api
                          .delete(
                              `v1/series/${seriesId}/items-group/${groupId}/value/link/${valueId}`
                          )
                          .then(() => {
                              setConfigurable(state)
                              return state
                          })
                          .catch(() => {
                              setConfigurable(!state)
                          })
            }
        },
        onSuccess: (data) => {
            changeConfigurability(valueId, data as boolean)
        },
    })

    const onChangeConfigurability = async (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        e.stopPropagation()
        configure(e.target.checked)
    }

    const onChangeNaming = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        mutationNaming.mutate(e.target.checked)
    }

    useEffect(() => {
        setConfigurable(!!isActive)
    }, [isActive])

    useEffect(() => {
        setNamingActive(isNamingActive)
    }, [isNamingActive])

    return (
        <tr key={valueId}>
            <td className={classes.checkboxCell}>
                {configureLoading && <Spin size="small" />}
                {!configureLoading && (
                    <input
                        className="form-check-input"
                        checked={configurable}
                        type="checkbox"
                        onChange={onChangeConfigurability}
                    />
                )}
            </td>
            <td className={classes.idCell}>{valueId}</td>
            <td className={classes.valueCell}>{value}</td>
            <td className={classes.exportCell}>
                {mutationNaming.isLoading && <Spin size="small" />}
                {isActive && !mutationNaming.isLoading && (
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={namingActive}
                        onChange={onChangeNaming}
                    />
                )}
            </td>
        </tr>
    )
}

interface ItemsGroupRowsChooserProps {
    activeLink?: ModelAttribute
    activeEntity?: ActiveEntityType
    groupId: Uuid
    refetchAttribute: () => void
}

export const AttributeValuesChooser: React.FC<ItemsGroupRowsChooserProps> = ({
    activeLink,
    groupId,
    refetchAttribute,
    activeEntity,
}) => {
    const classes = useStyles()
    const [search, setSearch] = useState("")
    const [configurableAttributeValues, setConfigurableAttributeValues] =
        useState<string[]>(
            activeLink?.configurableAttributeValues
                ? Object.values(activeLink.configurableAttributeValues)
                : []
        )
    const {
        mutate: setMassConfigure,
        data: massConfigureResponse,
        isLoading: massConfigureLoading,
    } = useMutation(
        (state: boolean) =>
            activeEntity?.entity === EntityType.model
                ? api
                      .post<any, ModelAttribute>(
                          `v1/model/attribute-link/bulk/configurability/${
                              state ? "link" : "unlink"
                          }`,
                          {},
                          {
                              id: activeLink!.id,
                              values: filteredRows.map(([key]) => key),
                          }
                      )
                      .then((response) => response.data)
                : state
                ? api
                      .post<any, ModelAttribute>(
                          `v1/series/${activeEntity?.id}/items-group/${groupId}/value/links`,
                          {},
                          {
                              values: filteredRows.map(([key]) => key),
                          }
                      )
                      .then((response) => response.data)
                : api
                      .post<any, ModelAttribute>(
                          `v1/series/${activeEntity?.id}/items-group/${groupId}/value/links/remove`,
                          {},
                          {
                              values: filteredRows.map(([key]) => key),
                          }
                      )
                      .then((response) => response.data),
        {
            onSettled: refetchAttribute,
        }
    )

    const isDimension = useMemo(() => {
        return activeLink?.attribute
            ? isDiemnsionAttribute(activeLink?.attribute)
            : false
    }, [activeLink])

    const filteredRows = useMemo(() => {
        if (!activeLink) return []
        const valuesList = activeLink.attribute.valuesList

        const pairs = valuesList ? Object.entries(valuesList) : []

        const dimensionsFiltredParse = isDiemnsionAttribute(
            activeLink?.attribute
        )
            ? pairs.filter(([key]) => {
                  return activeLink.configurableAttributeValues.includes(key)
              })
            : pairs

        return search
            ? dimensionsFiltredParse.filter(([key, value]) => {
                  return `${key} ${value}`.toLocaleLowerCase().includes(search)
              })
            : dimensionsFiltredParse
    }, [search, activeLink, isDimension])

    const allChosen = useMemo(() => {
        return configurableAttributeValues.length === filteredRows.length
    }, [configurableAttributeValues, filteredRows])

    const changeConfigurability = useCallback(
        (valueId: string, state: boolean) => {
            setConfigurableAttributeValues((prev) => {
                if (state) {
                    return prev.includes(valueId) ? prev : [...prev, valueId]
                }
                return prev.filter((el) => el !== valueId)
            })
            refetchAttribute()
        },
        [setConfigurableAttributeValues, refetchAttribute]
    )

    useEffect(() => {
        if (!massConfigureResponse?.configurableAttributeValues) return
        setConfigurableAttributeValues(
            massConfigureResponse.configurableAttributeValues
                ? (Object.values(
                      massConfigureResponse.configurableAttributeValues
                  ) as string[])
                : []
        )
    }, [massConfigureResponse])

    useEffect(() => {
        if (!activeLink?.configurableAttributeValues) return
        setConfigurableAttributeValues(
            Object.values(activeLink.configurableAttributeValues)
        )
    }, [activeLink])

    // TODO Необходимо разобратсья с типом значений, костыль был написан для кейсов когда с бека в разных случаях прилетали разные типы.
    const namingApplicableAttributeValues = useMemo(() => {
        if (!activeLink?.namingApplicableAttributeValues) return []
        if (typeof activeLink.namingApplicableAttributeValues === "object") {
            return Object.values(activeLink.namingApplicableAttributeValues)
        }
        if (Array.isArray(activeLink.namingApplicableAttributeValues))
            return activeLink.namingApplicableAttributeValues
        return []
    }, [activeLink])

    const { mutate: postAttributeDimensions } = useMutation(
        (dimensions: number[]) =>
            api.post(
                `v1/attribute/mass-link/series-model-values-link`,
                {},
                {
                    series: activeLink?.series?.id,
                    attributeId: activeLink?.attribute.id,
                    itemsGroup: groupId,
                    ...(activeLink?.modelId
                        ? { model: activeLink?.modelId }
                        : {}),
                    dimensions,
                }
            ),
        {
            onSuccess: refetchAttribute,
        }
    )

    // @ts-ignore
    return (
        <LoadingOverlay
            active={false}
            styles={{
                overlay: {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    textAlign: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: "800",
                    transition: "opacity 500ms ease-in",
                    opacity: 1,
                    backdropFilter: "blur(15px) grayscale(1)",
                },
            }}
            spinner={
                <div
                    className="spinner-border text-primary"
                    role="status"
                ></div>
            }
        >
            <h5>Значения</h5>
            <AttributValuesDimensionsParse
                attribute={activeLink?.attribute}
                resolveDimensions={postAttributeDimensions}
            />
            <div>
                <input
                    className={classes.searchBoxInput}
                    type="text"
                    value={search}
                    placeholder="Поиск"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <table
                className="table table-bordered table-users"
                style={{
                    display: "block",
                    width: "100%",
                }}
            >
                <thead>
                    <tr className="no-border-bottom">
                        <th className={classes.checkboxCell}>
                            {massConfigureLoading && <Spin size="small" />}
                            {!massConfigureLoading && (
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={allChosen}
                                    onChange={() =>
                                        setMassConfigure(!allChosen)
                                    }
                                />
                            )}
                        </th>
                        <th className={classes.idCell}>ID</th>
                        <th className={classes.valueCell}>Значения</th>
                        <th className={classes.exportCell}></th>
                    </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {activeLink &&
                        filteredRows.map(([valueId, value]) => (
                            <ValueItem
                                valueId={valueId}
                                value={value}
                                isActive={configurableAttributeValues?.includes(
                                    valueId
                                )}
                                linkId={activeLink?.id}
                                key={valueId}
                                groupId={groupId}
                                isNamingActive={namingApplicableAttributeValues.includes(
                                    valueId
                                )}
                                changeConfigurability={changeConfigurability}
                                entityType={activeEntity?.entity}
                                seriesId={
                                    activeEntity?.parentId ?? activeEntity?.id
                                }
                            />
                        ))}
                </tbody>
            </table>
        </LoadingOverlay>
    )
}
