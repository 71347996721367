type ObjectColumnData = {
    value: any | null
}

const DEFAULT_MEASURE_VALUE = "шт."

export default function MeasureCell({ value }: ObjectColumnData) {
    if (typeof value === "string") {
        return <span>{value}</span>
    }

    return <span>{value?.name || DEFAULT_MEASURE_VALUE}</span>
}
