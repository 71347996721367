import { QueryBuilderAntD } from "@react-querybuilder/antd"
import { Button } from "antd"
import Card from "antd/es/card/Card"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useCallback, useEffect, useMemo, useState } from "react"
import type { QueryBuilderProps, RuleGroupType } from "react-querybuilder"
import { Uuid } from "types"
import QueryBuilderAddItem from "./QueryBuilderAddItem"
import QueryBuilderItem from "./QueryBuilderItem"
import { QueryBuilderItemData } from "./types"

type CompinatorItem = { name: string; label: string; id: string }

export type QueryGetEnitiesResponse = {
    items: Record<Uuid, string>
    combinators: CompinatorItem[]
    operators: CompinatorItem[]
}

export type QueryBuilderComponentProps<T> = {
    crudURL: string
    getEntityTransfromFunc?: (v: any) => QueryBuilderItemData<T>[]
    translations?: QueryBuilderProps["translations"]
    renderItem?: (props: {
        data: QueryBuilderItemData<T>
        index: number
        onDataChange: (index: number, key: keyof T, value: any) => void
    }) => JSX.Element
    controlElements?: QueryBuilderProps["controlElements"]
    getControlElements?: (
        data: QueryBuilderItemData<T>
    ) => QueryBuilderProps["controlElements"]
    getDefaultData: () => T
    handleSaveData: (data: QueryBuilderItemData<T>[]) => Promise<void>
    editItemId?: Uuid | null
    resolveGetEntitiesData: (
        v: T
    ) => { url: string; params?: Record<string, string> } | undefined
    noEntitiesText?: string
    queryItemClassName?: string
    showDataByFilter?: (props: { data: QueryBuilderItemData<T> }) => JSX.Element
    resetQueryKeys?: Array<keyof T>
    topContent?: JSX.Element
    disableSave?: boolean
    context?: any
    noSave?: boolean
    bottomAdditionButtons?: Array<JSX.Element>
}

export default function QueryBuilderRoot<T>({
    crudURL,
    getEntityTransfromFunc,
    translations = {
        addGroup: { label: "Добавить группу" },
        addRule: { label: "Добавить правило" },
    } as any,
    renderItem,
    controlElements,
    getControlElements,
    getDefaultData,
    handleSaveData,
    editItemId,
    resolveGetEntitiesData,
    noEntitiesText = "Нет данных",
    queryItemClassName,
    showDataByFilter,
    resetQueryKeys,
    topContent,
    disableSave,
    context = {},
    noSave,
    bottomAdditionButtons,
}: QueryBuilderComponentProps<T>) {
    const getDefaultBuilderItems = (): QueryBuilderItemData<T>[] => {
        const itemData = getDefaultData()
        return [
            {
                ...itemData,
                query: undefined,
            },
        ]
    }

    const [builderItems, setBuilderItems] = useState<QueryBuilderItemData<T>[]>(
        getDefaultBuilderItems()
    )

    useEffect(() => {
        if (editItemId) {
            api.getTyped<QueryBuilderItemData<T>[]>(
                `${crudURL}/${editItemId}`
            ).then((data) => {
                const items = getEntityTransfromFunc
                    ? getEntityTransfromFunc(data)
                    : data
                setBuilderItems(items)
            })
        } else {
            setBuilderItems(getDefaultBuilderItems())
        }
    }, [crudURL, editItemId])

    const onQueryChange = useCallback(
        (query: RuleGroupType, index: number) => {
            setBuilderItems((prev) =>
                prev.map((el, indexToChange) =>
                    indexToChange === index
                        ? {
                              ...el,
                              query,
                          }
                        : el
                )
            )
        },
        [builderItems, setBuilderItems]
    )

    const removeItem = useCallback(
        (indexToRemove: number) => {
            setBuilderItems((prev) => {
                const newItems = prev.filter(
                    (_, index) => indexToRemove !== index
                )
                return newItems.length ? newItems : getDefaultBuilderItems()
            })
        },
        [builderItems, setBuilderItems]
    )

    const addItem = useCallback(() => {
        setBuilderItems((prev) => [...prev, getDefaultBuilderItems()[0]])
    }, [builderItems, setBuilderItems])

    const onDataChange = useCallback(
        (index: number, key: keyof T, value: any) => {
            setBuilderItems((prev) =>
                prev.map((el, indexToChange) => {
                    return indexToChange === index
                        ? {
                              ...el,
                              [key]: value,
                              query: resetQueryKeys?.includes(key)
                                  ? undefined
                                  : el.query,
                          }
                        : el
                })
            )
        },
        [builderItems, setBuilderItems]
    )

    const _disableSave = useMemo(() => {
        if (disableSave) return true
        return builderItems.some((el) => {
            if (!el.query?.rules?.length) return true
            return false
        })
    }, [builderItems, disableSave])

    const onSave = useCallback(() => {
        handleSaveData(builderItems).then(() => {
            if (!editItemId) {
                setBuilderItems(getDefaultBuilderItems())
            }
        })
    }, [handleSaveData, builderItems, setBuilderItems])

    return (
        <QueryBuilderAntD>
            <Card className="fw">
                {topContent}
                <Flex.Col gap={20}>
                    {builderItems.map((el, index) => (
                        <QueryBuilderItem
                            context={{ item: el, ...context }}
                            data={el}
                            key={index}
                            index={index}
                            onQueryChange={onQueryChange}
                            removeItem={removeItem}
                            renderItem={renderItem}
                            controlElements={
                                getControlElements
                                    ? getControlElements(el)
                                    : controlElements
                            }
                            translations={{ ...translations }}
                            onDataChange={onDataChange}
                            getBuilderEntitiesData={resolveGetEntitiesData(el)}
                            noEntitiesText={noEntitiesText}
                            queryItemClassName={queryItemClassName}
                            showDataByFilter={showDataByFilter}
                            editItemId={editItemId}
                        />
                    ))}
                </Flex.Col>
                <Flex.Col fullWidth>
                    <QueryBuilderAddItem onAddItem={addItem} />
                    <Flex.Row
                        fullWidth
                        gap={20}
                        justify="center"
                        align="center"
                        margin={[20, 0, 0]}
                    >
                        {!noSave && (
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                                disabled={_disableSave}
                            >
                                Сохранить
                            </Button>
                        )}
                        {bottomAdditionButtons && [...bottomAdditionButtons]}
                    </Flex.Row>
                </Flex.Col>
            </Card>
        </QueryBuilderAntD>
    )
}
