import React from "react"
import { Uuid } from "types"
import { getDateString } from "utils/date"

interface TextProps {
    id: Uuid
    value: any
    page: string
    listing: { [key: string]: any }
}

export const DateTime: React.FC<TextProps> = ({ value }) => {
    return <span>{getDateString(value)}</span>
}
