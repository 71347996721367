import { useQuery } from "@tanstack/react-query"
import { Col, Row, Typography } from "antd"
import api from "helpers/api"
import { useSeriesUpdateData } from "hooks/useSeriesUpdateData"
import { useMemo, useState } from "react"
import { Uuid } from "types"
import { AsyncSelect, Text } from "../UI"
import AccountingSelectSeries from "./AccountingSelectSeries"
import NamingSelectSeries from "./NamingSelectSeries"

export type LinkingDetailItem = {
    attribute: {
        code: Uuid
        datatype: string
        description: string
        id: Uuid
        isActive: boolean
        isRequired: boolean
        name: string
        sort: number
        valuesList: Record<Uuid, string>
    }
    attributeId: Uuid
    forAccountingNaming: boolean
    forNaming: boolean
    groupId: Uuid
    id: Uuid
    sortChoosing: number
    sortNaming: number
    sortAccountingNaming: number
}

export default function NamingSelect() {
    const { id: seriesId } = useSeriesUpdateData()

    const [activeGroup, setActiveGroup] = useState<
        { value: Uuid; label: string } | undefined
    >()

    const { data: linkedAttributesDetails = [] } = useQuery<
        LinkingDetailItem[]
    >({
        queryKey: [
            `v1/series/${seriesId}/items-group/${activeGroup?.value}/attribute-links`,
            seriesId,
            activeGroup?.value,
        ],
        enabled: !!seriesId && !!activeGroup?.value,
        queryFn: () =>
            api.getTyped(
                `v1/series/${seriesId}/items-group/${activeGroup?.value}/attribute-links`
            ),
    })

    const validLinks = useMemo(() => {
        return linkedAttributesDetails.filter((el) => el.id)
    }, [linkedAttributesDetails])

    return (
        <div>
            <Typography.Title level={4} className="mt-3">
                Наименования
            </Typography.Title>
            <Row gutter={[15, 10]}>
                <Col md={24} xl={6}>
                    <Text.FormLabel>Номенклатурная группа</Text.FormLabel>
                </Col>
                <Col md={24} xl={18}>
                    <AsyncSelect
                        placeholder="Выберите группу"
                        value={activeGroup}
                        onChange={(v) => setActiveGroup(v || undefined)}
                        className="mb-2"
                        dataUrl="items-group"
                    />
                </Col>
            </Row>
            {activeGroup && (
                <>
                    <NamingSelectSeries
                        activeGroupId={activeGroup.value}
                        attributes={validLinks}
                    />
                    <AccountingSelectSeries
                        activeGroupId={activeGroup.value}
                        attributes={validLinks}
                    />
                </>
            )}
        </div>
    )
}
