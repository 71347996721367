import { Card } from "antd"
import { Flex } from "components/UI/Flex"
import { useEffect } from "react"
import styles from "./nameGenerationSettings.module.scss"
import { useNameGenerationSettingsStore } from "./NameGenerationSettings.store"
import NameMasks from "./NameMasks"
import SelectionProducersAndGroups from "./SelectionProducersAndGroups"
import SettingValuesAttributesModel from "./SettingValuesAttributesModel"
import SettingValuesAttributesSeries from "./SettingValuesAttributesSeries"

const NameGenerationSettings = () => {
    const { activeGroupId, activeProducerId, activeSeriesId, reset } =
        useNameGenerationSettingsStore()

    useEffect(() => {
        return reset()
    }, [])

    return (
        <>
            <h1 className="h3 mb-3">Настройки формирования названий</h1>
            <Card className={styles.card}>
                <Flex.Col gap={24} align="start" fullWidth>
                    <SelectionProducersAndGroups />
                    {!!activeGroupId && !!activeProducerId && <NameMasks />}
                    {!!activeSeriesId &&
                        !!activeGroupId &&
                        !!activeProducerId && (
                            <>
                                <SettingValuesAttributesSeries />
                                <SettingValuesAttributesModel />
                            </>
                        )}
                </Flex.Col>
            </Card>
        </>
    )
}

export default NameGenerationSettings
