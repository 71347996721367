import { CloseOutlined } from "@ant-design/icons"
import clsx from "clsx"
import { useNotifications } from "hooks/useNotifications"
import { useRef } from "react"
import { TableProps } from "./types"
import { useScrollFinish } from "./useScrollFinish"

type TableRowsProps<T extends object> = Pick<
    TableProps<T>,
    | "columns"
    | "rowStyles"
    | "isRowActive"
    | "onRowClick"
    | "nested"
    | "textAlign"
    | "height"
    | "content"
    | "onScrollFinish"
    | "tableProps"
    | "onRowClose"
> & { data: T[]; isDisableFocusInput?: boolean }

export default function CommonTable<T extends object>({
    columns,
    rowStyles,
    data,
    isRowActive,
    onRowClick,
    nested,
    textAlign,
    height,
    content,
    onScrollFinish,
    tableProps,
    onRowClose,
    isDisableFocusInput,
}: TableRowsProps<T>) {
    const gridTemplateColumns = columns
        .map((el) => (el.width ? `${el.width}%` : "auto"))
        .join(" ")

    const dataRef = useRef<HTMLDivElement>(null)
    const { showNotification } = useNotifications()
    const { calclulateScroll } = useScrollFinish({ onScrollFinish })

    return (
        <div
            className="fw"
            style={{ overflow: "auto" }}
            onScroll={(e) => {
                const target = e.currentTarget as HTMLDivElement
                calclulateScroll({
                    containerHeight: target.clientHeight,
                    itemsHeight: dataRef.current!.clientHeight,
                    scrollTop: target.scrollTop,
                })
            }}
        >
            <div
                style={{
                    width: "100%",
                    position: "relative",
                }}
                ref={dataRef}
            >
                {data.map((row, itemId) => {
                    const styles = rowStyles ? rowStyles(row) : undefined
                    return (
                        <div
                            className="virtual-table_row-container"
                            key={`row-${itemId}`}
                        >
                            <div
                                key={itemId}
                                style={{
                                    gridTemplateColumns,
                                    width: "100%",
                                    height: 35,
                                    position: "relative",
                                    ...styles,
                                }}
                                className={clsx([
                                    "virtual-table_row",
                                    {
                                        "virtual-table_activeRow": isRowActive
                                            ? isRowActive(row)
                                            : false,
                                    },
                                    {
                                        "virtual-table_pointer": !!onRowClick,
                                    },
                                    {
                                        "virtual-table_nested": nested
                                            ? nested(row)
                                            : false,
                                    },
                                ])}
                                onClick={
                                    onRowClick
                                        ? (e) => {
                                              if (!isDisableFocusInput) {
                                                  const target = e.target as any
                                                  const currentTarget =
                                                      e.currentTarget as any
                                                  if (
                                                      target.tagName === "INPUT"
                                                  )
                                                      return
                                                  currentTarget
                                                      .querySelector(
                                                          "input:not([type='checkbox'])"
                                                      )
                                                      ?.focus()
                                              }
                                              onRowClick(row, e)
                                          }
                                        : undefined
                                }
                            >
                                {onRowClose && (
                                    <CloseOutlined
                                        style={{
                                            position: "absolute",
                                            right: 10,
                                            zIndex: 1000,
                                            cursor: "pointer",
                                            background: "#fff",
                                            border: "2px solid #fff",
                                        }}
                                        onClick={() => onRowClose(row)}
                                    />
                                )}
                                {columns.map((column, idx) => {
                                    const value = column.render(row, itemId)
                                    const displayedValue =
                                        typeof value === "boolean"
                                            ? value
                                                ? "Да"
                                                : "Нет"
                                            : value
                                    return (
                                        <div
                                            className={clsx([
                                                "virtual-table_cell",
                                                column?.className?.(row),
                                                {
                                                    "virtual-table_cell__isDisabled":
                                                        column.isDisabled,
                                                },
                                            ])}
                                            style={{
                                                textAlign: textAlign ?? "start",
                                                ...column.cellStyles,
                                            }}
                                            key={`td-${idx}`}
                                            onMouseOver={(e) => {
                                                if (!e.ctrlKey) return
                                                const target =
                                                    e.currentTarget as HTMLElement
                                                target.classList.add("can-copy")
                                            }}
                                            onMouseLeave={(e) => {
                                                const target =
                                                    e.currentTarget as HTMLElement
                                                target.classList.remove(
                                                    "can-copy"
                                                )
                                            }}
                                            onClick={(e) => {
                                                if (tableProps?.noCopy) return
                                                if (
                                                    (e.ctrlKey &&
                                                        typeof displayedValue ===
                                                            "string") ||
                                                    typeof displayedValue ===
                                                        "number"
                                                ) {
                                                    const target =
                                                        e.target as HTMLElement
                                                    const innertext =
                                                        target.innerHTML
                                                    if (!innertext) return
                                                    e.stopPropagation()
                                                    navigator.clipboard
                                                        .writeText(
                                                            target.textContent ||
                                                                ""
                                                        )
                                                        .then(() => {
                                                            showNotification({
                                                                message:
                                                                    "Скопировано",
                                                                type: "success",
                                                            })
                                                        })
                                                }
                                            }}
                                        >
                                            {displayedValue}
                                        </div>
                                    )
                                })}
                            </div>
                            {content &&
                                content(row) &&
                                isRowActive &&
                                isRowActive(row) && (
                                    <div className="virtual-table_content">
                                        {content(row)}
                                    </div>
                                )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
