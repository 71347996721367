import { Empty, message } from "antd"
import api from "helpers/api"
import React, { useMemo, useState } from "react"
import { useSet } from "react-use"
import { usePicturesMassUploaderContextManager } from "."

const blobToBase64 = async (blobLocalUrl: string): Promise<string> => {
    const blobResponse = await fetch(blobLocalUrl)
    const blob = await blobResponse.blob()

    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
    })
}
export const ListChangesScreen: React.FC = () => {
    const { changesList, params, resetChanges, setActiveScreen } =
        usePicturesMassUploaderContextManager()
    const [messageApi, contextHolder] = message.useMessage()

    const [loadingText, setLoadingText] = useState<undefined | string>()
    const [selected, selectActions] = useSet(new Set<string>([]))

    const handleSubmitChanges = async () => {
        setLoadingText("Кодирование изображений")

        const payload = {
            params: {
                group: params?.group.value,
                model: params?.model?.value,
                producer: params?.producer.value,
                series: params?.series.value,
            },
            // bindings: [],
            bindings: await Promise.all(
                Object.entries(changesList)
                    .filter(([picture]) => selected.has(picture))
                    .map(
                        ([picture, { values }]) =>
                            new Promise(async (resolve) =>
                                resolve({
                                    picture: await blobToBase64(picture),
                                    values,
                                })
                            )
                    )
            ),
        }

        setLoadingText("Отправка изображений")
        try {
            const result = await api
                .getClient()
                .post("/api/v1/pictures-generator/mass-upload", payload, {
                    onUploadProgress: (progressEvent) => {
                        const progressPercent = Math.round(
                            (progressEvent?.loaded / progressEvent?.total) * 100
                        )

                        if (progressPercent === 100) {
                            return setLoadingText("Обработка изменений")
                        }

                        setLoadingText(`${progressPercent}%`)
                    },
                })

            setLoadingText(undefined)
            resetChanges()
            messageApi.open({
                type: "success",
                content: result.data,
            })
        } catch (error) {
            setLoadingText(undefined)
            messageApi.open({
                type: "error",
                content: "Ошибка: " + String(error),
            })
        }
    }

    const handleBulk = () => {
        const isEverytingChecked =
            Object.keys(changesList).length === selected.size

        if (isEverytingChecked) {
            return Object.keys(changesList).map((v) => selectActions.remove(v))
        }

        return Object.keys(changesList).map((v) => selectActions.add(v))
    }

    const isEmpty = useMemo(
        () => Object.keys(changesList).length === 0,
        [changesList]
    )

    return (
        <>
            <div className="pictures-mass-uploader__changes-screen">
                {contextHolder}

                {isEmpty && (
                    <div className="col-12 pictures-mass-uploader__ui-box changes-screen__wrapper changes-screen__empty ">
                        <Empty
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{ height: 60 }}
                            description={
                                <span
                                    style={{
                                        fontSize: "15px",
                                        lineHeight: "19px",
                                        display: "block",
                                    }}
                                >
                                    Все изменения уже сохранены, либо
                                    <br />
                                    вовсе еще не существуют.
                                </span>
                            }
                        >
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => setActiveScreen("workspace")}
                                style={{ textDecoration: "none" }}
                            >
                                Вернуться назад
                            </button>
                        </Empty>
                    </div>
                )}

                {!isEmpty && (
                    <div className="col-12 pictures-mass-uploader__ui-box changes-screen__wrapper">
                        <div className="changes-screen__list-bulk mb-2">
                            <div
                                className="form-check"
                                onClick={() => handleBulk()}
                            >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                        Object.keys(changesList).length ===
                                        selected.size
                                    }
                                />{" "}
                                <label className="form-check-label">
                                    Выбрать все
                                </label>
                            </div>
                        </div>
                        <div className="changes-screen__list">
                            {Object.entries(changesList).map(
                                ([pictureUrl, item]) => (
                                    <div className="list__item">
                                        <div className="item__picture">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selected.has(
                                                        pictureUrl
                                                    )}
                                                    onChange={() =>
                                                        selectActions.toggle(
                                                            pictureUrl
                                                        )
                                                    }
                                                />
                                            </div>
                                            <img src={pictureUrl} alt="" />
                                        </div>
                                        <div className="item__content">
                                            <div className="content__values">
                                                <ul>
                                                    {Object.entries(
                                                        item.renders
                                                    )
                                                        .sort(([a], [b]) =>
                                                            a.localeCompare(b)
                                                        )
                                                        .map(
                                                            ([
                                                                attribute,
                                                                value,
                                                            ]) => (
                                                                <li className="d-flex justify-content-between values__item">
                                                                    <span className="item__attribute">
                                                                        {
                                                                            attribute
                                                                        }
                                                                        :
                                                                    </span>
                                                                    <span className="item__value">
                                                                        {value}
                                                                    </span>
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>

                        <div className="d-flex justify-content-end mt-3">
                            {/* <button className="btn btn-danger">Сохранить</button> */}

                            <div>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={handleSubmitChanges}
                                    disabled={
                                        loadingText !== undefined ||
                                        !selected.size
                                    }
                                >
                                    {loadingText !== undefined && (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            style={{ marginRight: 5 }}
                                        ></span>
                                    )}
                                    <span>
                                        {loadingText ?? "Сохранить выбранное"}
                                    </span>
                                </button>

                                <button
                                    className="btn btn-link"
                                    onClick={() => setActiveScreen("workspace")}
                                >
                                    Назад
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
