import React from "react"
import { useSelector } from "react-redux"
import FormElementContainer from "../helpers/FormElementContainer"
import { ContentFormProps } from "../types/interfaces"

const isError = (name: string, errorsData: any) => {
    for (let key in errorsData) {
        if (errorsData.hasOwnProperty([name])) {
            return errorsData[key].length > 0 ? errorsData[key][0] : null
        }
    }
}

const ContentForm: React.FC<ContentFormProps> = (props) => {
    const { group, isCreate, fields } = props

    const currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues
    )
    const errors = useSelector(
        (state: { [key: string]: any }) => state.errors.fields
    )

    const clonedFields = fields && fields.map((arr: {}) => ({ ...arr }))

    return clonedFields.map((item: { [key: string]: any }) => {
        if (item.createonly && !isCreate) item.readonly = true

        if (item.name === "id") return null

        item.value = item.value || currentValues[item.name]

        if (item.hasOwnProperty(item.multiple) || item.multiple) {
            item.type = "multiple." + item.type
        }

        const elementContainer = new FormElementContainer(
            item.name,
            item.type,
            item
        )

        const ComponentName = elementContainer.getComponent()

        if (elementContainer.get("group") === group) {
            return (
                <ComponentName
                    key={elementContainer.getName()}
                    propertyContainer={elementContainer}
                    textError={isError(elementContainer.getName(), errors)}
                    group={group ?? ""}
                />
            )
        }
        return null
    })
}

export default ContentForm
