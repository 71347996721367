import Home from "components/Home"
import PageNotFound from "components/PageNotFound"
import "dayjs/locale/ru"
import Test from "pages/Test"
import { Route, Switch } from "react-router-dom"
import { innerRoutes } from "routes/routes"
import { createAdditionalRoute, createBaseRoute } from "routes/tools"

const routes = innerRoutes

const createOrUpdateRoutes = routes.filter(
    (el) => el.create || el.update || el.createAndUpdate
)

export default function SecureRouter() {
    return (
        <Switch>
            {routes.map(createBaseRoute)}
            {createOrUpdateRoutes.map(createAdditionalRoute)}

            <Route exact path={"/"} component={Home} />

            <Route exact path={["/test"]} render={() => <Test />} />

            <Route render={() => <PageNotFound />} />
        </Switch>
    )
}
