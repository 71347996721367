import { useQuery } from "@tanstack/react-query"
import { Button, Checkbox, DatePicker, Form, Input, Spin } from "antd"
import { useForm } from "antd/es/form/Form"
import { FormProps } from "antd/lib"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import UNumberItem, { getUNumberValue } from "components/UNumberItem"
import dayjs, { Dayjs } from "dayjs"
import api from "helpers/api"
import { isDefined } from "helpers/checkType"
import { useNotifications } from "hooks/useNotifications"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { UserInfo } from "store/user/types"
import { RegionData, Uuid } from "types"
import { ModelEntity } from "types/api"
import AttributeValuesPricesHistoryModal from "./AttributeValuesPrices.HistoryModal"
import { useAttributeValuesPricesStore } from "./AttributeValuesPrices.store"

type CreateData = {
    model: Uuid
    currency: string
    region: string
    value: number
    marginValue?: number
    marginFirst: boolean
    calculateOnlyCombination: boolean
    startAt?: string
    values: Uuid[]
}

export type GetAttributeValuesListingItem = {
    model: ModelEntity
    attributesWithValuesMap: Array<{
        attributeId: Uuid
        attributeName: string
        attributeValueId: Uuid
        value: string
    }>
    currency: Currency
    createdAt: string
    createdBy: UserInfo
    id: Uuid
    marginFirst: boolean
    marginValue: number
    region: RegionData
    startAt: string
    value: number
    values: Record<Uuid, string>[]
    calculateOnlyCombination: boolean
}

type Currency = {
    id: string
    code: string
    name: string
    isoCode: string
}

export type GetAttributeValuesPriceData = {
    actionExplanation: string
    createdAt: string
    currency: Currency
    id: Uuid
    marginFirst: boolean
    marginValue: number
    value: number
    startAt: string
    calculateOnlyCombination: boolean
}

export default function AttributeValuesPricesForm({
    afterSave,
}: {
    afterSave: () => void
}) {
    const {
        activeModel,
        activeValues,
        activeGroup,
        activeProducer,
        activeListingValue,
    } = useAttributeValuesPricesStore()
    const region = useSelector((state: RootState) => state.region.region)
    const [mutateLoading, setMutateLoading] = useState(false)
    const [showHistoryModal, setHistoryModal] = useState<
        keyof GetAttributeValuesPriceData | null
    >(null)

    const [form] = useForm<{
        value: number
        oldValue?: number
        marginValue: number
        oldMargin?: number
        marginFirst: boolean
        startAt: Dayjs
        calculateOnlyCombination: boolean
    }>()

    const { showNotification } = useNotifications()

    const { data: currentCurrency } = useQuery({
        queryKey: ["get-currency", activeGroup, activeProducer, region],
        enabled: !!activeGroup && !!activeProducer && !!region,
        queryFn: () =>
            api
                .getTyped(`v1/economics/attribute-combination/get-currency`, {
                    group: activeGroup?.value,
                    producer: activeProducer?.value,
                    region: region?.value,
                })
                .then((data) => data.currency as Currency | null),
    })

    const { isFetching, refetch } = useQuery({
        queryKey: [activeModel, activeValues, "fetch-values-prices", region],
        refetchOnWindowFocus: false,
        enabled:
            activeValues.length > 1 &&
            !!activeModel &&
            !!region &&
            !activeListingValue,
        retry: false,
        queryFn: () =>
            api
                .post<any, GetAttributeValuesPriceData>(
                    `v1/economics/attribute-combination/get`,
                    {},
                    {
                        model: activeModel?.value,
                        region: region?.value,
                        values: activeValues,
                    }
                )
                .then((response) => {
                    const data = response.data
                    form.setFieldsValue({
                        oldValue: data.value,
                        oldMargin: data.marginValue ?? 0,
                        marginFirst: data.marginFirst,
                        calculateOnlyCombination: data.calculateOnlyCombination,
                        startAt: data.startAt
                            ? dayjs(data.startAt)
                            : dayjs(new Date()),
                    })
                    return response.data
                }),
        onError: () => {
            form.resetFields()
        },
    })

    const onFinish: FormProps["onFinish"] = (values) => {
        const {
            value,
            marginValue,
            marginFirst,
            startAt,
            oldValue,
            oldMargin,
            calculateOnlyCombination,
        } = values

        const formValue =
            isDefined(value) && value !== oldValue ? value : oldValue
        const formMargin =
            isDefined(marginValue) && marginValue !== oldMargin
                ? marginValue
                : oldMargin
        if (!activeModel || !region || !currentCurrency) return
        setMutateLoading(true)
        api.post<CreateData, any>(
            `v1/economics/attribute-combination/create`,
            {},
            {
                value: getUNumberValue(formValue)!,
                marginFirst: marginFirst,
                marginValue: getUNumberValue(formMargin),
                startAt: startAt
                    ? dayjs(startAt).format("YYYY-MM-DD")
                    : undefined,
                model: activeModel!.value,
                values: activeValues,
                region: region!.value,
                currency: currentCurrency?.id,
                calculateOnlyCombination,
            }
        )
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Успешно",
                })
            })
            .finally(() => {
                setMutateLoading(false)
                refetch()
                afterSave()
            })
    }

    useEffect(() => {
        if (!activeListingValue) {
            form.resetFields()
            return
        }
        form.setFieldsValue({
            marginFirst: activeListingValue.marginFirst,
            oldValue: activeListingValue.value,
            oldMargin: activeListingValue.marginValue,
            startAt: dayjs(activeListingValue.startAt),
            calculateOnlyCombination:
                activeListingValue.calculateOnlyCombination,
        })
        form.setFieldValue("marginFirst", activeListingValue.marginFirst)
        form.resetFields(["value", "marginValue"])
    }, [activeListingValue, activeValues])

    return (
        <Flex.Col
            fullWidth
            align="start"
            className={clsx({
                "--visible": activeValues.length > 1,
                "--hidden": activeValues.length <= 1,
            })}
        >
            {isFetching && <Spin />}
            <Form
                className="fw"
                style={{ maxWidth: 600 }}
                form={form}
                labelCol={{ span: 24 }}
                labelAlign="left"
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
            >
                <Form.Item name="startAt" label="Дата изменения цен">
                    <DatePicker className="fw" />
                </Form.Item>
                <UNumberItem
                    useInForm={{
                        name: "oldValue",
                        label: "Старая цена",
                    }}
                    disabled
                    bordered
                    className="fw form-control form-control-sm"
                />
                <UNumberItem
                    key={Number(form.isFieldTouched("value"))}
                    floatRank={2}
                    useInForm={{
                        name: "value",
                        label: (
                            <Flex.Row gap={5}>
                                <span>Новая цена</span>{" "}
                                <Button
                                    onClick={() => setHistoryModal("value")}
                                >
                                    История
                                </Button>
                            </Flex.Row>
                        ),
                    }}
                    prefix={currentCurrency?.isoCode}
                    bordered
                    className="fw form-control form-control-sm"
                />
                <Form.Item name="oldMargin" label="Старый процент наценки">
                    <Input disabled />
                </Form.Item>

                <UNumberItem
                    prefix="%"
                    useInForm={{
                        name: "marginValue",
                        label: (
                            <Flex.Row gap={5}>
                                <span>Новый процент наценки</span>{" "}
                                <Button
                                    onClick={() =>
                                        setHistoryModal("marginValue")
                                    }
                                >
                                    История
                                </Button>
                            </Flex.Row>
                        ),
                    }}
                    floatRank={2}
                    className="fw form-control form-control-sm"
                    bordered
                />

                <Form.Item
                    name="marginFirst"
                    valuePropName="checked"
                    label="Сначала наценка"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    name="calculateOnlyCombination"
                    valuePropName="checked"
                    label="Учитывать только сочетание"
                    labelCol={{ span: 13 }}
                    wrapperCol={{ span: 11 }}
                >
                    <Checkbox />
                </Form.Item>

                <Flex.Row fullWidth gap={10} justify="start">
                    <Button
                        htmlType="submit"
                        type="primary"
                        loading={mutateLoading}
                    >
                        Сохранить
                    </Button>
                </Flex.Row>
            </Form>
            <AttributeValuesPricesHistoryModal
                showModal={showHistoryModal}
                hide={() => setHistoryModal(null)}
            />
        </Flex.Col>
    )
}
