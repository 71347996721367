import { usePageData } from "hooks/usePageData"
import { usePermission } from "hooks/usePermission"
import { useMemo } from "react"
import { Route, RouteProps } from "react-router-dom"
export type CreateRouteData = {
    path: string
    folder?: string
    render: () => React.ReactNode
    create?: () => React.ReactNode
    update?: () => React.ReactNode
    createAndUpdate?: () => React.ReactNode
    title: string
    permission?: string
    dontShowInMenu?: boolean
}

const NoRights = () => <span>У вас нет прав для просмотра этой страницы</span>

const SecureRoute = (props: RouteProps) => {
    const { permission } = usePermission()
    const { isUpdatable } = usePageData()

    const render = useMemo(() => {
        if ((isUpdatable && !permission.update) || !permission.view)
            return () => <NoRights />
        return props.render
    }, [isUpdatable, permission, props.path])

    return <Route {...props} render={render} />
}

const createBasePath = ({
    folder,
    path,
}: Pick<CreateRouteData, "path" | "folder">) => {
    const base = "/" + [folder, path].filter(Boolean).join("/")
    return base
}

export const createBaseRoute = ({
    folder,
    path,
    render,
}: Pick<CreateRouteData, "path" | "render" | "folder">) => {
    const basePath = createBasePath({ folder, path })
    return <SecureRoute exact key={basePath} path={basePath} render={render} />
}

const createAdditionalPath = (
    props: Pick<
        CreateRouteData,
        "create" | "update" | "createAndUpdate" | "folder" | "path"
    >
) => {
    const paths = [] as string[]
    const base = createBasePath(props)
    const { create, createAndUpdate, update } = props
    if (create || createAndUpdate) {
        paths.push([base, "create"].join("/"))
    }
    if (update || createAndUpdate) {
        paths.push([base, "update-:id"].join("/"))
    }
    return paths
}

const getRenderFunc = ({
    createAndUpdate,
    create,
    update,
}: Pick<CreateRouteData, "create" | "createAndUpdate" | "update">) => {
    if (createAndUpdate) return createAndUpdate
    return create ?? update
}

export const createAdditionalRoute = (
    props: Pick<
        CreateRouteData,
        "create" | "update" | "createAndUpdate" | "folder" | "path"
    >
) => {
    const { create, update, createAndUpdate } = props
    if (!create && !update && !createAndUpdate) return null
    const path = createAdditionalPath(props)
    return (
        <SecureRoute
            exact
            key={path[0]}
            path={createAdditionalPath(props)}
            render={getRenderFunc(props)}
        />
    )
}
