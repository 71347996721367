import { useQuery } from "@tanstack/react-query"
import { usePageData } from "hooks/usePageData"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Uuid } from "types"
import api from "../../helpers/api"
import { uniqueEvents } from "../../helpers/uniqueEvents"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const InputMaterialSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    )

    const { slug } = usePageData()
    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    const optionHandler = (event: any) => {
        dispatch(uniqueEvents(event, "OnChange", slug))
        dispatch(
            SET_CURRENT_VALUES(
                { [propertyContainer.getName()]: event.target.value },
                group
            )
        )
    }

    const { data: attribute } = useQuery<{
        valuesList: Record<Uuid, string>
    }>({
        queryKey: ["attribute", "949bb121-c4dc-4ec3-a994-af5f6984ce42"],
        queryFn: () =>
            api.getTyped("attribute/949bb121-c4dc-4ec3-a994-af5f6984ce42"),
    })

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <select
                        className={`form-select ${isInvalid}`}
                        disabled={propertyContainer.get("readonly")}
                        value={value?.id ?? value}
                        size={propertyContainer.get("size")}
                        onChange={(event) => {
                            optionHandler(event)
                        }}
                        multiple={propertyContainer.get("multiple")}
                        name={propertyContainer.getName()}
                        id={propertyContainer.getName()}
                        aria-label="Default select"
                    >
                        <option value="null">Не указано</option>

                        {Object.entries(attribute?.valuesList ?? {}).map(
                            ([valueId, valueName]) => {
                                return (
                                    <option key={valueId} value={valueId}>
                                        {valueName}
                                    </option>
                                )
                            }
                        )}
                    </select>
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputMaterialSelect
