import { Switch } from "antd"
import { FormLine } from "components/UI/FormLine"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { FC, useState } from "react"
import { Uuid } from "types"

type IStandardKitDetalIsVerififed = FC<{
    id: Uuid
    isVerified: boolean
}>

const StandardKitIsVerified: IStandardKitDetalIsVerififed = ({
    id,
    isVerified,
}) => {
    const { showSuccess, handleApiError } = useNotifications()
    const [loading, setLoading] = useState(false)

    return (
        <FormLine label="Проверено">
            <Switch
                loading={loading}
                defaultChecked={isVerified}
                onChange={(verified) => {
                    setLoading(true)
                    api.put(
                        "listing/update/standard-kit/" + id,
                        {},
                        {
                            verified,
                        }
                    )
                        .then(showSuccess)
                        .catch(handleApiError)
                        .finally(() => setLoading(false))
                }}
            />
        </FormLine>
    )
}

export default StandardKitIsVerified
