import { GroupBase, OptionsOrGroups } from "react-select"
import api from "./api"

export const preloadListingToAsyncSelect =
    (entity: string, defaultQuery: string = "", customParams = {}) =>
    (
        q: string,
        callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void
    ) => {
        api.get<any>(`listing/${entity}`, {
            count_on_page: 50,
            q: q === "" ? defaultQuery : q,
            ...customParams,
        }).then(({ data }) => {
            const results = (data?.entities ?? []).map((item: any) => {
                return {
                    label: item.fields.find(
                        (v: { name: string }) => v.name === "name"
                    )?.value,
                    value: item.fields.find(
                        (v: { name: string }) => v.name === "id"
                    )?.value,
                }
            })

            callback(results)
        })
    }

export const preloadListingToPaginatedSelect =
    (
        entity: string,
        customParams = {},
        {
            noSearch,
            filter,
            noSort,
            externalOptions = [],
            transformResponse,
        }: {
            noSearch?: boolean
            filter?: (v: any) => any
            noSort?: boolean
            externalOptions?: any[]
            transformResponse?: (v: any) => any
        }
    ) =>
    async (
        search: string,
        _: any,
        additional: { page: number } | undefined
    ) => {
        const page = additional?.page ?? 1

        let data: any
        try {
            const response = await api.get(`listing/${entity}`, {
                count_on_page: 50,
                q: noSearch ? undefined : search,
                method: "asc",
                sort: noSort ? undefined : "name",
                page,
                ...customParams,
            })
            data = response.data
        } catch {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1,
                },
            }
        }

        const entities =
            data?.entities && filter
                ? data.entities.filter(filter)
                : data.entities

        const results = (entities ?? []).map((v: any) => {
            return transformResponse
                ? transformResponse(v)
                : {
                      label: v.fields.find(
                          (v: { name: string }) => v.name === "name"
                      )?.value,
                      value: v.fields.find(
                          (v: { name: string }) => v.name === "id"
                      )?.value,
                  }
        })

        return {
            options: [...externalOptions, ...results],
            hasMore: data?.pagination?.allPages
                ? (data?.pagination?.allPages ?? 0) > page
                : false,
            additional: {
                page: page + 1,
            },
        }
    }
