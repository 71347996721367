import { useQuery } from "@tanstack/react-query"
import { Layout } from "components/layouts"
import { SelectEntity } from "components/Selects/types"
import { AsyncSelect } from "components/UI"
import DetailFormItem from "components/UI/DetailFormItem"
import api from "helpers/api"
import { useDetailApi } from "hooks/useDetailApi"
import { useNotifications } from "hooks/useNotifications"
import { useSaveOrAcceptProps } from "hooks/useSaveOrAccept"
import { useCallback, useMemo } from "react"
import { useHistory } from "react-router-dom"
import ReactSelect from "react-select"
import { Uuid } from "types"
import { Attribute, ModelEntity } from "types/api"

type InitialData = {
    attribute: Attribute
    attributeValue: Record<Uuid, string>
    firstAttributeValueCriteria: Record<Uuid, string>
    id: Uuid
    model: ModelEntity
    secondAttributeValueCriteria: Record<Uuid, string>
}

type CurrentData = {
    attribute: SelectEntity | null
    attributeValue: SelectEntity | null
    firstAttributeValueCriteria: SelectEntity | null
    id?: Uuid
    model: SelectEntity | null
    secondAttributeValueCriteria: SelectEntity | null
}

type PutData = {
    model: Uuid
    attribute: Uuid
    attributeValue: Uuid
    firstAttributeValueCriteria: Uuid
    secondAttributeValueCriteria: Uuid
}

type GetValuesData = {
    firstCriteriaAttributeValues: Record<Uuid, string> | string
    attribute: {
        values: Record<Uuid, string>
    } & Record<Uuid, string>
    secondCriteriaAttributeValues: Record<Uuid, string> | string
}

const transformData = (v: CurrentData): PutData => ({
    model: v.model!.value,
    attribute: v.attribute!.value,
    attributeValue: v.attributeValue!.value,
    firstAttributeValueCriteria: v.firstAttributeValueCriteria!.value,
    secondAttributeValueCriteria: v.secondAttributeValueCriteria!.value,
})

const getAttributeValue = (data: GetValuesData["attribute"]) => {
    const attributeData = Object.entries(data).find(([key]) => key !== "values")
    return {
        label: attributeData![1] as string,
        value: attributeData![0],
    }
}

export default function ModelAttributeValueCriteria() {
    const {
        id,
        currentValues,
        changeValue,
        initialValues,
        getError,
        setError,
        resetValues,
    } = useDetailApi<InitialData, CurrentData>({
        pageSlug: "reference/model-attribute",
        init: {
            attribute: null,
            attributeValue: null,
            firstAttributeValueCriteria: null,
            secondAttributeValueCriteria: null,
            model: null,
        },
        transformInitialValues(v) {
            return {
                attribute: {
                    label: v.attribute.name,
                    value: v.attribute.id,
                },
                attributeValue: {
                    label: Object.entries(v.attributeValue)[0][1],
                    value: Object.entries(v.attributeValue)[0][0],
                },
                firstAttributeValueCriteria: {
                    label: Object.entries(v.firstAttributeValueCriteria)[0][1],
                    value: Object.entries(v.firstAttributeValueCriteria)[0][0],
                },
                secondAttributeValueCriteria: {
                    label: Object.entries(v.secondAttributeValueCriteria)[0][1],
                    value: Object.entries(v.secondAttributeValueCriteria)[0][0],
                },
                id: v.id,
                model: {
                    label: v.model.name,
                    value: v.model.id,
                },
            }
        },
    })

    const history = useHistory()

    const { showNotification } = useNotifications()
    const { withLoading, isLoading } = useSaveOrAcceptProps()

    const { data: getValuesData, isFetching } = useQuery<{
        data: GetValuesData | null
        cannotSet: boolean
    }>({
        queryKey: ["get-values", currentValues.model],
        enabled: !!currentValues.model?.value,
        queryFn: () =>
            api
                .getTyped<GetValuesData | undefined>(
                    `v1/model-attribute-value-criteria/get-values/${currentValues.model?.value}`
                )
                .then((data) => {
                    if (data?.attribute) {
                        changeValue(
                            "attribute",
                            getAttributeValue(data.attribute)
                        )
                    }
                    if (!data) {
                        resetValues([
                            "firstAttributeValueCriteria",
                            "secondAttributeValueCriteria",
                            "attribute",
                            "attributeValue",
                        ])
                    }
                    return {
                        data: data ?? null,
                        cannotSet: !data,
                    }
                }),
    })

    const pageTitle = useMemo(() => {
        return id
            ? "Редактирование стандартных размеров для модели"
            : "Установка стандартных размеров для модели"
    }, [id])

    const checkForm = useCallback(
        (currentValues?: CurrentData) => {
            if (!currentValues) return false
            if (Object.values(currentValues).some((el) => !el))
                return Object.entries(currentValues).forEach(([key, value]) => {
                    if (!value) {
                        setError(key as keyof CurrentData)
                    }
                })
            return true
        },
        [setError]
    )

    const putData = useCallback(
        (data: PutData, redirect?: boolean) => {
            const action = id ? api.put.bind(api) : api.post.bind(api)
            const target = id ?? "create"
            action(`v1/model-attribute-value-criteria/${target}`, {}, data)
                .then(() => {
                    showNotification({
                        type: "success",
                        message: "Сохранено",
                        cb: redirect
                            ? () => {
                                  history.push(
                                      `/catalog/model-attribute-value-criteria`
                                  )
                              }
                            : undefined,
                    })
                })
                .catch((error: any) => {
                    showNotification({
                        type: "danger",
                        message: error.message,
                    })
                })
        },
        [api, id]
    )

    const onSave = withLoading(
        async () => {
            if (!checkForm(currentValues)) return
            return putData(transformData(currentValues!), true)
        },
        "save",
        1000
    )

    const onAccept = withLoading(
        async () => {
            if (!checkForm(currentValues)) return
            putData(transformData(currentValues!))
        },
        "accept",
        1000
    )

    const attributeValues = useMemo(() => {
        if (!getValuesData?.data) return []
        const options = Object.entries(getValuesData.data.attribute)
            .filter(([key]) => key !== "values")
            .map(
                ([value, label]) =>
                    ({ value, label } as { value: Uuid; label: string })
            )
        return options
    }, [getValuesData])

    const firstAttributeValueCriteriaOptions = useMemo(() => {
        if (!getValuesData?.data) return []
        if (
            typeof getValuesData?.data?.firstCriteriaAttributeValues ===
            "string"
        )
            return []
        return Object.entries(
            getValuesData.data.firstCriteriaAttributeValues
        ).map(([value, label]) => ({ label, value }))
    }, [getValuesData])

    const secondAttributeValueCriteriaOptions = useMemo(() => {
        if (!getValuesData?.data) return []
        if (
            typeof getValuesData?.data.secondCriteriaAttributeValues ===
            "string"
        )
            return []
        return Object.entries(
            getValuesData?.data.secondCriteriaAttributeValues
        ).map(([value, label]) => ({ label, value }))
    }, [getValuesData])

    const cannotError = useMemo(() => {
        if (!getValuesData) return ""
        return getValuesData.cannotSet
            ? "Невозможно установить стандартные размеры для данной модели"
            : ""
    }, [getValuesData])

    return (
        <Layout.Detail
            pageTitle={pageTitle}
            onAcceptProps={{
                onAccept: onAccept,
                isLoading: isLoading === "accept",
            }}
            onSaveProps={{ onSave: onSave, isLoading: isLoading === "save" }}
            tabTitle="Основное"
        >
            <DetailFormItem
                label="Модель"
                error={cannotError || getError("model")}
                required
            >
                <AsyncSelect
                    dataUrl="model"
                    aria-errormessage="sdsd"
                    onChange={(v) => {
                        changeValue(
                            "model",
                            v,
                            v.value === initialValues?.model.id
                                ? []
                                : ["attribute", "attributeValue"]
                        )
                    }}
                    placeholder="Выберите модель"
                    value={currentValues?.model}
                    className="form--error"
                />
            </DetailFormItem>

            <DetailFormItem
                label="Аттрибут"
                error={getError("attribute")}
                required
            >
                <ReactSelect
                    options={attributeValues}
                    isDisabled={true}
                    isLoading={isFetching}
                    value={
                        currentValues?.attribute
                            ? {
                                  value: currentValues?.attribute?.value,
                                  label: currentValues?.attribute.label,
                              }
                            : null
                    }
                    onChange={(v) =>
                        changeValue("attribute", v, ["attributeValue"])
                    }
                    placeholder="Атрибут"
                />
            </DetailFormItem>

            <DetailFormItem
                label="Значение"
                error={getError("attributeValue")}
                required
            >
                <ReactSelect
                    options={
                        getValuesData?.data?.attribute?.values
                            ? Object.entries(
                                  getValuesData?.data.attribute.values
                              ).map(([value, label]) => ({ label, value }))
                            : []
                    }
                    isLoading={isFetching}
                    isDisabled={!currentValues?.attribute}
                    placeholder="Значение"
                    value={currentValues?.attributeValue ?? null}
                    onChange={(v) => changeValue("attributeValue", v)}
                />
            </DetailFormItem>

            <DetailFormItem
                label="Высота"
                error={getError("firstAttributeValueCriteria")}
                required
            >
                <ReactSelect
                    options={firstAttributeValueCriteriaOptions}
                    onChange={(v) => {
                        changeValue("firstAttributeValueCriteria", v)
                    }}
                    value={currentValues?.firstAttributeValueCriteria}
                    isLoading={isFetching}
                    isDisabled={!getValuesData?.data}
                    placeholder={
                        typeof getValuesData?.data
                            ?.firstCriteriaAttributeValues === "string"
                            ? getValuesData?.data.firstCriteriaAttributeValues
                            : "Выберите высоту"
                    }
                />
            </DetailFormItem>

            <DetailFormItem
                label="Ширина"
                error={getError("secondAttributeValueCriteria")}
                required
            >
                <ReactSelect
                    options={secondAttributeValueCriteriaOptions}
                    onChange={(v) => {
                        changeValue("secondAttributeValueCriteria", v)
                    }}
                    value={currentValues?.secondAttributeValueCriteria}
                    isLoading={isFetching}
                    isDisabled={!getValuesData?.data}
                    placeholder={
                        typeof getValuesData?.data
                            ?.secondCriteriaAttributeValues === "string"
                            ? getValuesData?.data.secondCriteriaAttributeValues
                            : "Выберите ширину"
                    }
                />
            </DetailFormItem>
        </Layout.Detail>
    )
}
