import React, { useMemo, useState } from "react"
import { AsyncSelect } from "../../../components/UI"
import { Uuid } from "../../../types"
import { usePicturesMassUploaderContextManager } from "./index"

export const SetupParentsScreen: React.FC = () => {
    const { setParams, setActiveScreen } =
        usePicturesMassUploaderContextManager()

    const [activeProducer, setActiveProducer] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >()
    const [activeGroup, setActiveGroup] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >()
    const [activeSeries, setActiveSeries] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >()
    const [activeModel, setActiveModel] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >()

    const handleApplyActiveParams = () => {
        setParams({
            producer: activeProducer!,
            group: activeGroup!,
            model: activeModel!,
            series: activeSeries!,
        })
        setActiveScreen("workspace")
    }

    const isFieldsFilled: boolean = useMemo(() => {
        return !(
            !activeGroup?.value ||
            !activeProducer?.value ||
            !activeSeries?.value ||
            !activeModel?.value
        )
    }, [activeSeries, activeGroup, activeProducer, activeModel])

    return (
        <>
            <div className="pictures-mass-uploader__setup-screen">
                <div className="col-12 setup-screen__wrapper pictures-mass-uploader__ui-box">
                    <div className="mb-3 setup-screen__field-row">
                        <label className="col-form-label">
                            Номенклатурная группа
                        </label>
                        <div>
                            <AsyncSelect
                                onChange={(e) => {
                                    setActiveProducer(undefined)
                                    setActiveSeries(undefined)
                                    setActiveModel(undefined)
                                    setActiveGroup(e as typeof activeGroup)
                                }}
                                dataUrl="items-group"
                                page={1}
                                className="setup-screen__field"
                            />
                        </div>
                    </div>
                    <div className="mb-3 setup-screen__field-row ">
                        <label className="col-form-label">Поставщик</label>
                        <div>
                            <AsyncSelect
                                isDisabled={!activeGroup?.value}
                                onChange={(e) => {
                                    setActiveSeries(undefined)
                                    setActiveModel(undefined)
                                    setActiveProducer(
                                        e as typeof activeProducer
                                    )
                                }}
                                dataUrl="producer"
                                params={{
                                    itemsGroups: activeGroup?.value,
                                }}
                                className="setup-screen__field"
                            />
                        </div>
                    </div>
                    <div className="mb-3 setup-screen__field-row">
                        <label className="col-form-label">Серия</label>
                        <div>
                            <AsyncSelect
                                isDisabled={
                                    !activeGroup?.value ||
                                    !activeProducer?.value
                                }
                                onChange={(e) => {
                                    setActiveSeries(e as typeof activeSeries)
                                    setActiveModel(undefined)
                                }}
                                dataUrl="series"
                                params={{
                                    producer: activeProducer?.value,
                                    itemsGroup: activeGroup?.value,
                                }}
                                className="setup-screen__field"
                            />
                        </div>
                    </div>
                    <div className="mb-3 setup-screen__field-row">
                        <label className="col-form-label">Модель</label>
                        <div>
                            <AsyncSelect
                                isDisabled={!activeSeries?.value}
                                onChange={(e) =>
                                    setActiveModel(e as typeof activeModel)
                                }
                                dataUrl="model"
                                params={{
                                    series: activeSeries?.value,
                                    itemsGroups: activeGroup?.value,
                                }}
                                className="setup-screen__field"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            className="btn btn-primary"
                            onClick={handleApplyActiveParams}
                            disabled={!isFieldsFilled}
                        >
                            Применить и продолжить
                        </button>
                        <button
                            className="btn btn-secondary ms-3"
                            onClick={() => {
                                setActiveProducer(undefined)
                                setActiveSeries(undefined)
                                setActiveModel(undefined)
                            }}
                        >
                            Сбросить
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
