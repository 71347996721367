import { Button } from "antd"
import { FC } from "react"

import styles from "./accordionButtons.module.scss"

interface IAccordionButtons {
    copyTo: () => void
    clear: () => void
}

const AccordionButtons: FC<IAccordionButtons> = ({ copyTo, clear }) => {
    return (
        <>
            <Button
                onClick={(e) => {
                    e.stopPropagation()
                    copyTo()
                }}
                className={styles.button}
                type="link"
            >
                Скопировать на
            </Button>
            <Button
                onClick={(e) => {
                    e.stopPropagation()
                    clear()
                }}
                className={styles.button}
                type="link"
            >
                Очистить
            </Button>
        </>
    )
}

export default AccordionButtons
