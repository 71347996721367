import { SelectProps } from "antd"

export const pickListingRecordField = (record: any, field: string) => {
    return record.fields.find((v: { name: string }) => v.name === field)?.value
}

export const filterOption: SelectProps["filterOption"] = (input, option) => {
    return (
        option?.label
            ?.toLocaleString()
            .toLowerCase()
            .includes(input.toLowerCase()) || false
    )
}

export const checkMimeType = (mimeType: string) =>
    mimeType === "text/plain" ||
    mimeType === "text/csv" ||
    mimeType === "application/vnd.ms-excel" ||
    mimeType === "application/vnd.oasis.opendocument.spreadsheet" ||
    mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
