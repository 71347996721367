import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"
import { Flex } from "components/UI/Flex"
import styles from "./columnSorterSwitcher.module.scss"

interface ColumnSorterSwitcherProps {
    typeSort?: "up" | "down" | ""
}

const ColumnSorterSwitcher = ({ typeSort = "" }: ColumnSorterSwitcherProps) => {
    return (
        <Flex.Col
            className={`${styles.switcher} ${typeSort ? styles[typeSort] : ""}`}
        >
            <CaretUpOutlined width={12} height={12} />
            <CaretDownOutlined width={12} height={12} />
        </Flex.Col>
    )
}

export default ColumnSorterSwitcher
