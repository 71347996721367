import { Spin } from "antd"
import Link from "antd/es/typography/Link"
import Button from "components/UI/Button"
import { Flex } from "components/UI/Flex"
import { useReturn } from "hooks/useReturn"
import { PropsWithChildren } from "react"

type DetailLayoutProps = {
    pageTitle: string
    tabTitle?: string
    contentTitle?: string
    loading?: boolean
    onSaveProps?: {
        label?: string
        onSave: () => void
        disabled?: boolean
        isLoading?: boolean
    }
    onAcceptProps?: {
        label?: string
        onAccept: () => void
        disabled?: boolean
        isLoading?: boolean
    }
    noReturnToList?: boolean
    infoPart?: JSX.Element
}

const Header = ({
    onSaveProps,
    onAcceptProps,
}: Pick<DetailLayoutProps, "onAcceptProps" | "onSaveProps">) => {
    const { returnLink } = useReturn()

    return (
        <Flex.Row
            justify="between"
            align="center"
            fullWidth
            className="detail-header"
        >
            <Link href={returnLink} className="btn-toolbar-back">
                <i className="btn-toolbar-back__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-left-short"
                        viewBox="0 0 16 16"
                    >
                        <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                </i>
                Назад в список
            </Link>
            {(onSaveProps || onAcceptProps) && (
                <Flex.Row>
                    {onAcceptProps && (
                        <Button
                            className="btn btn-outline-primary"
                            onClick={onAcceptProps.onAccept}
                            disabled={onAcceptProps.disabled}
                            isLoading={onAcceptProps.isLoading}
                        >
                            {onAcceptProps.label || "Применить"}
                        </Button>
                    )}
                    {onSaveProps && (
                        <Button
                            className="btn btn-primary"
                            onClick={onSaveProps.onSave}
                            disabled={onSaveProps.disabled}
                            isLoading={onSaveProps.isLoading}
                        >
                            {onSaveProps.label || "Сохранить"}
                        </Button>
                    )}
                </Flex.Row>
            )}
        </Flex.Row>
    )
}

export function DetailLayout({
    pageTitle,
    children,
    tabTitle = "Настройка",
    contentTitle = "Основное",
    loading,
    infoPart,
    ...headerProps
}: PropsWithChildren<DetailLayoutProps>) {
    return (
        <>
            <h1 className="h3 mb-3">{pageTitle}</h1>
            <Header {...headerProps} />
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                        className="nav-link active"
                        id="edit-settings-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#edit-settings"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                    >
                        {tabTitle}
                    </button>
                </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="edit-settings"
                    role="tabpanel"
                    aria-labelledby="edit-settings-tab"
                >
                    <div className="control-view">
                        <div className="control-view__main">
                            <div className="control-in">
                                <h5>{contentTitle}</h5>
                                {loading && (
                                    <Flex.Col fullHeight fullWidth>
                                        <Spin />
                                    </Flex.Col>
                                )}
                                {children}
                            </div>
                        </div>
                        <div className="control-view__info">{infoPart}</div>
                    </div>
                </div>
            </div>

            <Header {...headerProps} />
        </>
    )
}
