import { FormInstance } from "antd"
import { FC } from "react"
import { NomeclatureFiltersCheckboxField } from "./additionalFields/Checkbox"

export const additionalFieldsSettings = {
    ignoreModel: {
        Component: NomeclatureFiltersCheckboxField,
        name: "ignoreModel",
        label: "Не учитывать модель",
        defaultValue: false,
    },
} as const

export type AdditionalFieldType = keyof typeof additionalFieldsSettings

export type AdditionalField = FC<{
    label: string
    name: string
    form: FormInstance
}>
