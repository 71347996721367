import { useQuery } from "@tanstack/react-query"
import { Col, Row, Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import clsx from "clsx"
import { filterOption } from "components/inputs/helpers"
import { Text } from "components/UI"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useNotifications } from "hooks/useNotifications"
import { ListingResponse } from "types/api"
import { SelectProps } from "."
import { SelectEntity } from "../types"
import { getOnSelectData } from "./onSelect"

type CurrencySelectProps = SelectProps<{
    setActiveCurrency: (v: SelectEntity | null) => void
    activeCurrency: SelectEntity | null
    label?: string
}>

export function CurrencySelect({
    setActiveCurrency,
    required,
    layout = "horizontal",
    activeCurrency,
    labelSpan,
    inputSpan,
    formLabelFontSize,
    fullWidth,
    containerClassName,
    label = "Валюта",
    getValueProps,
    ...rest
}: CurrencySelectProps) {
    const { showNotification } = useNotifications()

    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["get-currency"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/currency", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    return response.entities.map(
                        (el) =>
                            ({
                                label: `[${getFieldValue(
                                    el.fields,
                                    "code"
                                )}] ${getFieldValue(el.fields, "name")}`,
                                value: getFieldValue(el.fields, "id"),
                            } as DefaultOptionType)
                    )
                }),
        initialData: [],
    })

    const { data: defaultValue, isFetching: getValueFetching } = useQuery({
        queryKey: [getValueProps, "get-value"],
        enabled: getValueProps ? getValueProps?.enabled : false,
        queryFn: async () => {
            const response = await api.getTyped<{
                currency: {
                    code: string
                    id: string
                    isoCode: string
                    name: string
                }
            }>(getValueProps!.url, getValueProps?.getParams)

            const data = response.currency
                ? {
                      label: response.currency.name,
                      value: response.currency.id,
                  }
                : null
            setActiveCurrency(data)
            return data
        },
    })

    const _labelSpan = layout === "horizontal" ? labelSpan ?? 5 : 24
    const _inputSpan = layout === "horizontal" ? inputSpan ?? 19 : 24

    const onChange = (v: any) => {
        if (defaultValue?.value === v?.value) return
        if (!getValueProps?.url) return
        const data = v ? getOnSelectData(v) : null
        setActiveCurrency(data)
        api.put(getValueProps!.url, {}, getValueProps?.getPutData(v))
            .then(() => {
                showNotification({ type: "success", message: "Сохранено" })
            })
            .catch((error: any) => {
                showNotification({ type: "danger", message: error })
            })
    }

    return (
        <Row
            gutter={[0, 10]}
            className={clsx([
                {
                    fw: fullWidth,
                },
                containerClassName,
            ])}
        >
            <Col xs={24} md={_labelSpan}>
                <Text.FormLabel
                    formLabelFontSize={formLabelFontSize}
                    required={required}
                >
                    {label}
                </Text.FormLabel>
            </Col>
            <Col xs={24} md={_inputSpan}>
                <Select
                    loading={getValueFetching || isFetching}
                    showSearch
                    filterOption={filterOption}
                    className="fw"
                    placeholder="Выберите валюту"
                    options={data}
                    value={activeCurrency}
                    onChange={(_, option) => onChange(option)}
                    allowClear
                    {...rest}
                />
            </Col>
        </Row>
    )
}
