import { Checkbox, Form } from "antd"
import { AdditionalField } from "../additionalFields"

export const NomeclatureFiltersCheckboxField: AdditionalField = ({
    label,
    name,
    form
}) => {

    const value = Form.useWatch(name, form);

    return (
        <Form.Item label={label} className="fw" name={name}>
            <Checkbox checked={value} onChange={e => {
                form.setFieldValue(name, e.target.checked)
            }}/>
        </Form.Item>
    )
}
