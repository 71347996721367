import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import api from "../../../helpers/api"
import { SET_CURRENT_VALUES } from "../../../store/fields/actions"
import { InputProps } from "../../../types/interfaces"
import { Required } from "../../UI/Required"
import TextError from "../../UI/TextError"
import { InputMultipleFileItem } from "./InputMultipleFileItem"

export const InputMultipleFile: React.FC<InputProps> = (props) => {
    const { propertyContainer, textError, group } = props

    const state = useSelector((state: { [key: string]: any }) => state)
    let cover =
        state.fields.currentValues[group] &&
        state.fields.currentValues[group][propertyContainer.getName()]

    const [showCover, setShowCover] = useState<boolean>(false)

    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    if (!cover) cover = []

    const sendFile = async (files: []) => {
        const formData: any = new FormData()

        let arrToDispatch: object[] = []
        for (const file of files) {
            formData.append("file", file)
            await api
                .post("file/upload", {}, formData)
                .then((response: { [key: string]: any }) => {
                    arrToDispatch.push(response.data)
                    dispatch(
                        SET_CURRENT_VALUES(
                            {
                                [propertyContainer.getName()]: [
                                    ...cover,
                                    ...arrToDispatch,
                                ],
                            },
                            group
                        )
                    )
                })
        }
    }

    useEffect(() => {
        if (cover) {
            setShowCover(true)
        } else {
            setShowCover(false)
        }
    }, [cover])

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label"></label>
                <div className="col-xl-9">
                    <div className="photogallery">
                        {showCover
                            ? cover.map(
                                  (item: {
                                      id: number
                                      public_link: string
                                      mime_type: string
                                  }) => {
                                      return (
                                          <InputMultipleFileItem
                                              id={item.id}
                                              key={item.id}
                                              imageSrc={item.public_link}
                                              propertyContainer={
                                                  propertyContainer
                                              }
                                          />
                                      )
                                  }
                              )
                            : null}
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <label
                    htmlFor={propertyContainer.getId()}
                    className="col-xl-3 col-form-label"
                >
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <input
                        className={`form-control ${isInvalid}`}
                        type="file"
                        multiple
                        id={propertyContainer.getId()}
                        disabled={propertyContainer.get("readonly")}
                        onChange={(event: any) => {
                            // dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: event.target.files[0].name }, group))
                            // showCoverHandler(event.target.files[0])
                            sendFile(event.target.files)
                        }}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}
