import React, { useMemo } from "react"
import { Uuid } from "types"
import { PicturesGeneratorParams } from "./index"
import { PicturesGeneratorPartAttributes } from "./PicturesGeneratorPartAttributes"

export const PicturesGeneratorContainersWrapper: React.FC<{
    params: PicturesGeneratorParams
    filledAttributes: {
        [attributeId: Uuid]: string
    }
    setFilledAttributes: (v: { [attributeId: Uuid]: string }) => void
}> = ({ params, filledAttributes, setFilledAttributes }) => {
    useMemo(() => setFilledAttributes({}), [params])

    const handleFillAttribute = (attributeId: Uuid, valueId?: Uuid) => {
        let curr = filledAttributes
        curr = valueId
            ? {
                  ...curr,
                  [attributeId]: valueId,
              }
            : Object.keys(curr).reduce((prev, currKey) => {
                  if (currKey === attributeId.toString()) {
                      return prev
                  }
                  return {
                      ...prev,
                      [currKey]: curr[currKey],
                  }
              }, {})

        setFilledAttributes(curr)
    }

    const resetPreviews = () => {
        setFilledAttributes({})
    }

    return (
        <>
            <PicturesGeneratorPartAttributes
                target={params.model ? "model" : "series"}
                onFillAttribute={handleFillAttribute}
                filled={filledAttributes}
                params={params}
                resetPreviews={resetPreviews}
            />
        </>
    )
}
