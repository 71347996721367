import { usePageData } from "hooks/usePageData"
import React from "react"
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
} from "react-grid-dnd"
import { useDispatch, useSelector } from "react-redux"
import api from "../../helpers/api"
import { compare } from "../../helpers/compare"
import { SET_LISTING } from "../../store/listing/actions"
import { SET_USER_SETTINGS } from "../../store/saveData/actions"

export const ColumnSettings = () => {
    const tableHeaders =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.headers
        ) || []
    const showedColumns: any =
        useSelector(
            (state: { [key: string]: any }) =>
                state?.savedUserSettings.showedColumns
        ) || []

    const [search, setSearch] = React.useState("")

    const dispatch = useDispatch()
    const { slug } = usePageData()

    const checkElemHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: { [key: string]: any }
    ) => {
        const id = event.target.attributes.getNamedItem("data-id")?.value
        const isChecked = event.target.checked
        if (id === undefined) return

        if (isChecked) {
            dispatch(
                SET_USER_SETTINGS({ showedColumns: [...showedColumns, item] })
            )
        } else {
            dispatch(
                SET_USER_SETTINGS({
                    showedColumns: showedColumns.filter(
                        (item: { [key: string]: any }) => item.columnName !== id
                    ),
                })
            )
        }
    }

    const checkedAll = () => {
        dispatch(SET_USER_SETTINGS({ showedColumns: [...tableHeaders] }))
    }

    const unCheckedAll = () => {
        dispatch(
            SET_USER_SETTINGS({
                showedColumns: [
                    {
                        columnName: "id",
                        columnLabel: "Id",
                    },
                ],
            })
        )
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSearch(event.target.value)
    const filtered = search
        ? tableHeaders.filter((item: any) =>
              item.columnLabel
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
          )
        : tableHeaders

    function onChange(sourceId: any, sourceIndex: number, targetIndex: number) {
        const result = swap(filtered, sourceIndex, targetIndex)

        dispatch(SET_LISTING({ headers: [...result] }))
        dispatch(
            SET_USER_SETTINGS({ showedColumns: compare(result, showedColumns) })
        )
    }

    const onCloseHandler = () => {
        api.post(
            `listing/saved-data/${slug}`,
            {},
            { showedColumns: [...showedColumns] }
        )
        setSearch("")
    }

    return (
        <div
            onClick={onCloseHandler}
            className="modal fade"
            id="columnModal"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div
                onClick={(event) => {
                    event.stopPropagation()
                }}
                className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
            >
                <div className="modal-content pb-4">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Настроить колонки
                        </h5>
                        <div
                            className="btn-group ms-4"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={checkedAll}
                            >
                                Выбрать всё
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={unCheckedAll}
                            >
                                Сбросить всё
                            </button>
                        </div>
                        <div className="ms-auto me-2 w-50">
                            <input
                                type="text"
                                className="form-control"
                                value={search}
                                placeholder="Поиск..."
                                onChange={(event) => handleSearch(event)}
                            />
                        </div>
                        <button
                            onClick={onCloseHandler}
                            type="button"
                            className="btn-close ms-2 p-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="list-group">
                            <div className="users-popup-column">
                                <GridContextProvider onChange={onChange}>
                                    <GridDropZone
                                        id="items"
                                        className="dropzone"
                                        boxesPerRow={4}
                                        rowHeight={60}
                                    >
                                        {filtered.map((item: any) => (
                                            <GridItem key={item.columnName}>
                                                <label
                                                    className="list-group-item"
                                                    style={{ width: "100%" }}
                                                >
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        disabled={
                                                            item.columnName ===
                                                            "id"
                                                        }
                                                        checked={showedColumns.some(
                                                            (column: any) =>
                                                                column.columnName ===
                                                                item.columnName
                                                        )}
                                                        data-id={
                                                            item.columnName
                                                        }
                                                        onChange={(event) =>
                                                            checkElemHandler(
                                                                event,
                                                                item
                                                            )
                                                        }
                                                    />
                                                    {item.columnLabel}
                                                    <button
                                                        type="button"
                                                        className="users-dropdown-toggle"
                                                        aria-expanded="false"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-list"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                </label>
                                            </GridItem>
                                        ))}
                                    </GridDropZone>
                                </GridContextProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
