import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import { setLocale } from "yup"
import App from "./App"
import { allReducers } from "./store/reducers"
//@ts-ignore fixme
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/lib/storage"
import ru from "yup-locale-ru"

import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

setLocale(ru)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Minsk")

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const persistConfig = {
    key: "root",
    storage,
}

const persistedReducer = persistReducer(persistConfig, allReducers) as any

let reduxDevTools = compose
if (
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    import.meta.env.NODE_ENV === "development"
) {
    reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__()
}

export const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk), reduxDevTools)
)

Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN ?? "",
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
    <PersistGate loading={null} persistor={persistStore(store)}>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </PersistGate>,
    document.querySelector("#root")
)
